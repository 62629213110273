import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchSale, getSales } from "../../app/reducers/Sale/saleSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { ClipLoader } from "react-spinners";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";

const ViewSaleProducts = () => {
  const { id } = useParams();
  const { elementEditData, editDataLoading } = useSelector(getSales);
  const { baseUrl } = useSelector(getMediaLibrary);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSale({ _id: id, populate: true }));
  }, [id]);

  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: "relative" }}>
              {_.isEmpty(row?.original?.product?.photo) ? (
                "No Image"
              ) : (
                <img
                  src={`${baseUrl}${row.original?.product?.photo}`}
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Product Name",
      accessor: "productName",
    },
    {
      Header: "Sku",
      accessor: "sku",
    },
    {
      Header: "Quantity",
      accessor: "qty",
    },
    {
      Header: "Cost Price",
      accessor: "product.costPrice",
    },
  ];

  const dataMemo = useMemo(() => {
    return (
      elementEditData?.products.slice().sort((a, b) => {
        const skuA = a.sku.toUpperCase();
        const skuB = b.sku.toUpperCase();
        if (skuA < skuB) {
          return -1;
        }
        if (skuA > skuB) {
          return 1;
        }
        return 0;
      }) ?? []
    );
  }, [elementEditData]);

  const columnsMemo = useMemo(() => columns ?? [], [columns]);

  return (
    <PageWithCard heading="View Sale Products">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <TableWithHeadingAndSearch data={dataMemo} columns={columnsMemo} />
      )}
    </PageWithCard>
  );
};

export default ViewSaleProducts;
