import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "organization";

export const fetchOrganizations = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editOrganizations = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createOrganization = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const organizationsSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchOrganizations, SLICE_NAME),
    ...editData.generateExtraReducers(editOrganizations, SLICE_NAME),
    ...createData.generateExtraReducers(createOrganization, SLICE_NAME),
  },
});

export const getOrganization = (state) => state.organization;
export default organizationsSlice.reducer;
