import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editVendor, fetchVendor, getVendors } from '../../app/reducers/Users/VendorSlice';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';

const EditVendor = () => {
  const { id } = useParams();
  const { elementEditData, editDataLoading } = useSelector(getVendors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendor({ _id: id }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...elementEditData },
    onSubmit: async values => {
      console.log(values);
      await dispatch(editVendor(values));
      await dispatch(fetchVendor({ _id: id }));
    },
  });

  return (
    <PageWithCard heading="Edit Vendor">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup label="First Name" formik={formik} name="firstName" required />
          <FormikInputGroup label="Last Name" formik={formik} name="lastName" required />
          <FormikInputGroup label="Company Name" formik={formik} name="companyName" required />

          <FormikPhoneInputGroup label="Phone" name="username" formik={formik} required />

          <FormikInputGroup label="GST Number" formik={formik} name="gstNumber" />
          <div>
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default EditVendor;
