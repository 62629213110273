import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchSaleReturn,
  getSaleReturns,
} from "../../app/reducers/SaleReturn/saleReturnSlice";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { ClipLoader } from "react-spinners";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";

const ViewSaleReturnProducts = () => {
  const { id } = useParams();
  const { editDataLoading, elementEditData } = useSelector(getSaleReturns);
  const { baseUrl } = useSelector(getMediaLibrary);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSaleReturn({ _id: id, populate: true }));
  }, [id]);

  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: "relative" }}>
              {_.isEmpty(row?.original?.product?.photo) ? (
                "No Image"
              ) : (
                <img
                  src={`${baseUrl}${row.original?.product?.photo}`}
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Product Name",
      accessor: "productName",
    },
    {
      Header: "Sku",
      accessor: "sku",
    },
    {
      Header: "Quantity",
      accessor: "qty",
    },
    {
      Header: "Cost Price",
      accessor: "product.costPrice",
    },
  ];

  const dataMemo = useMemo(() => {
    return elementEditData?.products ?? [];
  }, [elementEditData]);

  const columnsMemo = useMemo(() => columns ?? [], [columns]);

  return (
    <PageWithCard heading="View Sale Return Products">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <TableWithHeadingAndSearch data={dataMemo} columns={columnsMemo} />
      )}
    </PageWithCard>
  );
};

export default ViewSaleReturnProducts;
