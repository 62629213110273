import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "cashbook";

export const fetchCashbooks = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editCashbook = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCashbook = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteCashbook = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createCashbook = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const cashbookSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchCashbooks, SLICE_NAME),
    ...createData.generateExtraReducers(createCashbook, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteCashbook, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchCashbook, SLICE_NAME),
    ...editData.generateExtraReducers(editCashbook, SLICE_NAME),
  },
});

export const getCashbook = (state) => state.cashbook;
export const { setEditId } = cashbookSlice.actions;
export default cashbookSlice.reducer;
