import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  fetchProducts,
  getProducts,
} from "../../app/reducers/Product/productSlice";
import { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useMemo } from "react";
import { useCallback } from "react";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { Edit2, Trash } from "react-feather";
import ProductAdditionalSkuModal from "./ProductAdditionalSkuModal";
import ProductAttributesModal from "./ProductAttributesModal";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { useNavigate } from "react-router-dom";
import ShowDropdown from "../../components/infrastructure/ShowDropdown";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useFormik } from "formik";
import {
  fetchAttributes,
  getAttributes,
} from "../../app/reducers/Attribute/attributeSlice";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import { generateBarcodeDataByQty } from "../../utils/generateBarcodeData";
import { ClipLoader } from "react-spinners";

const ViewProduct = () => {
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenAdditionalSkuModel, setIsOpenAdditionalSkuModel] =
    useState(false);
  const [productAdditionalSkuData, setProductAdditionalSkuData] = useState([]);
  const [isOpenAttributesModal, setIsOpenAttributesModal] = useState(false);
  const [productAttributesData, setProductAttributesData] = useState([]);
  const [filters, setFilters] = useState({});
  const { baseUrl } = useSelector(getMediaLibrary);
  const { loading, product } = useSelector(getProducts);
  const { attribute } = useSelector(getAttributes);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts({ populate: true, page }));
    dispatch(fetchAttributes({ populate: true }));
  }, [page]);

  useEffect(() => {
    if (attribute?.docs) {
      let category = attribute?.docs?.reduce((acc, crr) => {
        if (acc[crr.type.type]) {
          acc[crr.type.type].push({
            label: crr.name,
            value: crr._id,
          });
        } else {
          acc[crr.type.type] = [
            {
              label: crr.name,
              value: crr._id,
            },
          ];
        }
        return acc;
      }, {});

      setFilters(category);
    }
  }, [attribute]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object?.keys(filters)?.reduce((acc, crr) => {
      acc[crr] = [];
      return acc;
    }, {}),
    onSubmit: async (values) => {
      const attributeData = Object.keys(values).reduce((acc, crr) => {
        if (acc[crr]) {
          acc[crr][$in] = [...acc[crr][$in], ...values[crr]];
        } else {
          acc[crr] = { $in: [...values[crr]] };
        }
        return acc;
      }, {});

      const finalAttribute = Object.values(attributeData).reduce(
        (acc, crr) => {
          acc["$and"].push({
            "attributes._id": crr,
          });

          return acc;
        },
        {
          $and: [],
        }
      );
      await dispatch(
        fetchProducts({
          populate: true,
          ...finalAttribute,
          page,
        })
      );
    },
  });

  const columns = [
    {
      Header: "Select",
      Cell: ({ row, data }) => (
        <input
          type="checkbox"
          checked={selectedRows.map((ele) => ele.id).includes(row.original._id)}
          onChange={(e) => {
            e.preventDefault();
            let obj = {
              productDetail: {
                productName: row?.original?.name,
                masterSku: row?.original?.masterSku,
              },
              quantity: 1,
              id: row?.original?._id,
            };
            if (e.target.checked) {
              setSelectedRows([...selectedRows, obj]);
            } else {
              const data = selectedRows.filter(
                (item) => item.id !== row.original._id
              );
              setSelectedRows(data);
            }
          }}
        />
      ),
    },
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + product.pagingCounter;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: "relative" }}>
              {_.isEmpty(row?.original?.product?.photo) ? (
                "No Image"
              ) : (
                <img
                  src={`${baseUrl}${row.original?.product?.photo}`}
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Manufacturer",
      Cell: ({ row }) => {
        const manufacturers = row?.original?.manufacturer?.map(
          (el) => el?.companyName
        );
        if (manufacturers) {
          return (
            <div className="flex flex-col ">
              <p>{manufacturers.join(", ")}</p>
            </div>
          );
        } else {
          return "No Data Found";
        }
      },
    },
    {
      Header: "Product Name",
      accessor: "name",
    },
    {
      Header: "Cost Price",
      accessor: "costPrice",
    },
    {
      Header: "Parent Sku",
      accessor: "parentSku",
    },
    {
      Header: "Master Sku",
      accessor: "masterSku",
    },
    {
      Header: "Additional_Sku",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenAdditionalSkuModel(true);
              setProductAdditionalSkuData(row?.original?.additionalSku);
            }}
          >
            Additional Sku
          </div>
        );
      },
    },
    {
      Header: "Attributes",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenAttributesModal(true);
              setProductAttributesData(row?.original?.attributes);
            }}
          >
            Attributes
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={() => {
                navigate(`/product/editProduct/${row?.original?._id}`);
              }}
            />

            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteProduct({ id: row?.original?._id }));
                await dispatch(fetchProducts({ populate: true }));
              }}
            />
          </div>
        );
      },
    },
  ];

  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(() => product?.docs ?? [], [product]);
  const debouncedSearch = useCallback(
    _.debounce((search) => {
      let data = {
        populate: true,
      };
      dispatch(
        fetchProducts({
          ...data,
          search: search,
          page,
        })
      );
    }, 300),
    []
  );
  return (
    <PageWithCard heading="View Product">
      <div className="w-full mb-8 flex flex-col gap-4">
        <ShowDropdown allowedRoles={["admin"]} heading="Filters">
          <form
            className="grid grid-cols-2 gap-4"
            onSubmit={formik.handleSubmit}
          >
            {Object.keys(filters).map((item, i) => {
              return (
                <FormikMultiSelect
                  label={item}
                  options={filters[item]}
                  formik={formik}
                  name={item}
                  key={i}
                />
              );
            })}

            <div className="col-span-2">
              {formik.isSubmitting ? (
                <ClipLoader />
              ) : (
                <PrimaryButton type="submit">Submit</PrimaryButton>
              )}
            </div>
          </form>
        </ShowDropdown>
      </div>
      <ProductAdditionalSkuModal
        isOpenAdditionalSkuModel={isOpenAdditionalSkuModel}
        setIsOpenAdditionalSkuModel={setIsOpenAdditionalSkuModel}
        productAdditionalSkuData={productAdditionalSkuData}
      />
      <ProductAttributesModal
        isOpenAttributesModal={isOpenAttributesModal}
        setIsOpenAttributesModal={setIsOpenAttributesModal}
        productAttributesData={productAttributesData}
      />
      {selectedRows?.length > 0 && (
        <div className="mb-3">
          <PrimaryButton
            onClick={() => generateBarcodeDataByQty(selectedRows)}
            type="button"
          >
            Print
          </PrimaryButton>
        </div>
      )}
      <TableWithHeadingAndGlobalSearch
        data={dataMemo}
        columns={columnsMemo}
        heading={`View Product`}
        loading={loading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
      <PaginationClassic setPage={setPage} paginationDetails={product} />
    </PageWithCard>
  );
};
export default ViewProduct;
