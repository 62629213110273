import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSaleOrder,
  fetchSaleOrders,
  getSaleOrders,
} from "../../app/reducers/SaleOrder/saleOrderSlice";
import { generateOptions } from "../../utils/Utils";
import ProductInputFields from "./ProductInputFields";
import DispatchProductTable from "./DispatchProductTable";
import TableHeader from "../../components/Table/TableHeader";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { Loader } from "react-feather";
import axios from "axios";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";

const Dispatch = () => {
  const storeLocation = JSON.parse(localStorage.getItem("saleLocationInfoId"));

  const { saleOrder, elementEditData: selectedSaleOrder } =
    useSelector(getSaleOrders);
  const { location } = useSelector(getLocation);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSaleOrders());
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      saleOrder: "",
      products: [],
      location: storeLocation ?? "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!values.products.length) {
          return alert("Select at least one before submit!");
        }
        if (_.isEmpty(values.saleOrder)) {
          return alert("Select sale order to continue!");
        }
        const createDispatchRes = await authAxiosInstance.post("/dispatch", {
          ...values,
        });
        if (createDispatchRes.data.success) toast.success("Dispatch Created!");
        console.log(createDispatchRes);
        resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <PageWithCard heading="Dispatch">
      <form onSubmit={formik.handleSubmit}>
        <FormikSelectGroup
          name={"location"}
          label="Select Location"
          formik={formik}
          options={generateOptions({
            array: location.docs ?? [],
            labelField: "name",
            valueField: "_id",
          })}
        />
        {!_.isEmpty(formik.values.location) && (
          <>
            <FormikSelectGroup
              label="Select Sale Order"
              name={"saleOrder"}
              formik={formik}
              options={generateOptions({
                array: saleOrder.docs ?? [],
                valueField: "_id",
                labelField: "saleOrderNumber",
              })}
              onChange={async (selectedObject) => {
                console.log(selectedObject);
                const saleOrderId = selectedObject.value;
                await dispatch(fetchSaleOrder({ _id: saleOrderId }));

                formik.setFieldValue("saleOrder", selectedObject.value);
              }}
            />

            {selectedSaleOrder?.status !== "completed" ? (
              <>
                <FormikProvider value={formik}>
                  <FieldArray
                    name="products"
                    render={(arrayHelpers) => (
                      <div>
                        <div>
                          <ProductInputFields
                            saleOrderProductArr={
                              selectedSaleOrder?.products ?? []
                            }
                            arrayHelpers={arrayHelpers}
                            formik={formik}
                          />
                        </div>
                        <div className="w-full overflow-x-auto">
                          <table className=" w-full">
                            <TableHeader
                              headers={[
                                { name: "" },
                                { name: "Product" },
                                { name: "sale order qty" },
                                { name: "Dispatch qty" },
                              ]}
                            />
                            <tbody>
                              {formik.values.products.map((ele, index) => (
                                <DispatchProductTable
                                  key={index}
                                  formik={formik}
                                  element={ele}
                                  index={index}
                                  arrayHelpers={arrayHelpers}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  />
                </FormikProvider>
                <div>
                  {formik.isSubmitting ? (
                    <div className="my-auto">
                      <Loader className="animate-spin " />
                    </div>
                  ) : (
                    <PrimaryButton disabled={formik.isSubmitting} type="submit">
                      Submit
                    </PrimaryButton>
                  )}
                </div>
              </>
            ) : (
              <div className="my-4 text-center">
                <h1>Selected Sale Order is already completed</h1>
              </div>
            )}
          </>
        )}
      </form>
    </PageWithCard>
  );
};

export default Dispatch;
