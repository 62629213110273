import React, { useEffect } from "react";
import PageWithCard from "../../../components/infrastructure/PageWithCard";
import { useFormik } from "formik";
import FormikSelectGroup from "../../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../../../app/reducers/Location/locationSlice";
import { generateOptions } from "../../../utils/Utils";
import {
  fetchExpenseCategories,
  getExpenseCategories,
} from "../../../app/reducers/ExpenseCategory/expenseCategorySlice";
import FormikInputGroup from "../../../components/formik/FormikInputGroup";
import { PAYMENT_TYPE } from "../../../utils/dropdownOptions";
import FormikTextareaGroup from "../../../components/formik/FormikTextareaGroup";
import PrimaryButton from "../../../components/infrastructure/Buttons/PrimaryButton";
import * as Yup from "yup";
import { createCashbook } from "../../../app/reducers/Cashbook/cashbookSlice";

const Cashbook = () => {
  const { location, loading: locationLoading } = useSelector(getLocation);
  const { expenseCategory, loading: expenseCategoryLoading } =
    useSelector(getExpenseCategories);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchExpenseCategories());
  }, []);
  const formik = useFormik({
    initialValues: {
      location: "",
      expense_category: "",
      amount: 0,
      transaction_type: "credit",
      notes: "",
    },
    validationSchema: Yup.object({
      location: Yup.string().required(),
      expense_category: Yup.string().required(),
      amount: Yup.number().required(),
      transaction_type: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      console.log(values);
      dispatch(createCashbook(values));
    },
  });
  return (
    <PageWithCard heading="Cashbook">
      <form onSubmit={formik.handleSubmit} className="grid grid-cols-3 gap-3">
        <FormikSelectGroup
          formik={formik}
          label="Location"
          name="location"
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: "name",
            valueField: "_id",
          })}
          required
        />
        <FormikSelectGroup
          formik={formik}
          label="Expense Category"
          name="expense_category"
          options={generateOptions({
            array: expenseCategory?.docs ?? [],
            labelField: "name",
            valueField: "name",
          })}
          required
        />
        <FormikInputGroup
          formik={formik}
          type="number"
          name="amount"
          label="Amount"
          required
        />
        <FormikSelectGroup
          name="transaction_type"
          label="Type"
          formik={formik}
          options={generateOptions({
            array: PAYMENT_TYPE,
            valueField: "value",
            labelField: "label",
          })}
        />
        <div className="col-span-2">
          <FormikTextareaGroup
            row={1}
            name="notes"
            label="Notes"
            formik={formik}
          />
        </div>
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </PageWithCard>
  );
};

export default Cashbook;
