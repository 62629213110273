import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "color";

export const fetchColors = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editColor = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchColor = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteColor = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createColor = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const colorSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchColors, SLICE_NAME),
    ...createData.generateExtraReducers(createColor, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteColor, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchColor, SLICE_NAME),
    ...editData.generateExtraReducers(editColor, SLICE_NAME),
  },
});

export const getColors = (state) => state.color;
export const { setEditId } = colorSlice.actions;
export default colorSlice.reducer;
