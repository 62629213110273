import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "saleReturn";

export const fetchSaleReturns = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editSaleReturn = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchSaleReturn = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteSaleReturn = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createSaleReturn = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const saleReturnSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchSaleReturns, SLICE_NAME),
    ...createData.generateExtraReducers(createSaleReturn, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteSaleReturn, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchSaleReturn, SLICE_NAME),
    ...editData.generateExtraReducers(editSaleReturn, SLICE_NAME),
  },
});

export const getSaleReturns = (state) => state.saleReturn;
export const { setEditId } = saleReturnSlice.actions;
export default saleReturnSlice.reducer;
