import React, { useEffect } from "react";
import ModalBasic from "../../../../components/infrastructure/Modals/ModalBasic";
import { useFormik } from "formik";
import FormikInputGroup from "../../../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../../../components/formik/FormikSelectGroup";
import { PAYMENT_METHOD } from "../../../../utils/dropdownOptions";
import PrimaryButton from "../../../../components/infrastructure/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createCreditbook,
  fetchCreditbooks,
} from "../../../../app/reducers/Creditbook/creditbookSlice";
import { createCashbook } from "../../../../app/reducers/Cashbook/cashbookSlice";
import { generateOptions } from "../../../../utils/Utils";
import {
  fetchLocations,
  getLocation,
} from "../../../../app/reducers/Location/locationSlice";
import { number, object, string } from "yup";
import { fetchBanks, getBanks } from "../../../../app/reducers/Bank/bankSlice";
import {
  editSaleOrder,
  fetchSaleOrders,
} from "../../../../app/reducers/SaleOrder/saleOrderSlice";
import { createBankbook } from "../../../../app/reducers/Bankbook/bankbookSlice";
import { Loader } from "react-feather";
import { editSale } from "../../../../app/reducers/Sale/saleSlice";

const ReceiveAmountModal = ({
  isOpen,
  setIsOpen,
  data,
  pageType = "creditbook",
  saleOrder = {},
}) => {
  const saleLocationInfoId = localStorage.getItem("SaleLocationInfoId");
  const { location, loading: locationLoading } = useSelector(getLocation);
  const { bank, loading: bankLoading } = useSelector(getBanks);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchBanks());
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user: data?._id,
      method: "",
      amount: "",
      location: saleLocationInfoId ? JSON.parse(saleLocationInfoId) : "",
    },
    validationSchema: object({
      location: string().when("method", {
        is: "cash",
        then: string().required("Location is required for cash transaction"),
        otherwise: string(),
      }),
      method: string().required(),
      user: string().required(),
      amount: number().min(1).required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (pageType == "creditbook") {
          await dispatch(
            createCreditbook({
              transaction_type: "debit",
              amount: values.amount,
              user: values.user,
            })
          );
          if (values.method == "cash") {
            await dispatch(
              createCashbook({
                transaction_type: "credit",
                amount: values.amount,
                location: values.location,
                expense_category: "RECEIVE_AMOUNT",
              })
            );
          } else {
            dispatch(
              createBankbook({
                bank: values.method,
                transaction_type: "credit",
                amount: values.amount,
                expense_category: "RECEIVE_AMOUNT",
              })
            );
          }
          await dispatch(fetchCreditbooks());
        } else if (pageType == "saleOrder") {
          await dispatch(
            createCreditbook({
              transaction_type: "debit",
              amount: values.amount,
              user: values.user,
            })
          );

          if (values.method == "cash") {
            await dispatch(
              createCashbook({
                transaction_type: "credit",
                amount: values.amount,
                location: values.location,
                expense_category: "SALE_ORDER",
              })
            );
          } else {
            await dispatch(
              createBankbook({
                bank: values.method,
                transaction_type: "credit",
                amount: values.amount,
                expense_category: "SALE_ORDER",
              })
            );
          }
          await dispatch(
            editSaleOrder({
              _id: saleOrder._id,
              received_amount: [
                ...saleOrder.received_amount,
                {
                  method: values.method,
                  amount: values.amount,
                },
              ],
            })
          );
          await dispatch(fetchSaleOrders());
        } else if (pageType == "sale") {
          await dispatch(
            createCreditbook({
              transaction_type: "debit",
              amount: values.amount,
              user: values.user,
            })
          );

          if (values.method == "cash") {
            await dispatch(
              createCashbook({
                transaction_type: "credit",
                amount: values.amount,
                location: values.location,
                expense_category: "SALE",
              })
            );
          } else {
            await dispatch(
              createBankbook({
                bank: values.method,
                transaction_type: "credit",
                amount: values.amount,
                expense_category: "SALE",
              })
            );
          }
          await dispatch(
            editSale({
              _id: saleOrder._id,
              received_amount: [
                ...saleOrder.received_amount,
                {
                  method: values.method,
                  amount: values.amount,
                },
              ],
            })
          );
        }
        setIsOpen(false);
        resetForm({
          user: data?._id,
        });
      } catch (error) {
        console.log("Error", error);
      }
    },
  });
  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={"Receive Amount"}
    >
      <form onSubmit={formik.handleSubmit} className="p-4 flex flex-col gap-3">
        <FormikInputGroup
          label="Customer"
          name={"user"}
          formik={formik}
          readOnly
        />
        <FormikSelectGroup
          name="location"
          label="Location / Store"
          formik={formik}
          options={generateOptions({
            array: location?.docs ?? [],
            valueField: "_id",
            labelField: "name",
          })}
        />{" "}
        <FormikSelectGroup
          label="Method"
          name={"method"}
          formik={formik}
          options={[
            ...(bank.docs ?? []).map((item) => ({
              value: item._id,
              label: item.name,
            })),
            ...PAYMENT_METHOD.filter((item) => item.value !== "credit"),
          ]}
        />
        <FormikInputGroup
          type={"number"}
          name={"amount"}
          label="Amount"
          formik={formik}
        />
        <div>
          {formik.isSubmitting ? (
            <div className="my-auto">
              <Loader className="animate-spin " />
            </div>
          ) : (
            <PrimaryButton disabled={formik.isSubmitting} type="submit">
              Submit
            </PrimaryButton>
          )}
        </div>
      </form>
    </ModalBasic>
  );
};

export default ReceiveAmountModal;
