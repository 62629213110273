import React from "react";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import { toast } from "react-toastify";

const ProductPurchaseOrderContainer = ({
  index,
  arrayHelpers,
  productData,
  formik,
}) => {
  return (
    <div
      className="relative p-4 mb-2"
      style={{
        border: "1px solid #d6c7c7",
        borderRadius: "5px",
      }}
      key={index}
    >
      <FormikSelectGroup
        formik={formik}
        label="Select Product"
        name={`products.${index}.product`}
        onChange={(selectedOption) => {
          const findProduct = productData?.find(
            (ele) => ele?._id === selectedOption?.value
          );

          let productExist = formik?.values?.products?.find(
            (ele) => ele.product === selectedOption.value
          );
          if (productExist) {
            return toast.error("You already added quantity to this Product");
          }
          formik.setFieldValue(
            `products.${index}.product`,
            selectedOption.value
          );
        }}
        options={generateOptions({
          array: productData,
          labelField: "masterSku",
          valueField: "_id",
        })}
      />
      {formik.values.products[index].product && (
        <>
          <FormikInputGroup
            formik={formik}
            label="Quantity"
            type="number"
            value={formik.values.products[index].quantity}
            name={`products.${index}.quantity`}
            required
          />
        </>
      )}
      <div>
        <DangerButton
          className="mt-3"
          onClick={() => {
            arrayHelpers.remove(index);
          }}
          type="button"
        >
          Remove
        </DangerButton>
      </div>
    </div>
  );
};

export default ProductPurchaseOrderContainer;
