import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { number, object, string } from "yup";
import ModalBasic from "../../../../components/infrastructure/Modals/ModalBasic";
import FormikInputGroup from "../../../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../../../utils/Utils";
import { PAYMENT_METHOD } from "../../../../utils/dropdownOptions";
import PrimaryButton from "../../../../components/infrastructure/Buttons/PrimaryButton";
import {
  fetchLocations,
  getLocation,
} from "../../../../app/reducers/Location/locationSlice";
import {
  createCreditbook,
  fetchCreditbooks,
} from "../../../../app/reducers/Creditbook/creditbookSlice";

const AddCreditNoteModal = ({ isOpen, setIsOpen, data }) => {
  const { location } = useSelector(getLocation);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: 0,
      user: data?._id,
      notes: "",
      transaction_type: "credit",
    },
    validationSchema: object({
      transaction_type: string().required(),
      user: string().required(),
      amount: number().min(1).required(),
    }),
    onSubmit: async (values) => {
      try {
        await dispatch(
          createCreditbook({
            transaction_type: values.transaction_type,
            amount: values.amount,
            user: values.user,
            notes: values.notes,
          })
        );
        await dispatch(
          fetchCreditbooks({
            populate: true,
            "user._id": values.user,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={"Add Credit Note"}
    >
      {/* <h1 className="p-10 font-bold">Under Development</h1> */}
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 p-4">
        <FormikInputGroup label="User" formik={formik} readOnly name="user" />

        <FormikInputGroup
          label="Amount"
          formik={formik}
          type="number"
          name="amount"
        />
        <FormikInputGroup label="Notes" formik={formik} name="notes" />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </ModalBasic>
  );
};

export default AddCreditNoteModal;
