import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  createWithFile,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";

const SLICE_NAME = "vendor";

export const fetchVendors = fetchData.generateThunk(SLICE_NAME, "users/vendor");
export const createVendor = createData.generateThunk(
  SLICE_NAME,
  "users/vendor/register"
);
export const deleteVendor = deleteData.generateThunk(
  SLICE_NAME,
  "users/vendor/remove"
);
export const fetchVendor = fetchEditData.generateThunk(
  SLICE_NAME,
  "users/vendor"
);
export const editVendor = editData.generateThunk(
  SLICE_NAME,
  "users/vendor/update"
);

const vendorSlice = createSlice({
  initialState: generateStateSingle(SLICE_NAME),
  name: SLICE_NAME,
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchVendors, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchVendor, SLICE_NAME),
    ...createData.generateExtraReducers(createVendor, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteVendor, SLICE_NAME),
    ...editData.generateExtraReducers(editVendor, SLICE_NAME),
  },
});

export const getVendors = (state) => state.vendor;

export default vendorSlice.reducer;
