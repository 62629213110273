import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "reference";

export const fetchReferences = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editReference = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchReference = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteReference = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createReference = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const referenceSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchReferences, SLICE_NAME),
    ...createData.generateExtraReducers(createReference, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteReference, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchReference, SLICE_NAME),
    ...editData.generateExtraReducers(editReference, SLICE_NAME),
  },
});

export const getReferences = (state) => state.reference;

export default referenceSlice.reducer;
