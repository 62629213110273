import React, { useEffect } from "react";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { X } from "react-feather";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import {
  createLocation,
  fetchLocations,
} from "../../app/reducers/Location/locationSlice";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
  findCitiesInState,
  findStatesInCountry,
  generateOptions,
} from "../../utils/Utils";
import geo from "../../assets/countries+states+cities.json";

const AddLocation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      pinCode: "",
      country: "",
      state: "",
      city: "",
      emails: [],
      phones: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(createLocation(values)).then((res) => {
        resetForm();
      });
    },
  });
  return (
    <PageWithCard heading="Add Stores">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikInputGroup
          label="Location Name"
          name="name"
          required
          formik={formik}
        />
        <FormikTextareaGroup
          label="Location Address"
          name="address"
          required
          formik={formik}
        />

        {/* <FormikInputGroup formik={formik} label="Country" name="country" />
        <FormikInputGroup formik={formik} label="State" name="state" />
        <FormikInputGroup formik={formik} label="City" name="city" /> */}

        <FormikSelectGroup
          formik={formik}
          label="Country"
          name="country"
          required
          //   onChange={(selectedOption) => {
          //     formik.setFieldValue("country", selectedOption.value);
          //   }}
          options={generateOptions({
            array: geo,
            valueField: "name",
            labelField: "name",
          })}
        />
        <FormikSelectGroup
          formik={formik}
          label="State"
          name="state"
          required
          options={generateOptions({
            array: findStatesInCountry(formik.values.country),
            valueField: "name",
            labelField: "name",
          })}
        />

        <FormikSelectGroup
          formik={formik}
          label="City"
          name="city"
          required
          options={generateOptions({
            array: findCitiesInState(
              formik?.values?.country,
              formik?.values?.state
            ),
            valueField: "name",
            labelField: "name",
          })}
        />

        <FormikInputGroup label="Pincode" name="pinCode" formik={formik} />

        <FormikProvider value={formik}>
          <FieldArray name="emails">
            {(arrayHelpers) => (
              <>
                <div className="flex items-center gap-4">
                  <h1 className="text-md">Emails</h1>
                  <PrimaryButton
                    type="button"
                    onClick={() => {
                      arrayHelpers.push("");
                    }}
                  >
                    Add
                  </PrimaryButton>
                </div>
                <div className="w-full">
                  {formik.values.emails.map((email, index) => (
                    <div className="flex items-center gap-4" key={index}>
                      <FormikInputGroup
                        name={`emails.${index}`}
                        formik={formik}
                        value={email}
                        fullWidth
                      />
                      <X
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </FieldArray>
        </FormikProvider>
        <FormikProvider value={formik}>
          <FieldArray name="phones">
            {(arrayHelpers) => (
              <>
                <div className="flex items-center gap-4">
                  <h1 className="text-md">Phones</h1>
                  <PrimaryButton
                    type="button"
                    onClick={() => {
                      arrayHelpers.push("+91");
                    }}
                  >
                    Add
                  </PrimaryButton>
                </div>
                <div>
                  {formik.values.phones.map((phone, index) => (
                    <div className="flex items-center gap-4" key={index}>
                      <FormikPhoneInputGroup
                        name={`phones.${index}`}
                        formik={formik}
                        value={phone}
                        fullWidth
                      />
                      <X
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </FieldArray>
        </FormikProvider>
        <div>
          <PrimaryButton type="submit">Create</PrimaryButton>
        </div>
      </form>
      {/* )} */}
    </PageWithCard>
  );
};

export default AddLocation;
