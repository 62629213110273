import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";

const ProductAttributesModal = ({
  isOpenAttributesModal,
  setIsOpenAttributesModal,
  productAttributesData,
}) => {
  return (
    <ModalBasic
      setModalOpen={setIsOpenAttributesModal}
      modalOpen={isOpenAttributesModal}
      title="Attributes"
    >
      <div className=" w-full p-2">
        <div className="grid grid-cols-1 gap-2 ">
          <div className="flex flex-row justify-between w-full  underline  hover:cursor-pointer p-2">
            <p className="text text-base font-extrabold w-[30%]">Type</p>
            <div className="flex flex-row w-[70%] justify-between">
              <p className="text text-base font-extrabold">Name</p>
              <p className="text text-base font-extrabold">Code</p>
            </div>
          </div>
          {productAttributesData?.length > 0 &&
            productAttributesData?.map((el, index) => (
              <div className="flex flex-row justify-between  w-full  p-2">
                <p className="text text-base font-semibold w-[30%]">
                  {el?.type?.type}
                </p>
                <div className="flex flex-row w-[70%] justify-between">
                  <p className="text text-base font-semibold">{el?.name}</p>
                  <p className="text text-base font-semibold">{el?.code}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </ModalBasic>
  );
};
export default ProductAttributesModal;
