export const checkRequired = (obj, config) => {
  let error = [];
  const keys = Object.keys(config);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = obj[key];
    error = [...error, ...verifyValidity(value, key, config)];
  }
  return error;
};

const verifyValidity = (value, key, config) => {
  let errors = [];
  const dataObject = _.at(config, key)[0];

  //here check isObject in fields

  switch (dataObject.type) {
    case "array":
      if (dataObject.fields) {
        for (let i = 0; i < value.length; i++) {
          const element = value[i];

          const filedKeys = Object.keys(dataObject.fields);
          for (let i = 0; i < filedKeys.length; i++) {
            const fieldKey = filedKeys[i];
            const data = element[fieldKey];
            const intermediateErrors = verifyValidity(
              data,
              `${key}.fields.${fieldKey}`,
              config
            );
            if (intermediateErrors?.length) {
              errors = [...errors, ...intermediateErrors];
            }
          }
        }
      } else {
        if (dataObject.required) {
          if (!value?.length) {
            errors.push(
              `At least one value is required for ${key.replace(
                ".fields.",
                "."
              )}`
            );
          }
        }
      }

      break;

    case "object":
      const filedKeys = Object.keys(dataObject.fields);
      for (let i = 0; i < filedKeys.length; i++) {
        const fieldKey = filedKeys[i];
        const element = value[fieldKey];
        const intermediateErrors = verifyValidity(
          element,
          `${key}.fields.${fieldKey}`,
          config
        );
        if (intermediateErrors?.length) {
          errors = [...errors, ...intermediateErrors];
        }
      }

      break;

    case "relationalArray":
      if (dataObject.required) {
        if (!value?.length) {
          errors.push(
            `At least one value is required for ${key.replace(".fields.", ".")}`
          );
        }
      }

    default:
      if (dataObject.required) {
        if (!value) {
          errors.push(`value is required for ${key.replace(".fields.", ".")}`);
        }
      }
      break;
  }

  return errors;
};
