import React from "react";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttributes } from "../../app/reducers/Attribute/attributeSlice";
import { generateOptions } from "../../utils/Utils";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import {
  fetchAttributeTypes,
  getAttributeTypes,
} from "../../app/reducers/AttributeType/attributeTypeSlice";
import { useEffect } from "react";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";

const ProductAttributes = ({ formik, index, arrayHelpers }) => {
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const { attributeType } = useSelector(getAttributeTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchExistingAttributes = async () => {
      const data = await dispatch(
        fetchAttributes({
          type: formik?.values?.attributes?.[index]?.type,
        })
      );
      if (data && data?.payload?.data?.docs?.length > 0) {
        setSelectedAttributes(data?.payload?.data?.docs);
      } else {
        setSelectedAttributes([]);
      }
    };

    if (formik?.values?.attributes?.[index]?.type) {
      fetchExistingAttributes();
    }
  }, []);

  return (
    <>
      <div
        className="relative p-4 mb-2 flex flex-row gap-2"
        style={{
          border: "1px solid #d6c7c7",
          borderRadius: "5px",
        }}
      >
        <FormikSelectGroup
          label="Type"
          formik={formik}
          name={`attributes.${index}.type`}
          onChange={async (selectedOption) => {
            const data = await dispatch(
              fetchAttributes({
                type: selectedOption?.value,
              })
            );
            formik.setFieldValue(
              `attributes.${index}.type`,
              selectedOption?.value
            );
            formik.setFieldValue(`attributes.${index}.attributeName`, "");
            if (data && data?.payload?.data?.docs.length > 0) {
              setSelectedAttributes(data?.payload?.data?.docs);
            } else {
              setSelectedAttributes([]);
            }
          }}
          options={generateOptions({
            array: attributeType?.docs ?? [],
            valueField: "_id",
            labelField: "type",
          })}
        />
        <FormikSelectGroup
          formik={formik}
          name={`attributes.${index}.attributeName`}
          label="Attribute Name"
          options={selectedAttributes?.map((el) => ({
            value: el?._id,
            label: el?.name,
          }))}
        />
      </div>
      <div>
        <DangerButton
          className="mt-3"
          onClick={() => {
            arrayHelpers.remove(index);
          }}
          type="button"
        >
          Remove
        </DangerButton>
      </div>
    </>
  );
};
export default ProductAttributes;
