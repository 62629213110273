import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "status";

export const fetchStatuses = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editStatus = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchStatus = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteStatus = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createStatus = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const statusSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchStatuses, SLICE_NAME),
    ...createData.generateExtraReducers(createStatus, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteStatus, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchStatus, SLICE_NAME),
    ...editData.generateExtraReducers(editStatus, SLICE_NAME),
  },
});

export const getStatuses = (state) => state.status;

export default statusSlice.reducer;
