import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "deadStock";

export const fetchDeadStocks = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editDeadStock = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchDeadStock = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createDeadStock = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const deadStockSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchDeadStocks, SLICE_NAME),
    ...createData.generateExtraReducers(createDeadStock, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchDeadStock, SLICE_NAME),
    ...editData.generateExtraReducers(editDeadStock, SLICE_NAME),
  },
});

export const getDeadStocks = (state) => state.deadStock;
export const { setEditId } = deadStockSlice.actions;
export default deadStockSlice.reducer;
