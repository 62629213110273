import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  editSaleOrder,
  fetchSaleOrder,
  getSaleOrders,
} from "../../app/reducers/SaleOrder/saleOrderSlice";
import { FieldArray, FormikProvider, useFormik } from "formik";
import SaleOrderProcessModal from "./SaleOrderProcessModal";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import ProductSelect from "./ProductSelect";
import SelectedProductRow from "./SelectedProductRow";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { createCreditbook } from "../../app/reducers/Creditbook/creditbookSlice";
const TableHeader = ({ headers }) => {
  return (
    <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers?.map((header) => (
          <th
            key={header.name}
            className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
          >
            <div style={header.style ?? {}} className="font-semibold text-left">
              {header.name}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
const EditSaleOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { elementEditData: saleOrderData } = useSelector(getSaleOrders);
  useEffect(() => {
    dispatch(fetchSaleOrder({ _id: id, populate: true }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...saleOrderData },
    onSubmit: async (values) => {
      console.log("values", values);
      if (values.products.length <= 0)
        return alert("Please selected at least one product to continue");

      const currentTotalAmount = values.products.reduce(
        (acc, { total_price }) => {
          return acc + total_price;
        },
        0
      );
      const oldTotalAmount = saleOrderData.products.reduce(
        (acc, { total_price }) => {
          return acc + total_price;
        },
        0
      );

      if (currentTotalAmount != oldTotalAmount) {
        console.log(currentTotalAmount, oldTotalAmount);
        console.log(currentTotalAmount - oldTotalAmount);
        if (currentTotalAmount > oldTotalAmount) {
          await dispatch(
            createCreditbook({
              transaction_type: "credit",
              amount: currentTotalAmount - oldTotalAmount,
              user: values.customer,
            })
          );
        } else if (oldTotalAmount > currentTotalAmount) {
          await dispatch(
            createCreditbook({
              transaction_type: "debit",
              amount: oldTotalAmount - currentTotalAmount,
              user: values.customer,
            })
          );
        }
      }
      await dispatch(editSaleOrder(values));

      // setOpenSaleProcess(true);
    },
  });

  console.log(formik.errors);
  return (
    <div className="px-4 ">
      {/* <SaleOrderProcessModal
        parentFormik={formik}
        isOpen={openSaleProcess}
        setIsOpen={setOpenSaleProcess}
        edit={true}
      /> */}
      <h1 className="font-bold text-3xl py-3">Edit Sale Order</h1>

      <div className="flex flex-col gap-3 border  my-2 px-2 py-3">
        <span className="text-sm font-semibold underline">INFORMATION</span>
        <p>
          Customer: {formik.values?.customer?.firstName}{" "}
          {formik.values?.customer?.lastName} /{" "}
          {formik.values?.customer?.username}
        </p>
        <p>Created By: {formik.values?.created_by}</p>
      </div>

      <form className="flex flex-col gap-3" onSubmit={formik.handleSubmit}>
        {/* <FormikAsyncSelect
          required
          name="customer"
          formik={formik}
          label="Search Customer"
          getOptions={async (value) => {
            const string = QueryString.stringify({ search: value });
            const customers = await authAxiosInstance.get(
              `users/customer?${string}`
            );
            const options = customers?.data?.data?.docs?.map((ele) => ({
              label: `${ele?.firstName} ${ele?.lastName} / ${ele?.username}`,
              value: ele?._id,
            }));
            return options;
          }}
          onChange={async (selectedOption) => {
            const string = QueryString.stringify({
              _id: selectedOption?.value,
            });
            const resp = await authAxiosInstance.get(
              `users/customer?${string}`
            );
            const user = resp?.data?.data?.docs[0];

            formik.setFieldValue("customer", user._id);
          }}
        /> */}
        <div className="grid grid-cols-2 gap-3">
          <FormikAsyncSelect
            required
            label="Created By"
            name="created_by"
            formik={formik}
            getOptions={async (searchValue) => {
              const string = QueryString.stringify({ search: searchValue });
              const resp = await authAxiosInstance.get(
                `/users/employee?${string}`
              );
              console.log(resp);
              const options = resp?.data?.data?.docs?.map((ele) => ({
                label: `${ele.firstName} ${ele.lastName}`,
                value: `${ele.firstName} ${ele.lastName}`,
              }));

              return options;
            }}
          />
          <FormikTextareaGroup
            row={1}
            label="Notes"
            name="notes"
            formik={formik}
          />
        </div>

        <FormikProvider value={formik}>
          <FieldArray
            name="products"
            render={(arrayHelpers) => (
              <div className="flex flex-col gap-3">
                <div>
                  <ProductSelect formik={formik} arrayHelpers={arrayHelpers} />
                </div>
                <div className="w-full overflow-x-auto">
                  <table className=" ">
                    <TableHeader
                      headers={[
                        { name: "" },
                        { name: "photo" },
                        { name: "Product" },
                        { name: "qty", style: { width: "40px" } },
                        { name: "MRP", style: { width: "65px" } },
                        { name: "Selling Price" },
                        { name: "Discount" },
                        { name: "Total Discount" },
                        { name: "Tax type" },
                        { name: "Tax Rate", style: { width: "40px" } },
                        { name: "Tax AMT", style: { width: "40px" } },
                        { name: "Total Tax" },
                        { name: "Total Price" },
                      ]}
                    />
                    <tbody>
                      {formik.values.products?.map((ele, index) => (
                        <SelectedProductRow
                          key={index}
                          formik={formik}
                          element={ele}
                          index={index}
                          arrayHelpers={arrayHelpers}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          />
        </FormikProvider>
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default EditSaleOrder;
