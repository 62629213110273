import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { fetchSales, getSales } from "../../app/reducers/Sale/saleSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikAsyncMultiSelect from "../../components/formik/FormikAsyncMultiSelect";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import ReceiveAmountModal from "../Books/Creditbook/creditBookModals/ReceiveAmountModal";

const ViewSale = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalSalesQty, setTotalSalesQty] = useState("");
  const { location } = useSelector(getLocation);
  const { sale, loading } = useSelector(getSales);

  const [receiveAmountModalOpen, setReceiveAmountModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedSale, setSelectedSale] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      location: "",
      customerId: "",
      product: [],
      from: moment().startOf("day").valueOf(),
      to: moment().endOf("day").valueOf(),
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const filters = {
          createdAt: {
            $gt: values.from,
            $lt: values.to,
          },
          page,
        };
        if (values.customerId) {
          filters.customerId = values.customerId;
        }
        if (values.location) {
          filters["location._id"] = values.location;
        }
        if (values.product) {
          filters["products.product._id"] = { $in: values.product };
        }
        const responseSalesData = await dispatch(
          fetchSales({ ...filters, page, populate: true })
        );
        const salesData = responseSalesData?.payload?.data?.docs;

        const filteredProducts = salesData?.reduce((acc, { products }) => {
          if (products) {
            return [...acc, ...products];
          }
          return acc;
        }, []);
        const totalSumOfQty = filteredProducts?.reduce((acc, { qty }) => {
          return qty ? acc + qty : 0;
        }, 0);
        if (totalSumOfQty) {
          setTotalSalesQty(totalSumOfQty);
        } else {
          setTotalSalesQty("");
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error("Error fetching Sales");
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    getSaleData();
  }, [page]);

  const getSaleData = async () => {
    try {
      setIsLoading(true);
      const filters = {
        createdAt: {
          $gt: formik.values.from,
          $lt: formik.values.to,
        },
      };
      if (formik.values.customerId) {
        filters.customerId = formik.values.customerId;
      }
      if (formik.values.location) {
        filters["location._id"] = formik.values.location;
      }
      if (formik.values.product) {
        filters["products.product._id"] = { $in: formik.values.product };
      }
      await dispatch(fetchLocations());
      const responseSalesData = await dispatch(
        fetchSales({ ...filters, page, populate: true })
      );
      const salesData = responseSalesData?.payload?.data?.docs;
      const filteredProducts = salesData?.reduce((acc, { products }) => {
        if (products) {
          return [...acc, ...products];
        }
        return acc;
      }, []);
      const totalSumOfQty = filteredProducts?.reduce((acc, { qty }) => {
        return qty ? acc + qty : 0;
      }, 0);
      if (totalSumOfQty) {
        setTotalSalesQty(totalSumOfQty);
      } else {
        setTotalSalesQty("");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Error fetching Sales");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format("DD/MM/YYYY")
          : "No Date Found";
      },
    },
    { Header: "Customer Name", accessor: "customerName" },
    {
      Header: "Location",
      accessor: "location.name",
    },
    {
      Header: "Invoice Number",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={async (e) => {
              e.stopPropagation();
              navigate(`/sale/viewSale/${row?.original?._id}`);
            }}
          >
            {row?.original?.saleNumber}
          </div>
        );
      },
    },
    {
      Header: "total amount",
      Cell: ({ row }) => {
        const totalAmount = row?.original?.products?.reduce(
          (acc, { total_price }) => {
            return total_price ? acc + total_price : 0;
          },
          0
        );
        return ` ₹${totalAmount}`;
      },
    },
    {
      Header: "pending amount",
      Cell: ({ row }) => {
        const totalAmount = row?.original?.products?.reduce(
          (acc, { total_price }) => {
            return total_price ? acc + total_price : 0;
          },
          0
        );
        const totalReceivedAmount = row?.original?.received_amount?.reduce(
          (acc, { amount }) => {
            return amount ? acc + amount : 0;
          },
          0
        );
        return ` ₹${totalAmount - totalReceivedAmount}`;
      },
    },
    {
      Header: "Received amount",
      Cell: ({ row }) => {
        const totalReceivedAmount = row?.original?.received_amount?.reduce(
          (acc, { amount }) => {
            return amount ? acc + amount : 0;
          },
          0
        );
        return ` ₹${totalReceivedAmount}`;
      },
    },
    {
      Header: "Total Quantity",
      Cell: ({ row }) => {
        const totalSale = row?.original?.products?.reduce(
          (acc, { quantity }) => {
            return quantity ? acc + quantity : 0;
          },
          0
        );
        return totalSale;
      },
    },
    {
      Header: "Total Valuation",
      Cell: ({ row }) => {
        const totalSaleValuation = row?.original?.products?.reduce(
          (acc, { quantity, product }) => {
            const value = quantity * product.costPrice;
            return acc + value;
          },
          0
        );
        return totalSaleValuation;
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const totalAmount = row?.original?.products?.reduce(
          (acc, { total_price }) => {
            return total_price ? acc + total_price : 0;
          },
          0
        );
        const totalReceivedAmount = row?.original?.received_amount?.reduce(
          (acc, { amount }) => {
            return amount ? acc + amount : 0;
          },
          0
        );

        if (totalAmount - totalReceivedAmount > 0)
          return (
            <PrimaryButton
              onClick={(e) => {
                e.stopPropagation();
                setReceiveAmountModalOpen(true);
                setSelectedCustomer({ _id: row.original.customerId });
                setSelectedSale(row.original);
              }}
            >
              Receive Amount
            </PrimaryButton>
          );
      },
    },
  ];

  const dateMemo = useMemo(() => {
    return sale?.docs ?? [];
  }, [sale?.docs]);

  const columnMemo = useMemo(() => columns ?? [], [columns]);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      let filter = {
        createdAt: {
          $gt: formik?.values?.from,
          $lt: formik?.values?.to,
        },
        "products.product._id": { $in: formik.values.product },
        "location._id": formik?.values?.location,
        customerId: formik.values.customerId,
        populate: true,
        search: search,
        page,
      };
      dispatch(fetchSales(filter));
    }, 300),
    []
  );

  return (
    <PageWithCard heading="View Sale">
      <ReceiveAmountModal
        isOpen={receiveAmountModalOpen}
        setIsOpen={setReceiveAmountModalOpen}
        data={selectedCustomer}
        pageType="sale"
        saleOrder={selectedSale}
      />
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikSelectGroup
          name="location"
          label="Select Location"
          formik={formik}
          options={generateOptions({
            array: location?.docs ?? [],
            valueField: "_id",
            labelField: "name",
          })}
        />
        <FormikAsyncSelect
          name="customerId"
          formik={formik}
          label="Search Customer"
          getOptions={async (value) => {
            const string = QueryString.stringify({ search: value });
            const customers = await authAxiosInstance.get(
              `users/customer?${string}`
            );
            const options = customers?.data?.data?.docs?.map((ele) => ({
              label: `${ele?.firstName} ${ele?.lastName} / ${ele?.username}`,
              value: ele?._id,
            }));
            return options;
          }}
        />

        <FormikAsyncMultiSelect
          name="product"
          formik={formik}
          label="Search Product"
          getOptions={async (value) => {
            const string = QueryString.stringify({ search: value });
            const products = await authAxiosInstance.get(`product?${string}`);
            const options = products?.data?.data?.docs?.map((ele) => ({
              label: `${ele?.name} / ${ele?.masterSku}`,
              value: ele?._id,
            }));
            return options;
          }}
        />

        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />

        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      <div className="mt-2">
        {totalSalesQty && (
          <div className="flex flex-row gap-2">
            <p className="text text-base font-bold">Total Sales :</p>
            <p>{totalSalesQty}</p>
          </div>
        )}
        <TableWithHeadingAndGlobalSearch
          heading="Sales"
          data={dateMemo}
          loading={loading || isLoading}
          columns={columnMemo}
          searchFunction={(search) => {
            debouncedSearch(search);
          }}
        />
        <PaginationClassic paginationDetails={sale} setPage={setPage} />
      </div>
    </PageWithCard>
  );
};

export default ViewSale;
