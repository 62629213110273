import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  editDeadStock,
  fetchDeadStock,
  getDeadStocks,
} from '../../app/reducers/DeadStockPercentage/deadStockPercentageSlice';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';

const EditDeadStockPercentage = () => {
  const { id } = useParams();
  const { elementEditData, editDataLoading } = useSelector(getDeadStocks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDeadStock({ _id: id }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...elementEditData },
    onSubmit: async values => {
      await dispatch(editDeadStock(values));
      await dispatch(fetchDeadStock({ _id: id }));
    },
  });
  return (
    <PageWithCard>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikInputGroup
          name="deadStockPercent"
          label="Edit Percentage"
          formik={formik}
          required
        />

        <div>
          {editDataLoading || formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default EditDeadStockPercentage;
