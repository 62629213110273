import React, { useEffect, useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransferOrder,
  fetchTransferOrders,
  getTransferOrders,
} from "../../app/reducers/TransferOrder/transferOrderSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import moment from "moment";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";

const ViewStockOut = () => {
  const { transferOrder } = useSelector(getTransferOrders);
  const storeLocation = JSON.parse(localStorage.getItem("saleLocationInfoId"));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchTransferOrders({
        populate: true,
        "from._id": storeLocation,
      })
    );
  }, []);

  const navigate = useNavigate();

  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format("DD/MM/YYYY")
          : "No Date Found";
      },
    },
    {
      Header: "from",
      accessor: "from.name",
    },
    {
      Header: "to",
      accessor: "to.name",
    },
    {
      Header: "Stock out number",
      Cell: ({ row }) => {
        return <div>{row?.original?._id}</div>;
      },
    },
    {
      Header: "Qty",
      Cell: ({ row }) => {
        return row.original.totalQuantity;
      },
    },
    {
      Header: "action",
      Cell: ({ row }) => {
        return (
          <PrimaryButton
            onClick={() => {
              navigate(`${row.original._id}`);
            }}
          >
            View
          </PrimaryButton>
        );
      },
    },
  ];

  return (
    <PageWithCard heading="View stock out">
      <TableWithHeadingAndSearch
        heading={"View stock out"}
        data={transferOrder?.docs ?? []}
        columns={columns}
      />
    </PageWithCard>
  );
};

export default ViewStockOut;
