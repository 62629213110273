import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import { ClipLoader } from "react-spinners";
import _ from "lodash";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const FormikPhotoInput = ({ formik, name }) => {
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(
    _.at(formik.values, name)[0] ? _.at(formik.values, name)[0].url : null
  );
  return (
    <>
      {loading ? (
        <ClipLoader />
      ) : img ? (
        <img src={img} className="w-full" />
      ) : (
        <input
          type="file"
          name={name}
          value={formik.values[name]}
          onChange={async (e) => {
            setLoading(true);
            const img = await resizeFile(e.currentTarget.files[0]);
            setLoading(false);
            setImg(window.URL.createObjectURL(img));
            formik.setFieldValue(name, img);
          }}
        />
      )}
    </>
  );
};

export default FormikPhotoInput;
