import React from "react";
import Select from "react-select";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";

const computeValue = (options, val) => {
  let resp = options?.filter((option) => val?.includes(option.value));

  return resp ? resp : null;
};
const FormikMultiSelect = ({
  formik,
  label = "",
  required = false,
  options,
  name,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState([]);
  useEffect(() => {
    const value = _.at(formik.values, name)[0];
    if (value) {
      setCurrentValue(value);
    }
  }, [formik.values]);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="w-full"
    >
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>
      <Select
        name={name}
        value={computeValue(options, currentValue)}
        options={options}
        onBlur={formik.handleBlur}
        onChange={(selectedOption) => {
          const values = selectedOption.map((option) => option.value);
          formik.setFieldValue(name, values);
        }}
        isMulti
        {...props}
      />
      {formik?.errors?.[name]?.value && formik?.touched?.[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name].value}</p>
      ) : null}
    </div>
  );
};

export default FormikMultiSelect;
