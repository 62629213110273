import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  createFolder,
  getMediaLibrary,
  openFolderModal,
} from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import * as Yup from 'yup';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';

const AddFolderModal = () => {
  const { addFolderModalOpen, loading } = useSelector(getMediaLibrary);
  const location = useLocation();
  const folder = location.pathname.replace('/media-library', '')
    ? `${location.pathname.replace('/media-library/', '')}/`
    : '/';
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentFolder: folder,
      folderName: '',
    },
    validationSchema: Yup.object({
      currentFolder: Yup.string().required('key is reqired'),
      folderName: Yup.string()
        .required('name is required')
        .matches(/^[\w," "]+$/, 'invalid name'),
    }),
    onSubmit: values => {
      dispatch(createFolder(values));
    },
  });

  const dispatch = useDispatch();
  const setModalState = state => {
    dispatch(openFolderModal(state));
  };
  return (
    <ModalBasic modalOpen={addFolderModalOpen} setModalOpen={setModalState} title="Add Folder">
      {loading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="p-4 flex flex-col gap-4">
          <FormikInputGroup
            label="Current Folder"
            required
            name="currentFolder"
            readOnly
            formik={formik}
          />
          <FormikInputGroup formik={formik} label="Folder Name" required name="folderName" />
          <div>
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </ModalBasic>
  );
};

export default AddFolderModal;
