import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';


const MessageTextModal = ({
    openMessageBox,
    setOpenMessageBox,
    linkOption,
}) => {
    // console.log('data', requestData);
    // console.log('link obj', linkOption);
    const computeInitialValue = (data) => {
        if (data) {
            return data;
        } else {
            return {
                text: '',
                phone : '',
            };
        }
    };
   
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(linkOption),
        onSubmit: (values) => {
            console.log('values', values);
            const url = `https://wa.me/+${values?.phone}?text=${values?.text}`;
            if (url) {
                window.open(url, '_blank');
            }
            setOpenMessageBox(false)
        },
    });
    useEffect(()=>{
        if(openMessageBox === false){
            formik.setValues(linkOption)
        }
    },[openMessageBox])
    return (
        <ModalBasic modalOpen={openMessageBox} setModalOpen={setOpenMessageBox}>
            <div className="p-4">
                <form onSubmit={formik.handleSubmit} className='flex flex-col gap-2'>
                    <FormikInputGroup
                        formik={formik}
                        label='Phone Number'
                        name = 'phone'
                        
                    />
                    <FormikTextareaGroup
                        formik={formik}
                        label="Message"
                        name="text"
                    />
                    <div>
                        <button
                            className="btn bg-green-500 hover:bg-green-600 text-white"
                            type="submit"
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </ModalBasic>
    );
};

export default MessageTextModal;
