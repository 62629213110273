//
import { toast } from "react-toastify";
import { exportTxt } from "./Utils";

export const generateBarcodeDataByQty = async (data) => {
  const outputData = data?.reduce((accumulator, item) => {
    const { productDetail, quantity } = item;
    for (let i = 0; i < quantity; i++) {
      accumulator.push(productDetail);
    }
    return accumulator;
  }, []);

  const resp = await exportTxt(outputData);
  if (resp) {
    toast.success("File Downloaded Successfully...");
  }
};
