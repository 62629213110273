import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";

const ProductAdditionalSkuModal = ({
  isOpenAdditionalSkuModel,
  setIsOpenAdditionalSkuModel,
  productAdditionalSkuData,
}) => {
  return (
    <ModalBasic
      setModalOpen={setIsOpenAdditionalSkuModel}
      modalOpen={isOpenAdditionalSkuModel}
      title="Additional Sku"
    >
      <div className=" w-full p-2">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex flex-row justify-between gap-2  p-2 underline hover:cursor-pointer">
            <p className="text text-base font-extrabold">Platform</p>
            <p className="text text-base font-extrabold">SKU</p>
          </div>
          {productAdditionalSkuData?.length > 0 &&
            productAdditionalSkuData?.map((el, index) => (
              <>
                <div className="flex flex-row justify-between   p-2 hover:cursor-pointer">
                  <p className="text text-base font-semibold">{el?.platform}</p>
                  <p className="text text-base font-semibold">{el?.sku}</p>
                </div>
              </>
            ))}
        </div>
      </div>
    </ModalBasic>
  );
};
export default ProductAdditionalSkuModal;
