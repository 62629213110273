import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "transferOrder";

export const fetchTransferOrders = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editTransferOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchTransferOrder = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteTransferOrder = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createTransferOrder = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const transferOrderSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchTransferOrders, SLICE_NAME),
    ...createData.generateExtraReducers(createTransferOrder, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteTransferOrder, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchTransferOrder, SLICE_NAME),
    ...editData.generateExtraReducers(editTransferOrder, SLICE_NAME),
  },
});

export const getTransferOrders = (state) => state.transferOrder;
export const { setEditId } = transferOrderSlice.actions;
export default transferOrderSlice.reducer;
