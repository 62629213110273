import React, { useEffect, useMemo, useState } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { object, string } from 'yup';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { createStatus, deleteStatus, fetchStatuses, getStatuses } from '../../app/reducers/Status/statusSlice';
import { Edit, Trash } from 'react-feather';
import EditStatusModal from './EditStatusModal';

const AddStatus = () => {
    const { status } = useSelector(getStatuses);
    const [requestData,setRequestData] = useState({})
    const [openEditModal,setOpenEditModal] = useState(false)
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues:{
            name : '',
        },
        validationSchema : object({
            name : string().required()
        }),
        onSubmit :async(values,{resetForm})=>{
            console.log('values', values)
            await dispatch(createStatus(values)).then((res)=>{
                if(res.payload.success){
                    dispatch(fetchStatuses())
                }
            })
            resetForm();
        }
    });

    useEffect(()=>{
        dispatch(fetchStatuses({populate : true}))
    },[]);

    const statsCols = [
        {
            Header : 'Sr.No.',
            Cell : ({row})=>{
                return (
                    <span>{row?.index + 1}</span>
                )
            }
        },
        {
            Header : 'Name',
            accessor : 'name'
        },
        {
            Header : ' ',
            Cell : ({row})=>{
                return (
                    <div className='flex flex-row gap-4'>
                        <Edit 
                            color='blue'
                            className='cursor-pointer'
                            onClick={(e)=>{
                                e.stopPropagation();
                                setRequestData(row?.original)
                                setOpenEditModal(true)
                            }}
                        />
                        <Trash 
                            color='red'
                            className='cursor-pointer'
                            onClick={async(e)=>{
                                e.stopPropagation();
                                await dispatch(deleteStatus({
                                    id : row?.original?._id,
                                }))
                                await dispatch(fetchStatuses())
                            }}
                        />
                    </div>
                )
            }
        }
    ];
    const statsMemo = useMemo(()=>(status?.docs ? status?.docs : []),[status])
  return (
    <PageWithCard heading='Add Status'>
        <EditStatusModal
            requestData={requestData}
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
        />
      <form
        onSubmit={formik.handleSubmit}
        className='flex flex-col gap-4 mb-4'
      >
        <FormikInputGroup 
            formik={formik}
            label='Name'
            name='name'
            required
        />
        <div>
            <PrimaryButton type='submit'>
                Submit
            </PrimaryButton>
        </div>
      </form>
      <TableWithHeadingAndSearch 
        columns={statsCols}
        data={statsMemo}
        heading={'All Status'}
      />
    </PageWithCard>
  )
}

export default AddStatus
