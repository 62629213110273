import { createSlice } from "@reduxjs/toolkit";
import {
  generateSetEditId,
  resetData,
} from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "product";

export const fetchProducts = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editProduct = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchProduct = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteProduct = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createProduct = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const productSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
    resetProductData: resetData(generateStateSingle(SLICE_NAME)),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchProducts, SLICE_NAME),
    ...createData.generateExtraReducers(createProduct, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteProduct, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchProduct, SLICE_NAME),
    ...editData.generateExtraReducers(editProduct, SLICE_NAME),
  },
});

export const getProducts = (state) => state.product;
export const { setEditId, resetProductData } = productSlice.actions;
export default productSlice.reducer;
