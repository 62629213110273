import React from "react";
import Select from "react-select";
import _ from "lodash";

const computeValue = (options, val) => {
  const resp = options?.find((option) => option.value === val);

  return resp ? resp : null;
};

const FormikSelectGroup = ({
  formik,
  label = "",
  required = false,
  options,
  name,
  refetch = false,
  ignoreDotInName = false,
  ...props
}) => {
  const onChange = props?.onChange
    ? props.onChange
    : (selectedOption) => {
        formik.setFieldValue(name, selectedOption.value);
      };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="w-full"
    >
      <div className="flex items-center gap-2  mb-1">
        <label className="block text-sm font-medium" htmlFor={name}>
          {label} {required && <span className="text-rose-500">*</span>}
        </label>
        {refetch && (
          <p onClick={refetch} className="text-xs underline">
            Refetch
          </p>
        )}
      </div>
      <Select
        name={name}
        value={computeValue(options, _.at(formik.values, name)[0])}
        options={options}
        onBlur={formik.handleBlur}
        onChange={onChange}
        {...props}
      />
      {formik?.errors?.[name] && formik?.touched?.[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikSelectGroup;
