import React from "react";
import { X } from "react-feather";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { useSelector } from "react-redux";
import { number } from "yup";

const SelectedProductRow = ({ formik, element, index, arrayHelpers }) => {
  const { baseUrl } = useSelector(getMediaLibrary);

  const percentOf = (percent, value, taxType, ceil = true) => {
    if (ceil && taxType == "inclusive") {
      // return ((percent / 100) * value)?.toFixed(2);
      return ((value * percent) / (100 + percent))?.toFixed(2);
    } else {
      return ((percent / 100) * value)?.toFixed(2);
    }
  };

  const calculateAmounts = () => {
    const product = formik.values.products[index];
    const discount = product.MRP - product.per_piece_price;
    const totalDiscount = discount * product.quantity;
    const tax_rate = parseInt(product.per_piece_tax);
    const totalAmountBeforeTax = product.per_piece_price * product.quantity;
    // if (product.tax_type == "exclusive") {

    //   const tax = (totalAmountBeforeTax * tax_rate) / 100;
    //   totalAmountAfterTax = tax + totalAmountBeforeTax;
    // } else {
    //   totalAmountAfterTax = product.per_piece_price * product.quantity;
    // }
    const perPieceTax = percentOf(
      tax_rate,
      parseInt(product.per_piece_price),
      product.tax_type
    );
    let totalAmountAfterTax =
      parseInt(perPieceTax) + parseInt(totalAmountBeforeTax);

    return {
      totalDiscount: totalDiscount || 0,
      perPieceDiscount: discount || 0,
      totalAmount: totalAmountAfterTax || 0,
      perPieceTaxAmt: perPieceTax || 0,
      totalTaxAmt: perPieceTax * product.quantity || 0,
    };
  };

  return (
    <>
      <tr key={element.product}>
        <td className="px-2 py-3 first:pl-5 last:pr-5 ">
          <div className="flex">
            <X onClick={() => arrayHelpers.remove(index)} />
          </div>
        </td>
        <td>
          {formik.values.products[index].photo && (
            <img
              src={`${baseUrl + formik.values.products[index].photo}`}
              style={{ height: "50px", width: "50px" }}
            />
          )}
        </td>
        <td>
          {
            <FormikInputGroup
              name={`products.${index}.name`}
              formik={formik}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.quantity`}
              formik={formik}
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.MRP`}
              formik={formik}
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.per_piece_price`}
              formik={formik}
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.per_piece_discount`}
              formik={formik}
              value={calculateAmounts().perPieceDiscount}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.total_discount`}
              formik={formik}
              value={calculateAmounts().totalDiscount}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              name={`products.${index}.tax_type`}
              formik={formik}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.per_piece_tax`}
              formik={formik}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.per_piece_tax_amt`}
              formik={formik}
              value={Number(calculateAmounts().perPieceTaxAmt)}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.total_tax_amt`}
              formik={formik}
              value={Number(calculateAmounts().totalTaxAmt)}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.total_price`}
              formik={formik}
              value={calculateAmounts().totalAmount}
              readOnly
            />
          }
        </td>
      </tr>
    </>
  );
};

export default SelectedProductRow;
