import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers, getCustomers } from '../../app/reducers/Users/CustomerSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { useFormik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import { exportCsv } from '../../utils/Utils';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';

const CustomerWiseProductReport = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [customerWiseProductData, setCustomerWiseProductData] = useState({});
  const [customerWiseProductCountData, setCustomerWiseProductCountData] = useState({});
  const { customer, loading } = useSelector(getCustomers);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: [],
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      try {
        setIsLoading(true);
        let filterData = {
          createdAt: { $gt: values?.from, $lt: values?.to },
          page,
        };
        if (values?.customer) {
          filterData['customerId'] = { $in: values?.customer };
        }
        const string = QueryString.stringify(filterData);
        const response = await authAxiosInstance.get(`/report/customer-product-sales?${string}`);
        if (response?.data) {
          setCustomerWiseProductData(response?.data?.data);
        } else {
          setCustomerWiseProductData({});
        }
        const countResponse = await authAxiosInstance.get(
          `/report/customer-product-sales-count?${string}`
        );
        if (countResponse?.data) {
          setCustomerWiseProductCountData(countResponse?.data?.data?.docs[0]);
        } else {
          setCustomerWiseProductCountData({});
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Error Fetching Product Sales ');
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    dispatch(fetchCustomers());
    getCustomerWiseProductData();
    getCustomerWiseProductCountData();
  }, [page]);

  const getCustomerWiseProductData = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        page,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(`/report/customer-product-sales?${string}`);

      if (response?.data) {
        setCustomerWiseProductData(response?.data?.data);
      } else {
        setCustomerWiseProductData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Fetching Product Sales ');
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomerWiseProductCountData = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        page,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(
        `/report/customer-product-sales-count?${string}`
      );

      if (response?.data) {
        setCustomerWiseProductCountData(response?.data?.data?.docs[0]);
      } else {
        setCustomerWiseProductCountData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Fetching Product Sales Count ');
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: 'SRNo.',
      Cell: ({ row }) => {
        return row.index + customerWiseProductData?.pagingCounter;
      },
    },
    {
      Header: 'Customer',
      Cell: ({ row }) => {
        return row?.original?.customerData?.firstName + ' ' + row?.original?.customerData?.lastName;
      },
    },
    {
      Header: 'Company',
      accessor: 'customerData.companyName',
    },
    {
      Header: 'Product',
      accessor: 'productData.name',
    },
    {
      Header: 'Parent Sku',
      accessor: 'productData.parentSku',
    },
    {
      Header: 'Master Sku',
      accessor: 'productData.masterSku',
    },
    {
      Header: 'Cost Price',
      accessor: 'productData.costPrice',
    },
    {
      Header: 'Product Qty',
      accessor: 'totalProductQty',
    },
  ];

  const exportCsvDownloadCustomerSalesData = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        limit: 3000,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(`/report/customer-product-sales?${string}`);

      if (response?.data?.data?.docs?.length > 0) {
        let reportData = response?.data?.data?.docs;
        let csvData = reportData.map(item => {
          const customerName = item?.customerData?.firstName + ' ' + item?.customerData?.lastName;
          return {
            Customer: customerName,
            Company: item?.customerData?.companyName,
            Product: item?.productData?.name,
            'Parent Sku': item?.productData?.parentSku,
            'Master Sku': item?.productData?.masterSku,
            'Cost Price': item?.productData?.costPrice,
            ProductQty: item?.totalProductQty,
          };
        });

        exportCsv(csvData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Downloading data');
    } finally {
      setIsLoading(false);
    }
  };

  const columnsMemoization = useMemo(() => columns ?? [], [columns]);
  const dataMemoization = useMemo(
    () => customerWiseProductData?.docs ?? [],
    [customerWiseProductData]
  );

  return (
    <PageWithCard heading="Customer Wise Product Sales Report">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikMultiSelect
          name="customer"
          formik={formik}
          label="Select Customer"
          options={customer?.docs?.map(el => ({
            value: el._id,
            label: `${el.firstName}-${el.lastName}`,
          }))}
        />
        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />
        <div className="flex flex-row justify-between gap-3">
          <div className="flex flex-row gap-3">
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
            <PrimaryButton type="button" onClick={exportCsvDownloadCustomerSalesData}>
              Download
            </PrimaryButton>
          </div>
          <div className="mt-2 mb-2">
            {customerWiseProductCountData && (
              <div className="flex flex-row gap-2">
                <div className="flex flex-row gap-1">
                  <p className="text text-base font-semibold">Total Sales:</p>
                  <p>{customerWiseProductCountData?.totalSaleCount}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      {isLoading || loading ? (
        <ClipLoader />
      ) : (
        <>
          <div className="mt-3">
            <TableWithHeadingAndSearch data={dataMemoization} columns={columnsMemoization} />
          </div>
          <PaginationClassic paginationDetails={customerWiseProductData} setPage={setPage} />
        </>
      )}
    </PageWithCard>
  );
};

export default CustomerWiseProductReport;
