import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "lead";

export const fetchLeads = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editLead = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchLead = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteLead = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createLead = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const leadSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchLeads, SLICE_NAME),
    ...createData.generateExtraReducers(createLead, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteLead, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchLead, SLICE_NAME),
    ...editData.generateExtraReducers(editLead, SLICE_NAME),
  },
});

export const getLeads = (state) => state.lead;

export default leadSlice.reducer;
