import { useFormik } from 'formik';
import QueryString from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { exportCsv } from '../../utils/Utils';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import moment from 'moment';
import { toast } from 'react-toastify';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { ClipLoader } from 'react-spinners';
import FormikAsyncMultiSelect from '../../components/formik/FormikAsyncMultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';

const ProductSalesReport = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [productSalesData, setProductSalesData] = useState({});
  const [productSalesCountData, setProductSalesCountData] = useState({});
  const { location, loading } = useSelector(getLocation);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      product: [],
      location: [],
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      try {
        setIsLoading(true);
        const filter = {
          createdAt: { $gt: values?.from, $lt: values?.to },
          page,
        };
        if (values?.product) {
          filter['products.product'] = { $in: values?.product };
        }
        if (values?.location) {
          filter['location'] = { $in: values?.location };
        }
        const string = QueryString.stringify(filter);
        const response = await authAxiosInstance.get(`/report/product-sales?${string}`);
        if (response?.data) {
          setProductSalesData(response?.data?.data);
        } else {
          setProductSalesData({});
        }
        const countResponse = await authAxiosInstance.get(`/report/product-sales-count?${string}`);

        if (countResponse?.data) {
          setProductSalesCountData(countResponse?.data?.data?.docs[0]);
        } else {
          setProductSalesCountData({});
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Error fetching Product Sales');
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    getProductSalesData();
    getProductSalesCountData();
    dispatch(fetchLocations());
  }, [page]);

  const getProductSalesData = async () => {
    try {
      setIsLoading(true);
      const filter = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        page,
      };
      if (formik.values.product) {
        filter['products.product'] = { $in: formik?.values?.product };
      }
      if (formik?.values?.location) {
        filter['location'] = { $in: formik?.values?.location };
      }
      const string = QueryString.stringify(filter);
      const response = await authAxiosInstance.get(`/report/product-sales?${string}`);
      if (response?.data) {
        setProductSalesData(response?.data?.data);
      } else {
        setProductSalesData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error fetching Product Sales');
    } finally {
      setIsLoading(false);
    }
  };

  const getProductSalesCountData = async () => {
    try {
      setIsLoading(true);
      const filter = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        page,
      };
      if (formik?.values?.product) {
        filter['products.product'] = { $in: formik?.values?.product };
      }
      if (formik?.values?.location) {
        filter['location'] = { $in: formik?.values?.location };
      }
      const string = QueryString.stringify(filter);
      const response = await authAxiosInstance.get(`/report/product-sales-count?${string}`);

      if (response?.data) {
        setProductSalesCountData(response?.data?.data?.docs[0]);
      } else {
        setProductSalesCountData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Fetching Product Sales Count ');
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: 'SRNo.',
      Cell: ({ row }) => {
        return row.index + productSalesData?.pagingCounter;
      },
    },
    {
      Header: 'Location',
      accessor: 'locationData.name',
    },
    {
      Header: 'Product',
      accessor: 'productData.name',
    },
    {
      Header: 'Parent Sku',
      accessor: 'productData.parentSku',
    },
    {
      Header: 'Master Sku',
      accessor: 'productData.masterSku',
    },
    {
      Header: 'Cost Price',
      accessor: 'productData.costPrice',
    },
    {
      Header: 'Product Qty',
      accessor: 'totalQty',
    },
  ];

  const exportCsvDownloadProductSalesData = async () => {
    try {
      setIsLoading(true);
      const filter = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        limit: 3000,
      };
      if (formik.values.product) {
        filter['products.product'] = { $in: formik?.values?.product };
      }
      const string = QueryString.stringify(filter);
      const response = await authAxiosInstance.get(`/report/product-sales?${string}`);

      if (response?.data?.data?.docs?.length > 0) {
        let reportData = response?.data?.data?.docs;
        let csvData = reportData.map(item => {
          return {
            Location: item?.locationData?.name,
            Product: item?.productData?.name,
            'Parent Sku': item?.productData?.parentSku,
            'Master Sku': item?.productData?.masterSku,
            'Cost Price': item?.productData?.costPrice,
            ProductQty: item?.totalQty,
          };
        });

        exportCsv(csvData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Downloading data');
    } finally {
      setIsLoading(false);
    }
  };

  const columnsMemoization = useMemo(() => columns ?? [], [columns]);
  const dataMemoization = useMemo(() => productSalesData?.docs ?? [], [productSalesData]);

  return (
    <PageWithCard heading="Product Sales Report">
      <form onSubmit={formik.handleSubmit}>
        <FormikAsyncMultiSelect
          getOptions={async value => {
            const string = QueryString.stringify({ search: value });
            const productsResponse = await authAxiosInstance.get(`product?${string}`);
            const options = productsResponse?.data?.data?.docs?.map((el, i) => {
              return {
                label: `${el?.name} / ${el?.masterSku}`,
                value: el?._id,
              };
            });
            return options;
          }}
          name={`product`}
          formik={formik}
          label="Search Product"
        />
        <FormikMultiSelect
          name="location"
          formik={formik}
          label="Select Location"
          options={location?.docs?.map(el => ({
            value: el._id,
            label: el.name,
          }))}
        />
        <FormikInputDateGroup label="From" name="from" formik={formik} />
        <FormikInputDateGroup label="To" name="to" formik={formik} />

        <div className="mt-2 flex flex-row gap-3">
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
          <PrimaryButton type="button" onClick={exportCsvDownloadProductSalesData}>
            Download
          </PrimaryButton>
        </div>
      </form>
      {isLoading || loading ? (
        <ClipLoader />
      ) : (
        <>
          <div className="mt-2 mb-2">
            {productSalesCountData && (
              <div className="flex flex-row gap-2">
                <div className="flex flex-row gap-1">
                  <p className="text text-base font-semibold">Total Product Qty:</p>
                  <p>{productSalesCountData?.totalProductSalesCount}</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-3">
            <TableWithHeadingAndSearch data={dataMemoization} columns={columnsMemoization} />
          </div>
          <PaginationClassic paginationDetails={productSalesData} setPage={setPage} />
        </>
      )}
    </PageWithCard>
  );
};

export default ProductSalesReport;
