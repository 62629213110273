import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "attributeType";

export const fetchAttributeTypes = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editAttributeType = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchAttributeType = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteAttributeType = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createAttributeType = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const attributeTypeSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchAttributeTypes, SLICE_NAME),
    ...createData.generateExtraReducers(createAttributeType, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteAttributeType, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchAttributeType, SLICE_NAME),
    ...editData.generateExtraReducers(editAttributeType, SLICE_NAME),
  },
});

export const getAttributeTypes = (state) => state.attributeType;
export const { setEditId } = attributeTypeSlice.actions;
export default attributeTypeSlice.reducer;
