import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editProductChallan,
  fetchProductChallan,
  getProductChallans,
} from '../../app/reducers/ProductChallan/productChallanSlice';
import { useParams } from 'react-router-dom';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { ClipLoader } from 'react-spinners';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useFormik } from 'formik';
import { generateOptions } from '../../utils/Utils';
import { fetchProducts, getProducts } from '../../app/reducers/Product/productSlice';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';

const EditDirectProductPurchaseOrder = () => {
  const { id } = useParams();
  const { product } = useSelector(getProducts);
  const { vendor } = useSelector(getVendors);
  const { location } = useSelector(getLocation);
  const { elementEditData, editDataLoading } = useSelector(getProductChallans);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductChallan({ _id: id }));
    dispatch(fetchProducts());
    dispatch(fetchVendors());
    dispatch(fetchLocations());
  }, [id]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
    },
    onSubmit: async values => {
      const payload = {
        ...values,
        receivedQty: Number(values?.receivedQty),
        damaged: Number(values?.damaged),
      };
      await dispatch(editProductChallan(payload));
      await dispatch(fetchProductChallan({ _id: id }));
    },
  });
  return (
    <PageWithCard>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikSelectGroup
          options={generateOptions({
            array: product?.docs ?? [],
            labelField: 'name',
            valueField: '_id',
          })}
          label="Product"
          name="product"
          formik={formik}
        />
        <FormikSelectGroup
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: 'name',
            valueField: '_id',
          })}
          label="Location"
          name="location"
          formik={formik}
        />
        <FormikSelectGroup
          options={generateOptions({
            array: vendor?.docs ?? [],
            labelField: 'companyName',
            valueField: '_id',
          })}
          label="Manufacturer"
          name="manufacturer"
          formik={formik}
        />
        <FormikInputGroup name="challanNumber" label="Challan Number" formik={formik} />
        <FormikInputDateGroup name="challanDate" label="Date" formik={formik} />
        <FormikInputGroup name="receivedQty" label="Quantity" formik={formik} />
        <FormikInputGroup name="damaged" label="Damaged" formik={formik} />
        <div>
          {editDataLoading || formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default EditDirectProductPurchaseOrder;
