import React, { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from '../../app/reducers/Users/EmployeeSlice';
import { ClipLoader } from 'react-spinners';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';

const EditEmployee = () => {
  const navigate = useNavigate();
  const { employees, loading } = useSelector(getEmployees);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      empId: '',
    },
    validationSchema: object({
      empId: string().required(),
    }),
    onSubmit: () => {
      navigate(`/users/edit-employee/${formik.values.empId}`);
    },
  });
  return (
    <PageWithCard>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        {loading ? (
          <ClipLoader />
        ) : (
          <FormikSelectGroup
            name="empId"
            label="Employee Id"
            formik={formik}
            required
            options={generateOptions({
              array: employees?.docs,
              labelField: 'username',
              valueField: '_id',
            })}
          />
        )}
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default EditEmployee;
