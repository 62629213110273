import React from "react";
import { X } from "react-feather";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { useSelector } from "react-redux";
import { number } from "yup";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";

const DispatchProductTable = ({ formik, element, index, arrayHelpers }) => {
  return (
    <>
      <tr key={element.product}>
        <td className="px-2 py-3 first:pl-5 last:pr-5 ">
          <div className="flex">
            <X onClick={() => arrayHelpers.remove(index)} />
          </div>
        </td>

        <td>
          {
            <FormikInputGroup
              name={`products.${index}.name`}
              formik={formik}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.saleOrderQuantity`}
              formik={formik}
              readOnly
            />
          }
        </td>
        <td>
          {
            <FormikInputGroup
              type="number"
              name={`products.${index}.quantity`}
              formik={formik}
            />
          }
        </td>
      </tr>
    </>
  );
};

export default DispatchProductTable;
