import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "saleOrder";

export const fetchSaleOrders = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editSaleOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchSaleOrder = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteSaleOrder = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createSaleOrder = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const saleOrderSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchSaleOrders, SLICE_NAME),
    ...createData.generateExtraReducers(createSaleOrder, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteSaleOrder, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchSaleOrder, SLICE_NAME),
    ...editData.generateExtraReducers(editSaleOrder, SLICE_NAME),
  },
});

export const getSaleOrders = (state) => state.saleOrder;
export const { setEditId } = saleOrderSlice.actions;
export default saleOrderSlice.reducer;
