import React, { useEffect, useMemo } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransferOrder,
  fetchTransferOrders,
  getTransferOrders,
} from "../../app/reducers/TransferOrder/transferOrderSlice";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import moment from "moment";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";

const StockIn = () => {
  const dispatch = useDispatch();
  const { transferOrder } = useSelector(getTransferOrders);
  const storeLocation = JSON.parse(localStorage.getItem("saleLocationInfoId"));
  useEffect(() => {
    dispatch(
      fetchTransferOrders({
        populate: true,
        "to._id": storeLocation,
      })
    );
  }, []);
  const navigate = useNavigate();

  const dataMemo = useMemo(() => {
    return transferOrder.docs
      ? [...transferOrder.docs].sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : -1
        )
      : [];
  }, [transferOrder]);
  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format("DD/MM/YYYY")
          : "No Date Found";
      },
    },
    {
      Header: "from",
      accessor: "from.name",
    },
    {
      Header: "to",
      accessor: "to.name",
    },
    {
      Header: "Stock out number",
      Cell: ({ row }) => {
        return <div>{row?.original?._id}</div>;
      },
    },
    {
      Header: "Qty",
      Cell: ({ row }) => {
        return row.original.totalQuantity;
      },
    },
    {
      Header: "action",
      Cell: ({ row }) => {
        return (
          <PrimaryButton
            onClick={() => {
              navigate(`${row.original._id}`);
            }}
          >
            View
          </PrimaryButton>
        );
      },
    },
  ];

  return (
    <PageWithCard heading="Receive Stock">
      <TableWithHeadingAndSearch data={dataMemo} columns={columns} />
    </PageWithCard>
  );
};

export default StockIn;
