import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "size";

export const fetchSizes = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editSize = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchSize = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteSize = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createSize = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const sizeSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchSizes, SLICE_NAME),
    ...createData.generateExtraReducers(createSize, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteSize, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchSize, SLICE_NAME),
    ...editData.generateExtraReducers(editSize, SLICE_NAME),
  },
});

export const getSizes = (state) => state.size;
export const { setEditId } = sizeSlice.actions;
export default sizeSlice.reducer;
