import { useFormik } from 'formik';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers, getCustomers } from '../../app/reducers/Users/CustomerSlice';
import { useEffect, useMemo, useState } from 'react';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { toast } from 'react-toastify';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { ClipLoader } from 'react-spinners';
import { exportCsv } from '../../utils/Utils';

const ProductSalesByParentSkuReport = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [productSalesByParentSkuData, setProductSalesByParentSkuData] = useState({});
  const [productSalesByParentSkuCountData, setProductSalesByParentSkuCountData] = useState({});
  const { customer, loading } = useSelector(getCustomers);
  const { location } = useSelector(getLocation);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      location: [],
      customer: [],
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      try {
        setIsLoading(true);
        let filterData = {
          createdAt: { $gt: values?.from, $lt: values?.to },
          page,
        };
        if (values?.customer) {
          filterData['customerId'] = { $in: values?.customer };
        }
        if (formik?.values?.location) {
          filterData['location'] = { $in: values?.location };
        }
        const string = QueryString.stringify(filterData);
        const response = await authAxiosInstance.get(
          `/report/product-sales-by-parent-sku?${string}`
        );
        if (response?.data) {
          setProductSalesByParentSkuData(response?.data?.data);
        } else {
          setProductSalesByParentSkuData({});
        }
        const responseCount = await authAxiosInstance.get(
          `/report/product-sales-by-parent-sku-count?${string}`
        );

        if (responseCount?.data) {
          setProductSalesByParentSkuCountData(responseCount?.data?.data?.docs[0]);
        } else {
          setProductSalesByParentSkuCountData({});
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Error Fetching Sales ');
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    dispatch(fetchCustomers());
    dispatch(fetchLocations());
    getProductSalesByParentSku();
    getProductSalesByParentSkuCount();
  }, [page]);

  const getProductSalesByParentSku = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        page,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      if (formik?.values?.location) {
        filterData['location'] = { $in: formik?.values?.location };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(`/report/product-sales-by-parent-sku?${string}`);

      if (response?.data) {
        setProductSalesByParentSkuData(response?.data?.data);
      } else {
        setProductSalesByParentSkuData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Fetching Sales ');
    } finally {
      setIsLoading(false);
    }
  };
  const getProductSalesByParentSkuCount = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        page,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      if (formik?.values?.location) {
        filterData['location'] = { $in: formik?.values?.location };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(
        `/report/product-sales-by-parent-sku-count?${string}`
      );

      if (response?.data) {
        setProductSalesByParentSkuCountData(response?.data?.data?.docs[0]);
      } else {
        setProductSalesByParentSkuCountData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Fetching Sales ');
    } finally {
      setIsLoading(false);
    }
  };

  const exportCsvDownloadProductSalesByParentSkuData = async () => {
    try {
      setIsLoading(true);
      const filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        limit: 3000,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      if (formik?.values?.location) {
        filterData['location'] = { $in: formik?.values?.location };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(`/report/product-sales-by-parent-sku?${string}`);

      if (response?.data?.data?.docs?.length > 0) {
        let reportData = response?.data?.data?.docs;
        let csvData = reportData?.map(item => {
          return {
            'Parent Sku': item?._id?.parentSku,
            Location: item?.locationData?.name,
            'Total Qty': item?.totalQty,
            'Total Valuation': item?.totalValuationByParentSku,
          };
        });

        exportCsv(csvData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Downloading data');
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: 'SRNo.',
      Cell: ({ row }) => {
        return row.index + productSalesByParentSkuData?.pagingCounter;
      },
    },
    {
      Header: 'Location',
      accessor: 'locationData.name',
    },
    {
      Header: 'Parent Sku',
      accessor: '_id.parentSku',
    },
    {
      Header: 'Total Qty',
      accessor: 'totalQty',
    },
    {
      Header: 'Total Valuation',
      accessor: 'totalValuationByParentSku',
    },
  ];

  const columnsMemoization = useMemo(() => columns ?? [], [columns]);
  const dataMemoization = useMemo(
    () => productSalesByParentSkuData?.docs ?? [],
    [productSalesByParentSkuData]
  );

  return (
    <PageWithCard>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikMultiSelect
          name="customer"
          formik={formik}
          label="Select Customer"
          options={customer?.docs?.map(el => ({
            value: el._id,
            label: `${el.firstName}-${el.lastName}`,
          }))}
        />
        <FormikMultiSelect
          name="location"
          formik={formik}
          label="Select Location"
          options={location?.docs?.map(el => ({
            value: el._id,
            label: el.name,
          }))}
        />
        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />
        <div className="flex gap-3">
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
          <PrimaryButton type="button" onClick={exportCsvDownloadProductSalesByParentSkuData}>
            Download
          </PrimaryButton>
        </div>
      </form>
      {isLoading || loading ? (
        <ClipLoader />
      ) : (
        <>
          <div className="mt-2 mb-2">
            {productSalesByParentSkuCountData && (
              <div className="flex flex-row gap-2">
                <div className="flex flex-row gap-1">
                  <p className="text text-base font-semibold">Total Valuation:</p>
                  <p>{productSalesByParentSkuCountData?.totalCount}</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-3">
            <TableWithHeadingAndSearch data={dataMemoization} columns={columnsMemoization} />
          </div>
          <PaginationClassic paginationDetails={productSalesByParentSkuData} setPage={setPage} />
        </>
      )}
    </PageWithCard>
  );
};

export default ProductSalesByParentSkuReport;
