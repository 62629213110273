import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { useFormik } from "formik";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as Yup from "yup";

const AssignLocation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [locationInfoId, setLocationInfoId] = useState(
    localStorage.getItem("locationInfoId")
  );

  const saleLocationInfoID = JSON.parse(
    localStorage.getItem("saleLocationInfoId")
  );
  const { location, loading } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    initialValues: {
      assignedLocation: locationInfoId ?? [],
    },
    validationSchema: Yup.object({
      assignedLocation: Yup.array().required(),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      if (values) {
        localStorage.setItem(
          "locationInfoId",
          JSON.stringify(values?.assignedLocation)
        );
        setIsLoading(false);
        toast.success("Store assigned...!");
      } else {
        setIsLoading(false);
        toast.error("Store not assigned..");
      }
    },
  });

  const saleFormik = useFormik({
    initialValues: {
      assignSaleLocation: saleLocationInfoID ?? "",
    },
    validationSchema: Yup.object({
      assignSaleLocation: Yup.string().required(),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      if (values) {
        localStorage.setItem(
          "saleLocationInfoId",
          JSON.stringify(values?.assignSaleLocation)
        );
        setIsLoading(false);
        toast.success("Store assigned...!");
      } else {
        setIsLoading(false);
        toast.error("Store not assigned..");
      }
    },
  });

  return (
    <PageWithCard heading="Assign Stores">
      <div className="flex flex-col gap-5">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-3 border p-3 rounded-lg"
        >
          <FormikMultiSelect
            formik={formik}
            name="assignedLocation"
            label="Select Store Location"
            options={generateOptions({
              array: location?.docs ?? [],
              labelField: "name",
              valueField: "_id",
            })}
          />
          {loading || isLoading ? (
            <ClipLoader />
          ) : (
            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          )}
        </form>

        <form
          onSubmit={saleFormik.handleSubmit}
          className="flex flex-col gap-3 border p-3 rounded-lg"
        >
          <FormikSelectGroup
            formik={saleFormik}
            name="assignSaleLocation"
            label="Select Sale Location"
            options={generateOptions({
              array: location?.docs ?? [],
              labelField: "name",
              valueField: "_id",
            })}
          />
          {loading || isLoading ? (
            <ClipLoader />
          ) : (
            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          )}
        </form>
      </div>
    </PageWithCard>
  );
};

export default AssignLocation;
