import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  editAttribute,
  fetchAttribute,
  getAttributes,
} from '../../app/reducers/Attribute/attributeSlice';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { generateOptions } from '../../utils/Utils';
import {
  fetchAttributeTypes,
  getAttributeTypes,
} from '../../app/reducers/AttributeType/attributeTypeSlice';
import { ClipLoader } from 'react-spinners';

const EditAttribute = () => {
  const { id } = useParams();
  const { elementEditData } = useSelector(getAttributes);
  const { attributeType } = useSelector(getAttributeTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAttribute({ _id: id }));
    dispatch(fetchAttributeTypes());
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
    },
    onSubmit: async values => {
      await dispatch(editAttribute(values));
      await dispatch(fetchAttribute({ _id: id }));
    },
  });

  return (
    <PageWithCard heading="Edit Attribute">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikInputGroup formik={formik} name="name" label="Name" required />
        <FormikInputGroup formik={formik} name="code" label="Code" required />
        <FormikSelectGroup
          formik={formik}
          name="type"
          label="Type"
          required
          options={generateOptions({
            array: attributeType?.docs ?? [],
            valueField: '_id',
            labelField: 'type',
          })}
        />
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};
export default EditAttribute;
