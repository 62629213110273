import { createSlice } from "@reduxjs/toolkit";
import {
  generateSetEditId,
  resetData,
} from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "productInventory";

export const fetchProductInventories = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editProductInventory = editData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchProductInventory = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteProductInventory = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createProductInventory = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const productInventorySlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
    resetProductInventoryData: resetData(generateStateSingle(SLICE_NAME)),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchProductInventories, SLICE_NAME),
    ...createData.generateExtraReducers(createProductInventory, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteProductInventory, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchProductInventory, SLICE_NAME),
    ...editData.generateExtraReducers(editProductInventory, SLICE_NAME),
  },
});

export const getProductInventories = (state) => state.productInventory;
export const { setEditId, resetProductInventoryData } =
  productInventorySlice.actions;
export default productInventorySlice.reducer;
