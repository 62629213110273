import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { axiosInstance } from "../../utils/axiosConfig";

const AddDefaults = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: { billing_series: "", organization: organizationId },
    onSubmit: async (values) => {
      try {
        const defaultRes = await axiosInstance.post("/default", values);
        console.log(defaultRes.data);
        navigate("/login", {
          state: {
            message:
              "Organization created, Please login with your password and phone number.",
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <div>
      <div className="flex flex-col items-center justify-center my-6 ">
        <div className=" flex flex-col gap-4  w-[90%]  md:w-[40%]">
          <div>
            <h1 className="text-2xl font-semibold">Add Defaults</h1>
            <div className="border border-black w-full" />
          </div>

          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <FormikInputGroup
              formik={formik}
              label="Billing Series"
              name={"billing_series"}
            />
            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDefaults;
