import { createSlice } from "@reduxjs/toolkit";
import {
  generateSetEditId,
  resetData,
} from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "productChallan";

export const fetchProductChallans = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editProductChallan = editData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchProductChallan = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteProductChallan = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createProductChallan = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const productChallanSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
    resetProductChallanData: resetData(generateStateSingle(SLICE_NAME)),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchProductChallans, SLICE_NAME),
    ...createData.generateExtraReducers(createProductChallan, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteProductChallan, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchProductChallan, SLICE_NAME),
    ...editData.generateExtraReducers(editProductChallan, SLICE_NAME),
  },
});

export const getProductChallans = (state) => state.productChallan;
export const { setEditId, resetProductChallanData } =
  productChallanSlice.actions;
export default productChallanSlice.reducer;
