import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAttributeTypes,
  getAttributeTypes,
} from '../../app/reducers/AttributeType/attributeTypeSlice';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { createAttribute } from '../../app/reducers/Attribute/attributeSlice';
import { ClipLoader } from 'react-spinners';

const AddAttribute = () => {
  const { attributeType, loading } = useSelector(getAttributeTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAttributeTypes());
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
      type: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      code: Yup.string().required(),
      type: Yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      await dispatch(createAttribute(values));
      resetForm();
    },
  });
  return (
    <PageWithCard heading="Add Attribute">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikInputGroup formik={formik} name="name" label="Name" required />
        <FormikInputGroup formik={formik} name="code" label="Code" required />
        {loading ? (
          <ClipLoader />
        ) : (
          <FormikSelectGroup
            formik={formik}
            name="type"
            label="Type"
            required
            options={generateOptions({
              array: attributeType?.docs ?? [],
              valueField: '_id',
              labelField: 'type',
            })}
          />
        )}
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};
export default AddAttribute;
