import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchTransferOrder,
  getTransferOrders,
} from "../../app/reducers/TransferOrder/transferOrderSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";

const ViewStockOutById = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { elementEditData: transferOrderData } = useSelector(getTransferOrders);
  useEffect(() => {
    dispatch(
      fetchTransferOrder({
        _id: _id,
        populate: true,
      })
    );
  }, []);
  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "product name",
      accessor: "product.name",
    },
    {
      Header: "Barcode",
      accessor: "product.masterSku",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Qty",
      Cell: ({ row }) => {
        return row.original.quantity;
      },
    },
  ];
  return (
    <PageWithCard heading={_id}>
      <TableWithHeadingAndSearch
        data={transferOrderData?.products ?? []}
        columns={columns}
      />
    </PageWithCard>
  );
};

export default ViewStockOutById;
