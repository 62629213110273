import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useMemo } from "react";
import { Edit2, Trash } from "react-feather";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { ClipLoader } from "react-spinners";
import EditAttributeModal from "./EditAttributeModal";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import { useNavigate } from "react-router-dom";
import {
  createExpenseCategory,
  deleteExpenseCategory,
  fetchExpenseCategories,
  getExpenseCategories,
} from "../../app/reducers/ExpenseCategory/expenseCategorySlice";

const ExpenseCategory = () => {
  const [page, setPage] = useState(1);
  const { expenseCategory, loading } = useSelector(getExpenseCategories);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchExpenseCategories({ page }));
  }, [page]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await dispatch(createExpenseCategory(values));
      await dispatch(fetchExpenseCategories());
      formik.resetForm();
    },
  });

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={(e) => {
                navigate(`/master/expenseCategory/edit/${row.original._id}`);
              }}
            />

            <Trash
              color="red"
              onClick={async () => {
                await dispatch(
                  deleteExpenseCategory({ id: row?.original?._id })
                );
                await dispatch(fetchExpenseCategories());
              }}
            />
          </div>
        );
      },
    },
  ];

  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(
    () => expenseCategory?.docs ?? [],
    [expenseCategory]
  );

  return (
    <PageWithCard heading="Add Bank">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikInputGroup formik={formik} name="name" label="Name" required />
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      <div className="mt-2">
        {loading ? (
          <ClipLoader />
        ) : (
          <>
            <TableWithHeadingAndSearch
              heading="View Banks"
              columns={columnsMemo}
              data={dataMemo}
            />
            <PaginationClassic
              setPage={setPage}
              paginationDetails={expenseCategory}
            />
          </>
        )}
      </div>
    </PageWithCard>
  );
};
export default ExpenseCategory;
