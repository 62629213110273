import React, { useRef } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";

const FormikAsyncMultiSelect = ({
  formik,
  label = "",
  required = false,
  name,
  onChange,
  getOptions,
  ...props
}) => {
  const computeValue = (options, val) => {
    let resp = options?.filter((option) => val.includes(option.value));

    return resp ? resp : null;
  };
  const [options, setOptions] = useState([]);
  const handleChange = onChange
    ? onChange
    : (selectedOption) => {
        const values = selectedOption.map((option) => option.value);
        formik.setFieldValue(name, values);
      };
  const loadOptions = async (value) => {
    const options = await getOptions(value);
    setOptions(options);
    return options;
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>
      <AsyncSelect
        name={name}
        value={computeValue(options, _.at(formik.values, name)[0])}
        onBlur={formik.handleBlur}
        onChange={handleChange}
        loadOptions={loadOptions}
        isMulti
        {...props}
      />
      {formik?.errors?.[name]?.value && formik?.touched?.[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name].value}</p>
      ) : null}
    </div>
  );
};

export default FormikAsyncMultiSelect;
