import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLocation,
  fetchLocations,
  getLocation,
  setEditId,
} from "../../app/reducers/Location/locationSlice";
import { Edit2, Trash } from "react-feather";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { ClipLoader } from "react-spinners";
import EditLocationModal from "./EditLocationModal";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";

const ViewLocation = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { loading, location } = useSelector(getLocation);

  useEffect(() => {
    dispatch(fetchLocations({ page }));
  }, [page]);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Location Name",
      accessor: "name",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Edit2
              onClick={() => {
                dispatch(setEditId(row.original._id));
              }}
            />
            <Trash
              size={18}
              color="red"
              onClick={async () => {
                await dispatch(deleteLocation({ id: row.original._id }));
                await dispatch(fetchLocations());
              }}
            />
          </div>
        );
      },
    },
  ];
  const columnsMemo = useMemo(() => columns, [columns]);
  const data = useMemo(
    () => (location.docs ? location.docs : []),
    [location.docs]
  );

  return (
    <PageWithCard heading="View Locations">
      {loading ? (
        <ClipLoader />
      ) : (
        <>
          <EditLocationModal />
          <TableWithHeadingAndSearch
            heading="Locations"
            columns={columnsMemo}
            data={data}
          />
          <PaginationClassic paginationDetails={location} setPage={setPage} />
        </>
      )}
    </PageWithCard>
  );
};

export default ViewLocation;
