import React, { useEffect, useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import QueryString from "qs";
import moment from "moment";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";

const ViewDispatch = () => {
  const [dispatchData, setDispatchData] = useState([]);

  async function fetchDispatch() {
    const string = QueryString.stringify({
      populate: true,
    });
    const fetchDispatchResp = await authAxiosInstance.get(
      `/dispatch?${string}`
    );
    console.log(fetchDispatchResp);
    setDispatchData(fetchDispatchResp?.data?.data?.docs ?? []);
  }

  useEffect(() => {
    fetchDispatch();
  }, []);

  const navigate = useNavigate();

  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row?.original.createdAt).format("DD/MM/YYYY: HH:mm");
      },
    },

    {
      Header: "Location",
      accessor: "location.name",
    },
    {
      Header: "Dispatch Number",
      accessor: "dispatch_number",
    },
    {
      Header: "Sale Order Number",
      accessor: "saleOrder.saleOrderNumber",
    },
    {
      Header: "Qty",
      Cell: ({ row }) => {
        return row.original.products.reduce(
          (acc, ele) => acc + ele.quantity,
          0
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div>
            <PrimaryButton
              onClick={() => {
                navigate(`${row.original.dispatch_number}`);
              }}
            >
              View
            </PrimaryButton>
          </div>
        );
      },
    },
  ];
  return (
    <PageWithCard heading="View Dispatch">
      <TableWithHeadingAndSearch columns={columns} data={dispatchData} />
    </PageWithCard>
  );
};

export default ViewDispatch;
