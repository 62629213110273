export const generateSetEditId = (element) => {
  return (state, action) => {
    state.elementEditId = action.payload;
  };
};
export const resetData = (initialState) => {
  return (state, action) => {
    return initialState;
  };
};
