import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikAsyncMultiSelect from '../../components/formik/FormikAsyncMultiSelect';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { generateOptions } from '../../utils/Utils';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
  fetchStockAdjustments,
  getStockAdjustments,
} from '../../app/reducers/StockAdjustment/stockAdjustmentSlice';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';

const ViewStockAdjustment = () => {
  const [page, setPage] = useState(1);
  const { stockAdjustment, loading } = useSelector(getStockAdjustments);
  const { location, loading: locationLoading } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStockAdjustments({ populate: true, page }));
    dispatch(fetchLocations());
  }, [page]);

  const formik = useFormik({
    initialValues: {
      location: '',
      products: [],
    },
    onSubmit: async values => {
      let filter = {
        populate: true,
        page,
      };
      if (values?.products) {
        filter['product._id'] = { $in: values.products };
      }
      if (values?.products) {
        filter['location._id'] = values.location;
      }
      await dispatch(fetchStockAdjustments(filter));
    },
  });
  const columns = [
    {
      Header: 'SRNo',
      Cell: ({ row }) => {
        return row?.index + stockAdjustment.pagingCounter;
      },
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'Location',
      accessor: 'location.name',
    },
    {
      Header: 'Product',
      accessor: 'product.name',
    },
    {
      Header: 'Sku',
      accessor: 'product.masterSku',
    },
    {
      Header: 'Adjustment Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Type',
      accessor: 'transactionType',
    },
    {
      Header: 'Reason',
      accessor: 'reason',
    },
  ];

  const dataMemoization = useMemo(() => stockAdjustment?.docs ?? [], [stockAdjustment]);

  const columnMemoization = useMemo(() => columns ?? [], [columns]);

  return (
    <PageWithCard>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikSelectGroup
          formik={formik}
          name="location"
          label="Select Location"
          options={generateOptions({
            array: location?.docs ?? [],
            valueField: '_id',
            labelField: 'name',
          })}
        />
        <FormikAsyncMultiSelect
          label="Search Product"
          name="products"
          formik={formik}
          getOptions={async value => {
            const query = {
              search: value,
            };
            const string = QueryString.stringify(query);
            const productResp = await authAxiosInstance.get(`product?${string}`);
            console.log(productResp);
            const options = productResp?.data?.data?.docs?.map((el, i) => ({
              value: el?._id,
              label: `${el?.name} / ${el?.masterSku}`,
            }));
            return options;
          }}
        />
        <div>
          {loading || locationLoading || formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      <div className="mt-3">
        <TableWithHeadingAndSearch
          columns={columnMemoization}
          data={dataMemoization}
          heading="View Stock Adjustment"
        />
        <PaginationClassic setPage={setPage} paginationDetails={stockAdjustment} />
      </div>
    </PageWithCard>
  );
};

export default ViewStockAdjustment;
