import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteCustomer,
  fetchCustomers,
  getCustomers,
} from "../../app/reducers/Users/CustomerSlice";
import { Edit2, Trash } from "react-feather";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { ClipLoader } from "react-spinners";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";

const ViewCustomer = () => {
  const [page, setPage] = useState(1);
  const { customer, loading } = useSelector(getCustomers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCustomers({ page }));
  }, [page]);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Vendor Name",
      Cell: ({ row }) => {
        return (
          <Link to={`credit/${row.original._id}`}>
            <h1 className="text-blue-600 underline cursor-pointer ">
              {row?.original?.firstName + " " + row?.original?.lastName}
            </h1>
          </Link>
        );
      },
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "GST No.",
      accessor: "gstNumber",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={(e) => {
                navigate(`/users/edit-customer/${row?.original?._id}`);
              }}
            />

            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteCustomer({ id: row?.original?._id }));
                await dispatch(fetchCustomers());
              }}
            />
          </div>
        );
      },
    },
  ];
  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(
    () => (customer.docs ? customer.docs : []),
    [customer.docs]
  );
  return (
    <PageWithCard heading="View Customer">
      {loading ? (
        <ClipLoader />
      ) : (
        <>
          <TableWithHeadingAndSearch
            heading="View Customers"
            columns={columnsMemo}
            data={dataMemo}
          />
          <PaginationClassic paginationDetails={customer} setPage={setPage} />
        </>
      )}
    </PageWithCard>
  );
};

export default ViewCustomer;
