import { useFormik } from 'formik';
import React, { useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch } from 'react-redux';
import { createCustomer } from '../../app/reducers/Users/CustomerSlice';
import * as Yup from 'yup';

const AddCustomer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      username: '91',
      gstNumber: '',
      __t: 'customer',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      companyName: Yup.string().required(),
      gstNumber: Yup.string(),
      username: Yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      console.log(values);
      await dispatch(createCustomer(values));
      resetForm();
      setIsLoading(false);
    },
  });

  return (
    <PageWithCard heading="Add Customer">
      {isLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup label="First Name" formik={formik} name="firstName" required />
          <FormikInputGroup label="Last Name" formik={formik} name="lastName" required />
          <FormikInputGroup label="Company Name" formik={formik} name="companyName" required />

          <FormikPhoneInputGroup label="Phone" name="username" formik={formik} required />

          <FormikInputGroup label="GST Number" formik={formik} name="gstNumber" />
          <div>
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default AddCustomer;
