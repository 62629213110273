import React from "react";
import { useSelector } from "react-redux";
import { getAuth } from "../../app/reducers/Auth/authSlice";

const MainMediaLibraryPage = () => {
  const { user } = useSelector(getAuth);
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <a
        className="p-4 text-white bg-indigo-600 rounded-md"
        href={`/media-library/${user.organization}`}
      >
        Enter Media Library
      </a>
    </div>
  );
};

export default MainMediaLibraryPage;
