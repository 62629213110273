import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "creditbook";

export const fetchCreditbooks = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editCreditbook = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCreditbook = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteCreditbook = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createCreditbook = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const creditbookSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchCreditbooks, SLICE_NAME),
    ...createData.generateExtraReducers(createCreditbook, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteCreditbook, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchCreditbook, SLICE_NAME),
    ...editData.generateExtraReducers(editCreditbook, SLICE_NAME),
  },
});

export const getCreditbook = (state) => state.creditbook;
export const { setEditId } = creditbookSlice.actions;
export default creditbookSlice.reducer;
