import { FieldArray, FormikProvider, useFormik } from 'formik';
import React from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch } from 'react-redux';
import { fetchProducts } from '../../app/reducers/Product/productSlice';
import { generateBarcodeDataByQty } from '../../utils/generateBarcodeData';
import { ClipLoader } from 'react-spinners';

const GenerateBarcode = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      list: [
        {
          productDetail: { productName: '', masterSku: '' },
          quantity: 0,
        },
      ],
    },
    onSubmit: async (values, { resetForm }) => {
      generateBarcodeDataByQty(values?.list);
      await resetForm();
    },
  });
  return (
    <PageWithCard heading="Generate Barcodes">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikProvider value={formik}>
          <FieldArray
            name="list"
            render={arrayHelpers => {
              return (
                <div className="flex flex-col gap-4 border rounded-lg p-5">
                  {formik.values.list.map((ele, i) => (
                    <div key={i} className="grid grid-cols-3 gap-4 w-full border p-8  rounded-lg">
                      <FormikAsyncSelect
                        name={`list.${i}.productDetail.`}
                        label="Select Product"
                        formik={formik}
                        getOptions={async value => {
                          const query = {
                            search: value,
                          };
                          const string = QueryString.stringify(query);
                          const resp = await authAxiosInstance.get(`product?${string}`);

                          const products = resp.data.data.docs;
                          return products.map(ele => {
                            return {
                              label: `${ele?.name} / ${ele.masterSku}`,
                              value: ele._id,
                            };
                          });
                        }}
                        onChange={async selectedOption => {
                          const data = await dispatch(
                            fetchProducts({ _id: selectedOption?.value })
                          );
                          const productResp = data?.payload?.data?.docs[0];

                          formik.setFieldValue(
                            `list.${i}.productDetail.productName`,
                            productResp?.name
                          );

                          formik.setFieldValue(
                            `list.${i}.productDetail.masterSku`,
                            productResp?.masterSku
                          );
                        }}
                      />
                      <FormikInputGroup
                        name={`list.${i}.quantity`}
                        label="Quantity"
                        formik={formik}
                      />
                      <div className="mt-6">
                        <DangerButton type="button" onClick={() => arrayHelpers.remove(i)}>
                          Remove
                        </DangerButton>
                      </div>
                    </div>
                  ))}
                  <div className="flex gap-4">
                    <PrimaryButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.push({
                          productDetail: { productName: '', masterSku: '' },
                          quantity: 0,
                        });
                      }}
                    >
                      Add More
                    </PrimaryButton>
                    {formik.isSubmitting ? (
                      <ClipLoader />
                    ) : (
                      <PrimaryButton type="submit">Submit</PrimaryButton>
                    )}
                  </div>
                </div>
              );
            }}
          />
        </FormikProvider>
      </form>
    </PageWithCard>
  );
};

export default GenerateBarcode;
