import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  createWithFile,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";

const SLICE_NAME = "customer";

export const fetchCustomers = fetchData.generateThunk(
  SLICE_NAME,
  "users/customer"
);
export const createCustomer = createData.generateThunk(
  SLICE_NAME,
  "users/customer/register"
);
export const deleteCustomer = deleteData.generateThunk(
  SLICE_NAME,
  "users/customer/remove"
);
export const fetchCustomer = fetchEditData.generateThunk(
  SLICE_NAME,
  "users/customer"
);
export const editCustomer = editData.generateThunk(
  SLICE_NAME,
  "users/customer/update"
);

const customerSlice = createSlice({
  initialState: generateStateSingle(SLICE_NAME),
  name: SLICE_NAME,
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchCustomers, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchCustomer, SLICE_NAME),
    ...createData.generateExtraReducers(createCustomer, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteCustomer, SLICE_NAME),
    ...editData.generateExtraReducers(editCustomer, SLICE_NAME),
  },
});

export const getCustomers = (state) => state.customer;

export default customerSlice.reducer;
