import React from "react";
import _ from "lodash";

const FormikTextareaGroup = ({
  formik,
  label = "",
  required = false,
  name,
  row = 5,
  ...props
}) => {
  return (
    <div>
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>
      <textarea
        value={_.at(formik.values, name)[0]}
        onChange={formik.handleChange}
        name={name}
        onBlur={formik.handleBlur}
        className="w-full form-input"
        rows={row}
        cols={5}
        {...props}
      />
      {formik.errors[name] && formik.touched[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikTextareaGroup;
