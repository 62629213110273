import React, { useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
  fetchProductPurchaseOrders,
  getProductPurchaseOrders,
  resetProductPurchaseOrderData,
} from "../../app/reducers/ProductPurchaseOrder/productPurchaseOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendors, getVendors } from "../../app/reducers/Users/VendorSlice";
import { generateOptions } from "../../utils/Utils";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import TableHeader from "../../components/Table/TableHeader";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import {
  createProductChallan,
  fetchProductChallans,
} from "../../app/reducers/ProductChallan/productChallanSlice";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { array, object, string } from "yup";
import { fetchProducts } from "../../app/reducers/Product/productSlice";
import ProductSelect from "../SalesOrder/ProductSelect";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import QueryString from "qs";
import { X } from "react-feather";

const DirectPurchaseOrder = () => {
  const storeLocation = JSON.parse(localStorage.getItem("saleLocationInfoId"));

  const [manufacturer, setManufacturer] = useState("");
  const [progressLoading, setProgressLoading] = useState(false);

  const [currentItem, setCurrentItem] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [totalItem, setTotalItem] = useState(0);

  const { vendor } = useSelector(getVendors);
  const { productPurchaseOrder, loading } = useSelector(
    getProductPurchaseOrders
  );
  const { location } = useSelector(getLocation);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchVendors());
    dispatch(fetchLocations());

    return () => {
      dispatch(resetProductPurchaseOrderData());
    };
  }, []);

  const productPurchaseOrderData = useMemo(
    () =>
      productPurchaseOrder?.docs
        ? productPurchaseOrder.docs
            .filter(
              (d) =>
                d.status != "completed" &&
                d.status != "settled" &&
                d.status != "direct"
            )
            .map((ele) => ({
              select: false,
              ...ele,
            }))
        : [],
    [productPurchaseOrder]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: manufacturer ?? "",
      products: [],
      challanNumber: "",
      challanDate: "",
      notes: "",
      location: storeLocation ?? "",
    },
    validationSchema: object({
      manufacturer: string().required(),
      products: array(),
      challanNumber: string().required(),
      challanDate: string().required(),
      location: string().required(),
    }),
    onSubmit: async (values) => {
      try {
        console.log(values);
        const selectedProducts = values.products;

        if (!selectedProducts.length) {
          return alert("Please Select at least one Product to continue!");
        }
        const repeatChallan = await dispatch(
          fetchProductChallans({
            manufacturer: values?.manufacturer,
            challanNumber: values?.challanNumber,
          })
        );
        if (repeatChallan?.payload?.data?.totalDocs > 0) {
          alert("Challan Number already exist");
          return;
        }
        console.log(selectedProducts);
        setTotalItem(selectedProducts.length);
        setProgressLoading(true);

        for (let i = 0; i < selectedProducts.length; i++) {
          const currentProduct = selectedProducts[i];
          try {
            const payload = {
              product: currentProduct?.product,
              location: values?.location,
              manufacturer: values?.manufacturer,
              challanNumber: values?.challanNumber,
              receivedQty: Number(currentProduct?.currentReceive),
              damaged: Number(currentProduct?.currentDamage),
              challanDate: values?.challanDate,
              status: "directReceiveOrder",
              notes: values?.notes,
            };
            const response = await authAxiosInstance.post(
              "/productChallan/direct",
              payload
            );

            if (response?.data) {
              setCurrentItem((prevState) => prevState + 1);
            }

            console.log(payload);
          } catch (error) {
            console.log(error);
            setCurrentItem((prevState) => prevState + 1);
            toast.error("Error on Submit");
            setErrorData((prev) => [
              ...prev,
              {
                ...currentProduct[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <PageWithCard heading="Direct purchase order">
      {progressLoading ? (
        <ProgressBar
          totalItem={totalItem}
          currentItem={currentItem}
          errorData={errorData}
        />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <FormikSelectGroup
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            onChange={async (selectedValue) => {
              formik.setFieldValue("manufacturer", selectedValue?.value);

              await dispatch(
                fetchProducts({
                  manufacturer: { $in: [selectedValue?.value] },
                  //   populate: true,
                })
              );
              setManufacturer(selectedValue?.value);
            }}
            required
            options={generateOptions({
              array: vendor ? vendor.docs : [],
              valueField: `_id`,
              labelField: "companyName",
            })}
          />
          <div>
            <FormikInputDateGroup
              formik={formik}
              required
              label="Challan Date"
              name="challanDate"
            />
            <FormikInputGroup
              required
              formik={formik}
              label="Challan Number"
              name="challanNumber"
            />
          </div>
          <FormikTextareaGroup formik={formik} label="Notes" name="notes" />
          <FormikSelectGroup
            name={"location"}
            label="Location"
            required
            formik={formik}
            onChange={() => {}}
            options={generateOptions({
              array: location.docs ?? [],
              labelField: "name",
              valueField: "_id",
            })}
          />
          <FormikProvider value={formik}>
            <FieldArray
              name="products"
              render={(arrayHelpers) => (
                <div className="flex flex-col gap-3">
                  <div>
                    <FormikAsyncSelect
                      name={``}
                      label="Search Product"
                      formik={formik}
                      getOptions={async (value) => {
                        const string = QueryString.stringify({
                          search: value,
                          manufacturer: { $in: [formik.values.manufacturer] },
                        });
                        const product = await authAxiosInstance.get(
                          `/product?${string}`
                        );
                        const options = product?.data?.data?.docs?.map(
                          (ele) => ({
                            label: `${ele.name} - ${ele.masterSku}`,
                            value: JSON.stringify(ele),
                          })
                        );
                        return options;
                      }}
                      onChange={async (selectedOption) => {
                        const product = JSON.parse(selectedOption.value);
                        const checkExist = formik.values.products.findIndex(
                          (prod) => prod.product == product._id
                        );

                        if (checkExist !== -1) {
                          formik.setFieldValue(
                            `products.${checkExist}.currentReceive`,
                            formik.values.products[checkExist]?.currentReceive +
                              1
                          );
                          return toast.success(
                            "Receive quantity Increased by One"
                          );
                        }

                        arrayHelpers.push({
                          product: product?._id,
                          masterSku: product?.masterSku,
                          currentReceive: 0,
                          currentDamage: 0,
                        });
                      }}
                    />
                  </div>
                  <div className="w-full overflow-x-auto">
                    <table className="w-full">
                      <TableHeader
                        headers={[
                          {
                            name: "select",
                          },
                          { name: "Product" },
                          { name: "Receive qty" },
                          { name: "Damage qty" },
                        ]}
                      />
                      <tbody>
                        {formik.values.products.map((product, index) => (
                          <tr key={index} className="w-full">
                            <td className="px-2 text-center">
                              {/* <input
                                type="checkbox"
                                onChange={formik.handleChange}
                                name={`products.${index}.select`}
                                checked={product.select}
                              /> */}

                              <X
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </td>
                            <td className="px-2 ">{product.masterSku}</td>
                            <td className="px-2  ">
                              <FormikInputGroup
                                type="number"
                                name={`products.${index}.currentReceive`}
                                formik={formik}
                              />
                            </td>
                            <td className="px-2  ">
                              <FormikInputGroup
                                type="number"
                                name={`products.${index}.currentDamage`}
                                formik={formik}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            />
          </FormikProvider>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </form>
      )}
    </PageWithCard>
  );
};

export default DirectPurchaseOrder;
