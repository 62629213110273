import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import {
  fetchTransferOrder,
  getTransferOrders,
} from "../../app/reducers/TransferOrder/transferOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import TableHeader from "../../components/Table/TableHeader";
import { useFormik } from "formik";
import FormikCheckbox from "../../components/formik/FormikCheckbox";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikCheckboxGroup from "../../components/formik/FormikCheckboxGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";

const ViewStockInById = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { elementEditData: transferOrderData } = useSelector(getTransferOrders);
  useEffect(() => {
    dispatch(
      fetchTransferOrder({
        _id: _id,
        populate: true,
      })
    );
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      transferOrderCounter: transferOrderData?.transfer_order_counter,
      from: transferOrderData?.from._id,
      to: transferOrderData?.to._id,
      products:
        transferOrderData?.products.map((ele) => ({
          ...ele,
          receive: 0,
          damage: 0,
          select: false,
        })) ?? [],
    },
    onSubmit: async (values) => {
      try {
        const filterSelectedProduct = values.products.filter(
          (item) => item.select
        );
        console.log(values);
        console.log(filterSelectedProduct);
        const stockTransferRes = await authAxiosInstance.post(
          "/stockTransfer/receive",
          {
            ...values,
            products: filterSelectedProduct,
          }
        );

        console.log(stockTransferRes);
        if (stockTransferRes.data.message == "success") {
          toast.success("Stock Received!");
          await dispatch(
            fetchTransferOrder({
              _id: _id,
              populate: true,
            })
          );
          return;
        }

        toast.error("Failed to Received Stock!");
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <PageWithCard heading={_id}>
      <div className="flex flex-col gap-4">
        <div>
          <PrimaryButton
            onClick={() => {
              const selectAllProduct = formik.values.products.map(
                (product) => ({
                  ...product,
                  select:
                    product.status == "pending" ||
                    product.status == "inComplete",
                })
              );
              formik.setFieldValue("products", selectAllProduct);
            }}
          >
            Select all
          </PrimaryButton>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <table>
            <TableHeader
              headers={[
                {
                  name: "select",
                },
                { name: "name" },
                { name: "master_sku" },
                { name: "quantity" },
                { name: "received" },
                { name: "damaged" },
                { name: "status" },
                { name: "Receive" },
                { name: "damage" },
                { name: "Action" },
              ]}
            />
            <tbody>
              {formik.values.products.map((product, index) => (
                <tr>
                  <td className="px-2 text-center">
                    {/* <FormikCheckbox
                  value={true}
                  formik={formik}
                  name={`products.${index}.select`}
                /> */}
                    <input
                      type="checkbox"
                      onChange={formik.handleChange}
                      name={`products.${index}.select`}
                      checked={
                        product.select &&
                        (product.status == "pending" ||
                          product.status == "inComplete")
                      }
                    />
                  </td>
                  <td className="px-2">{product.product.name}</td>
                  <td className="px-2">{product.product.masterSku}</td>
                  <td className="px-2">{product.quantity}</td>
                  <td className="px-2">
                    {product.received?.reduce(
                      (acc, ele) => acc + ele.quantity,
                      0
                    )}
                  </td>
                  <td className="px-2">
                    {product.received?.reduce(
                      (acc, ele) => acc + ele.damage,
                      0
                    )}
                  </td>
                  <td className="px-2">{product.status}</td>
                  <td className="px-2">
                    <FormikInputGroup
                      name={`products.${index}.receive`}
                      formik={formik}
                      type="number"
                      readOnly={
                        product.status == "received" ||
                        product.status == "declined"
                      }
                      onChange={(input) => {
                        const damageProduct =
                          formik.values.products[index].damage;
                        let receiveProduct = parseInt(input.target.value) || 0;
                        const alreadyReceived = product.received?.reduce(
                          (acc, ele) =>
                            acc + (ele.quantity || 0) + (ele.damage || 0),
                          0
                        );
                        const total =
                          damageProduct + receiveProduct + alreadyReceived;

                        if (total > product.quantity) {
                          receiveProduct =
                            product.quantity - alreadyReceived - damageProduct;
                        }
                        formik.setFieldValue(
                          `products.${index}.receive`,
                          Math.max(receiveProduct, 0)
                        );
                      }}
                    />
                  </td>
                  <td className="px-2">
                    <FormikInputGroup
                      name={`products.${index}.damage`}
                      formik={formik}
                      type="number"
                      readOnly={
                        product.status == "received" ||
                        product.status == "declined"
                      }
                      onChange={(input) => {
                        let damageValue = parseInt(input.target.value) || 0;
                        let receiveValue =
                          formik.values.products[index].receive;
                        const alreadyReceived = product.received?.reduce(
                          (acc, ele) =>
                            acc + (ele.quantity || 0) + (ele.damage || 0),
                          0
                        );
                        const total =
                          receiveValue + damageValue + alreadyReceived;

                        if (total > product.quantity) {
                          damageValue =
                            product.quantity - alreadyReceived - receiveValue;
                        }

                        formik.setFieldValue(
                          `products.${index}.damage`,
                          Math.max(damageValue, 0)
                        );
                      }}
                    />
                  </td>
                  <td>
                    {product.status == "pending" && (
                      <PrimaryButton
                        disabled={product.status == "inComplete"}
                        onClick={async () => {
                          let confirmDecline = confirm(
                            "Once you declined you have to re-create stock transfer"
                          );
                          if (confirmDecline) {
                            const payload = {
                              product: product,
                              from: formik.values.from,
                              to: formik.values.to,
                              transferOrderCounter:
                                formik.values.transferOrderCounter,
                            };
                            console.log(payload);
                            const declineRes = await authAxiosInstance.post(
                              "/stockTransfer/decline",
                              payload
                            );
                            await dispatch(
                              fetchTransferOrder({
                                _id: _id,
                                populate: true,
                              })
                            );
                            console.log(declineRes);
                          }
                        }}
                      >
                        Decline
                      </PrimaryButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      </div>
    </PageWithCard>
  );
};

export default ViewStockInById;
