import React, { useEffect, useMemo, useState } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import FormikInputGroup from '../../components/formik/FormikInputGroup'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { createReference, deleteReference, fetchReferences, getReferences } from '../../app/reducers/Reference/referenceSlice'
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch'
import { Delete, Edit, Trash } from 'react-feather'
import EditRefStatusModal from './EditRefStatusModal'



const AddReference = () => {
    const { reference } = useSelector(getReferences);
    console.log('reference', reference)
    const [requestData,setRequestData] = useState({})
    const [openEditModal,setOpenEditModal] = useState(false)
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues:{
            name : '',
        },
        validationSchema : object({
            name : string().required()
        }),
        onSubmit :(values,{resetForm})=>{
            console.log('values', values)
            dispatch(createReference(values)).then((res)=>{
                if(res.payload.success){

                    dispatch(fetchReferences())
                }
            })
            resetForm();
        }
    });

    useEffect(()=>{
        dispatch(fetchReferences({populate : true}))
    },[]);

    const cols = [
        {
            Header : 'Sr. No.',
            Cell:({row})=>{
                return(
                    <span>{row?.index + 1}</span>
                )
            }
        },
        {
            Header : 'Reference Name',
            accessor : 'name'
        },
        {
            Header : ' ',
            Cell : ({row})=>{
                return (
                    <div className='flex flex-row gap-4'>
                        <Edit 
                            color='blue' 
                            className='cursor-pointer'
                            onClick={async(e)=>{
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setOpenEditModal(true)
                            }}
                        />
                        <Trash 
                            color='red'
                            className='cursor-pointer'
                            onClick={async(e)=>{
                                e.stopPropagation();
                                await dispatch(deleteReference({
                                    id : row?.original?._id,
                                }))
                                await dispatch(fetchReferences())
                            }}
                        />
                    </div>
                )
            }
        },
    ]

    const refMemo = useMemo(()=>(reference?.docs ? reference?.docs : []),[reference])
  return (
    <PageWithCard heading='Add Reference'>
       <EditRefStatusModal 
        requestData={requestData}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
       />
      <form
        onSubmit={formik.handleSubmit}
        className='flex flex-col gap-4 mb-4'
      >
        <FormikInputGroup 
            formik={formik}
            label='Name'
            name='name'
            required
        />
        <div>
            <PrimaryButton type='submit'>
                Submit
            </PrimaryButton>
        </div>
      </form>
      <TableWithHeadingAndSearch 
        columns={cols}
        data={refMemo}
        heading={'All References'}
      />
    </PageWithCard>
  )
}

export default AddReference
