import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCollections,
  getGeneralBulkUpload,
} from '../../app/reducers/GeneralBulkUpload/generalBulkUploadSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { axiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { FieldArray, FormikProvider } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import MappingFields from './MappingFields';
import { getAnalysisFieldName } from '../../utils/getAnalysisFieldName';
import { exportCsv, generateOptions } from '../../utils/Utils';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { ClipLoader } from 'react-spinners';

const STEP = 2;
const action = 'Analyze';
const Step = ({ formik, currentStep, setCurrentStep }) => {
  const dispatch = useDispatch();
  const [schemaFields, setSchemaFields] = useState([]);
  const [laoding, setLoading] = useState(false);
  // const handleAnalyze = async () => {
  //   try {
  //     setLoading(true);
  //     const string = QueryString.stringify({
  //       collection: formik.values.collection,
  //     });
  //     const resp = await axiosInstance.get(
  //       `generalBulkUpload/analyze?${string}`
  //     );
  //     // console.log("analyzeResp", resp.data.data);
  //     formik.setFieldValue("analyzedObj", resp.data.data);
  //     const firstObj = formik.values.parsedCsvArr[0];
  //     const headingArr = Object.keys(firstObj).map((key) => {
  //       return {
  //         heading: key,
  //       };
  //     });
  //     formik.setFieldValue("headings", headingArr);

  //     // setSchemaFields(resp?.data?.data);
  //     setLoading(false);
  //     // formik.setFieldValue("collectionFields", resp.data?.data);
  //   } catch (error) {
  //     console.log("error in handleAnalyze==>", error);
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      {currentStep + 1 == STEP && (
        <>
          <div className="flex flex-col gap-3 p-4  ">
            <h1 className="text-xl ">Total {formik.values.parsedCsvArr.length} entries found</h1>

            <div className="flex gap-4">
              <SecondaryButton type={'button'} onClick={() => setCurrentStep(prev => prev - 1)}>
                Back
              </SecondaryButton>
            </div>
            <FormikInputGroup formik={formik} name={'batchNumber'} label="Batch No." readOnly />
            <FormikProvider value={formik}>
              <FieldArray
                name="relationalFields"
                render={() => (
                  <div className="flex flex-col gap-4">
                    {formik.values.relationalFields.map((ele, i) => (
                      <div className="grid grid-cols-3 gap-4" key={i}>
                        <FormikTextareaGroup
                          name={`relationalFields.${i}.name`}
                          formik={formik}
                          label="Field Name"
                          readOnly
                          row={2}
                          cols={2}
                        />
                        <FormikInputGroup
                          name={`relationalFields.${i}.ref`}
                          readOnly
                          formik={formik}
                          label="Field Model"
                        />
                        <FormikSelectGroup
                          name={`analyzedObj.${getAnalysisFieldName(
                            formik.values.relationalFields[i].name
                          )}.mapping`}
                          formik={formik}
                          label="Mapping Field"
                          options={formik.values.relationalFields[i].fieldOptions.map(ele => ({
                            label: ele,
                            value: ele,
                          }))}
                        />
                      </div>
                    ))}
                  </div>
                )}
              />
            </FormikProvider>
          </div>
          {Object.keys(formik.values.analyzedObj).length > 0 && (
            <div>
              <FormikProvider value={formik}>
                <FieldArray
                  name="headings"
                  render={arrayHelpers => {
                    return (
                      <div>
                        {formik.values.headings.map((ele, index) => (
                          <div key={index} className="  p-4 relative">
                            <MappingFields formik={formik} index={index} />
                          </div>
                        ))}
                      </div>
                    );
                  }}
                />
              </FormikProvider>
              <div className="mx-4  ">
                {formik.isSubmitting ? (
                  <ClipLoader />
                ) : (
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default {
  Component: Step,
  action: action,
};
