import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchOrganizations,
  getOrganization,
} from "../../app/reducers/Organization/organizationSlice";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import moment from "moment";
import { object, ref, string } from "yup";
import { axiosInstance } from "../../utils/axiosConfig";

const CreateUser = () => {
  // const { stores, loading } = useSelector(getStores);

  const { organizationId } = useParams();
  const { organization } = useSelector(getOrganization);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchOrganizations({ _id: organizationId }));
  }, [organizationId]);
  // console.log(organization.docs[0].company_name);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      organization: organizationId,
      firstName: "",
      lastName: "",
      username: "+91",
      email: "",
      role: "org_admin",
      password: "",
      confirm_password: "",
      joiningDate: moment(new Date()).valueOf(),
      isActive: true,
    },
    validationSchema: object({
      firstName: string().required(),
      lastName: string().required(),
      username: string().required().min(12).max(12),
      password: string().required().min(8),
      confirm_password: string()
        .required()
        .oneOf([ref("password")], "Password didn't match!"),
    }),
    onSubmit: async (values) => {
      try {
        const createOrganizationUserRes = await axiosInstance.post(
          "/organization/user",
          values
        );
        console.log(createOrganizationUserRes);
        navigate(`/AddDefaults/${organizationId}`);
      } catch (error) {
        console.log(error);
      }
    },
  });
  console.log(organization);
  return (
    <div>
      <div className="flex flex-col items-center justify-center my-6 ">
        <div className=" flex flex-col gap-4  w-[90%]  md:w-[40%]">
          <div>
            <h1 className="text-2xl font-semibold">Create Admin User</h1>
            <div className="border border-black w-full" />
          </div>
          <h1 className="text-xl font-semibold">
            Organization: {organization.company_name}
          </h1>
          <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
            <FormikInputGroup
              name={"role"}
              label="Role"
              formik={formik}
              readOnly
            />
            <FormikInputGroup
              name="firstName"
              formik={formik}
              label="First Name"
              required
            />
            <FormikInputGroup
              name="lastName"
              formik={formik}
              label="Last Name"
              required
            />
            <FormikPhoneInputGroup
              name="username"
              formik={formik}
              label="Phone"
              required
            />
            <FormikInputGroup
              name="email"
              formik={formik}
              label="Email"
              required
            />
            <FormikInputGroup
              type="password"
              formik={formik}
              label="Password"
              name="password"
            />
            <FormikInputGroup
              type="password"
              formik={formik}
              label="Confirm Password"
              name="confirm_password"
            />

            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
