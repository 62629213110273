import React from 'react';
import _ from 'lodash';

const FormikLabel = ({
    formik,
    label = '',
    required = false,
    // name,
    fullWidth,
    ...props
}) => {
    return (
        <div className={fullWidth ? 'w-full' : ''}>
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            {/* <input
                type="text"
                value={_.at(formik.values, name)[0]}
                onChange={formik.handleChange}
                name={name}
                onBlur={formik.handleBlur}
                className="w-full form-input"
                {...props}
            /> */}
            {/* {formik.errors[name] && formik.touched[name] ? (
                <p className="text-xs text-red-500">{formik.errors[name]}</p>
            ) : null} */}
        </div>
    );
};

export default FormikLabel;
