import { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { Minus, Plus } from 'react-feather';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

const SaleReturn = () => {
  const { location } = useSelector(getLocation);
  const dispatch = useDispatch();
  const saleLocationInfoID = JSON.parse(localStorage.getItem('saleLocationInfoId'));

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    initialValues: {
      dataType: '',
      location: saleLocationInfoID ?? '',
      customerId: '',
      customerName: '',
      customerPhone: '',
      products: [
        {
          product: '',
          productName: '',
          sku: '',
          qty: 0,
        },
      ],
    },
    validationSchema: Yup.object({
      dataType: Yup.string().required(),
      customerId: Yup.string().required(),
      customerName: Yup.string().required(),
      customerPhone: Yup.string().required(),
      product: Yup.array().of(
        Yup.object({
          product: Yup.string(),
          productName: Yup.string(),
          sku: Yup.string(),
          qty: Yup.number(),
        })
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      let filterProduct = values?.products?.filter(ele => ele.sku);
      if (filterProduct.length > 0) {
        let payload = { ...values, products: filterProduct };
        const generatingNewSaleReturn = await authAxiosInstance.post('/saleReturn', payload);
        if (generatingNewSaleReturn) {
          await toast.success('Sale Return Placed Successfully');
          resetForm();
        } else {
          toast.error('Error placing Sale Return');
        }
      } else {
        alert('Please Select Atleast one Barcode');
      }
    },
  });

  const onKeyPressBarcode = async (event, i, arrayHelpers) => {
    if (event.keyCode === 13 && event.target.value) {
      event.preventDefault();
      let checkExist = formik.values.products?.find(d => d.sku == event.target.value);

      const string = QueryString.stringify({
        masterSku: event.target.value,
      });

      const resp = await authAxiosInstance.get(`product?${string}`);
      const product = resp.data.data.docs[0];

      if (!product) {
        alert('Product Not found');
      }

      if (formik?.values?.dataType === 'new') {
        const stringInv = QueryString.stringify({
          'products.product': product?._id,
          customerId: formik.values.customerId,
        });

        const invRsp = await authAxiosInstance.get(`sale?${stringInv}`);

        const stocksData = invRsp?.data?.data?.docs;

        const filterProductStocksDataInSale = stocksData?.reduce((acc, crr) => {
          // return acc.concat(crr.products);
          return [...acc, ...crr.products];
        }, []);

        const totalQtyExistInSaleWithSameSku = filterProductStocksDataInSale?.reduce((acc, crr) => {
          if (crr.sku === product?.masterSku) {
            return acc + crr.qty;
          }
          return acc;
        }, 0);

        const queryData = QueryString.stringify({
          dataType: 'new',
          'products.sku': product?.masterSku,
        });

        const existingDataWithSkuInSaleReturn = await authAxiosInstance(`/saleReturn?${queryData}`);

        const existingSaleReturn = existingDataWithSkuInSaleReturn?.data?.data?.docs;

        const filterProductStocksDataInSaleReturn = existingSaleReturn?.reduce((acc, crr) => {
          return [...acc, ...crr.products];
        }, []);

        const totalQtyExistInSaleReturnWithSameSku = filterProductStocksDataInSaleReturn?.reduce(
          (acc, crr) => {
            if (crr.sku === product?.masterSku) {
              return acc + crr.qty;
            }
            return acc;
          },
          0
        );

        const currentStocks = totalQtyExistInSaleWithSameSku - totalQtyExistInSaleReturnWithSameSku;

        if (currentStocks <= 0) {
          return alert('Product out of stock');
        }

        if (!currentStocks) {
          return alert('Stock not found ');
        }

        if (checkExist) {
          let incQty = checkExist.qty + 1;
          if (currentStocks < incQty) {
            return alert('Product out of stock');
          }
          let productIndex = formik.values.products?.findIndex(d => d.sku == event.target.value);
          formik.setFieldValue(`products.${productIndex}.qty`, incQty);
          formik.setFieldValue(`products.${i}.product`, '');
          formik.setFieldValue(`products.${i}.id`, '');
          return toast.success('Qty updated on product');
        }

        formik.setFieldValue(`products.${i}.sku`, product.masterSku);
        formik.setFieldValue(`products.${i}.productName`, product.name);
        formik.setFieldValue(`products.${i}.product`, product._id);
        formik.setFieldValue(`products.${i}.qty`, 1);
        arrayHelpers.push('');
      } else {
        if (checkExist) {
          let incQty = checkExist.qty + 1;
          let productIndex = formik.values.products?.findIndex(d => d.sku == event.target.value);
          formik.setFieldValue(`products.${productIndex}.qty`, incQty);
          formik.setFieldValue(`products.${i}.product`, '');
          formik.setFieldValue(`products.${i}.id`, '');
          return toast.success('Qty updated on product');
        }

        formik.setFieldValue(`products.${i}.sku`, product.masterSku);
        formik.setFieldValue(`products.${i}.productName`, product.name);
        formik.setFieldValue(`products.${i}.product`, product._id);
        formik.setFieldValue(`products.${i}.qty`, 1);
        arrayHelpers.push('');
      }
    }
  };

  const increment = async (e, i) => {
    e.stopPropagation();

    let data = formik?.values?.products[i];

    if (formik?.values?.dataType === 'new') {
      const stringInv = QueryString.stringify({
        'products.product': data?.product,
        customerId: formik.values.customerId,
      });

      const invRsp = await authAxiosInstance.get(`sale?${stringInv}`);

      const stocksData = invRsp?.data?.data?.docs;
      //
      const filterProductStocksDataInSale = stocksData?.reduce((acc, crr) => {
        // return acc.concat(crr.products);
        return [...acc, ...crr.products];
      }, []);

      const totalQtyExistInSaleWithSameSku = filterProductStocksDataInSale?.reduce((acc, crr) => {
        if (crr.sku === data?.sku) {
          return acc + crr.qty;
        }
        return acc;
      }, 0);

      const queryData = QueryString.stringify({
        'products.sku': data?.sku,
        dataType: 'new',
      });

      const existingDataWithSkuInSaleReturn = await authAxiosInstance(`/saleReturn?${queryData}`);

      const existingSaleReturn = existingDataWithSkuInSaleReturn?.data?.data?.docs;

      const filterProductStocksDataInSaleReturn = existingSaleReturn?.reduce((acc, crr) => {
        return [...acc, ...crr.products];
      }, []);

      const totalQtyExistInSaleReturnWithSameSku = filterProductStocksDataInSaleReturn?.reduce(
        (acc, crr) => {
          if (crr.sku === data?.sku) {
            return acc + crr.qty;
          }
          return acc;
        },
        0
      );

      const currentStocks = totalQtyExistInSaleWithSameSku - totalQtyExistInSaleReturnWithSameSku;
      //

      if (currentStocks <= 0) {
        return alert('Product out of stock');
      }
      if (!currentStocks) {
        return alert('Stock not found');
      }
      let incQty = data.qty + 1;
      if (currentStocks < incQty) {
        return alert('Product out of stock');
      }
      formik.setFieldValue(`products.${i}.qty`, incQty);
    } else {
      let incQty = data.qty + 1;
      formik.setFieldValue(`products.${i}.qty`, incQty);
    }
  };

  const decrement = async (e, i) => {
    e.stopPropagation();
    let data = formik.values.products[i];
    if (data.qty == 1) {
      window.alert('Not allowed');
    } else {
      let incQty = data.qty - 1;
      formik.setFieldValue(`products.${i}.qty`, incQty);
    }
  };

  return (
    <PageWithCard heading="Sale Return">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikSelectGroup
          label="Select Type"
          name="dataType"
          formik={formik}
          options={[
            { label: 'New', value: 'new' },
            { label: 'Old', value: 'old' },
          ]}
        />
        {formik?.values?.dataType && (
          <FormikAsyncSelect
            name="customerId"
            formik={formik}
            label="Search Customer"
            getOptions={async value => {
              const string = QueryString.stringify({ search: value });
              const customers = await authAxiosInstance.get(`users/customer?${string}`);
              const options = customers?.data?.data?.docs?.map(ele => ({
                label: `${ele?.firstName} ${ele?.lastName} / ${ele?.username}`,
                value: ele?._id,
              }));
              return options;
            }}
            onChange={async selectedOption => {
              const string = QueryString.stringify({
                _id: selectedOption?.value,
              });
              const resp = await authAxiosInstance.get(`users/customer?${string}`);
              const user = resp?.data?.data?.docs[0];

              formik.setFieldValue('customerId', user._id);
              formik.setFieldValue('customerName', `${user.firstName} ${user.lastName}`);
              formik.setFieldValue('customerPhone', user.username);
            }}
          />
        )}

        {formik?.values?.customerId && (
          <div className="flex gap-4 w-full">
            <FormikInputGroup
              name="customerName"
              formik={formik}
              label="Customer Name"
              readOnly
              fullWidth
            />
            <FormikInputGroup
              name="customerPhone"
              formik={formik}
              label="Customer Phone"
              readOnly
              fullWidth
            />
            <FormikSelectGroup
              options={generateOptions({
                array: location?.docs ?? [],
                labelField: 'name',
                valueField: '_id',
              })}
              label="Location"
              name="location"
              formik={formik}
              isDisabled
            />
          </div>
        )}

        {formik?.values?.location && formik?.values?.customerId && (
          <>
            <FormikProvider value={formik}>
              <FieldArray
                name="products"
                render={arrayHelpers => {
                  return (
                    <div className="flex flex-col gap-2">
                      {/* <p>Address</p> */}
                      <div>
                        {formik.values.products.map((ele, index) => (
                          <div
                            className="relative p-4 mb-2"
                            style={{
                              border: '1px solid #d6c7c7',
                              borderRadius: '5px',
                            }}
                            key={index}
                          >
                            {!formik.values.products[index].sku && (
                              <FormikInputGroup
                                autoFocus={true}
                                formik={formik}
                                label={`Barcode${index + 1}`}
                                name={`products.${index}.id`}
                                onKeyDown={e => {
                                  onKeyPressBarcode(e, index, arrayHelpers);
                                }}
                              />
                            )}

                            {formik.values.products[index].sku && (
                              <div className="flex gap-3">
                                <FormikInputGroup
                                  formik={formik}
                                  label={`sku`}
                                  name={`products.${index}.sku`}
                                  readOnly
                                />

                                <FormikInputGroup
                                  formik={formik}
                                  label={`Product Name`}
                                  name={`products.${index}.productName`}
                                  readOnly
                                />

                                <Minus
                                  onClick={e => decrement(e, index)}
                                  style={{ marginTop: '25px' }}
                                />
                                <FormikInputGroup
                                  formik={formik}
                                  label={`Qty`}
                                  name={`products.${index}.qty`}
                                  readOnly
                                />
                                <Plus
                                  onClick={e => increment(e, index)}
                                  style={{ marginTop: '25px' }}
                                />
                              </div>
                            )}

                            <div>
                              <DangerButton
                                className="mt-3"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                                type="button"
                              >
                                Remove
                              </DangerButton>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex gap-3">
                        <SecondaryButton
                          onClick={() => {
                            arrayHelpers.push('');
                          }}
                          type="button"
                        >
                          Add More
                        </SecondaryButton>

                        {/* {formik.values.products &&
                          formik.values.products.length > 0 && (
                            <div className="mt-2">
                              <h1>
                                Total Quantity:-{" "}
                                <span className="font-bold">
                                  {formik.values.products?.reduce(
                                    (acc, ele) => acc + (ele.qty || 0),
                                    0
                                  )}
                                </span>
                              </h1>
                            </div>
                          )} */}
                      </div>
                    </div>
                  );
                }}
              />
              {formik.errors['products'] &&
              !Array.isArray(formik.errors['products']) &&
              formik.errors['products'] ? (
                <p className="text-xs text-red-500">{formik.errors['products']}</p>
              ) : null}
            </FormikProvider>
          </>
        )}
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default SaleReturn;
