import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductPurchaseOrders,
  getProductPurchaseOrders,
  resetProductPurchaseOrderData,
} from "../../app/reducers/ProductPurchaseOrder/productPurchaseOrderSlice";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { fetchVendors, getVendors } from "../../app/reducers/Users/VendorSlice";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { generateOptions } from "../../utils/Utils";
import PageWithCard from "../../components/infrastructure/PageWithCard";

const inputStyle = {
  width: "137px",
  borderColor: "#e4e6fc",
  borderRadius: "7px",
};

const ReceiveProductPurchaseOrder = () => {
  const [manufacturer, setManufacturer] = useState("");
  const { productPurchaseOrder, loading } = useSelector(
    getProductPurchaseOrders
  );
  const { vendor } = useSelector(getVendors);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchVendors());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetProductPurchaseOrderData());
    };
  }, []);

  const productPurchaseOrderData = useMemo(
    () =>
      productPurchaseOrder?.docs
        ? productPurchaseOrder.docs.filter(
            (d) =>
              d.status != "completed" &&
              d.status != "settled" &&
              d.status != "direct"
          )
        : [],
    [productPurchaseOrder]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: manufacturer ?? "",
      ppOData: productPurchaseOrderData ?? [],
    },
  });

  useEffect(() => {
    formik?.resetForm();
    return () => {
      dispatch(resetProductPurchaseOrderData());
    };
  }, []);
  return (
    <PageWithCard heading="Receive Purchase Order">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikSelectGroup
          formik={formik}
          label="Manufacturer"
          name="manufacturer"
          onChange={async (selectedValue) => {
            await dispatch(
              fetchProductPurchaseOrders({
                "manufacturer._id": selectedValue?.value,
                populate: true,
              })
            );

            setManufacturer(selectedValue?.value);
            formik.setFieldValue("manufacturer", selectedValue?.value);
          }}
          required
          options={generateOptions({
            array: vendor ? vendor.docs : [],
            valueField: `_id`,
            labelField: "companyName",
          })}
        />

        <div className="relative overflow-x-auto">
          <table
            className="table-auto w-full border text-center"
            style={{ padding: "10px" }}
          >
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200 p-2">
              <th scope="col" style={{ padding: "15px" }}>
                SRNO
              </th>

              <th scope="col">Product</th>
              <th scope="col">Location</th>
              <th scope="col">Po Number</th>
              <th scope="col">Ordered Total</th>
              <th scope="col">Ordered Remaining</th>
              <th scope="col">Action</th>
            </thead>
            {formik.values.ppOData &&
              formik.values.ppOData.length > 0 &&
              formik.values.ppOData.map((po, index) => (
                <>
                  <tbody className="text-sm divide-y divide-slate-200">
                    <td>{index + 1}.</td>

                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={po?.product?.name}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={po?.location?.name}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={po?.poNumber}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={po?.quantity}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        formik={formik}
                        value={formik.values.ppOData[index]?.remaining}
                        style={inputStyle}
                        readOnly
                      />
                    </td>

                    <td className="p-3">
                      <div style={{ display: "flex", gap: "10px" }}>
                        <PrimaryButton
                          tabIndex={"0"}
                          onClick={() => {
                            navigate(
                              `/product/createProductChallan/${formik.values.ppOData[index]._id}`
                            );
                          }}
                        >
                          Add Challan
                        </PrimaryButton>
                      </div>
                    </td>
                  </tbody>
                </>
              ))}
          </table>
        </div>
      </form>
    </PageWithCard>
  );
};

export default ReceiveProductPurchaseOrder;
