import QueryString from "qs";
import React from "react";
import { useLocation } from "react-router-dom";

const useQueryString = () => {
  const queryLocation = useLocation();
  const queryParams = queryLocation.search;
  return QueryString.parse(queryParams.replace("?", ""));
};

export default useQueryString;
