import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAttributeType,
  deleteAttributeType,
  fetchAttributeTypes,
  getAttributeTypes,
  setEditId,
} from '../../app/reducers/AttributeType/attributeTypeSlice';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { Edit2, Trash } from 'react-feather';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { ClipLoader } from 'react-spinners';
import EditAttributeModal from './EditAttributeModal';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';

const AddAttributeType = () => {
  const [page, setPage] = useState(1);
  const { attributeType, loading } = useSelector(getAttributeTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAttributeTypes({ page }));
  }, [page]);

  const formik = useFormik({
    initialValues: {
      type: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(),
    }),
    onSubmit: async values => {
      await dispatch(createAttributeType(values));
      await dispatch(fetchAttributeTypes());
      formik.resetForm();
    },
  });

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={e => {
                dispatch(setEditId(row?.original?._id));
              }}
            />

            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteAttributeType({ id: row?.original?._id }));
                await dispatch(fetchAttributeTypes());
              }}
            />
          </div>
        );
      },
    },
  ];

  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(() => attributeType?.docs ?? [], [attributeType]);

  return (
    <PageWithCard heading="Attribute Type">
      <EditAttributeModal />
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikInputGroup formik={formik} name="type" label="Type" required />
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      <div className="mt-2">
        {loading ? (
          <ClipLoader />
        ) : (
          <>
            <TableWithHeadingAndSearch
              heading="View Attributes Type"
              columns={columnsMemo}
              data={dataMemo}
            />
            <PaginationClassic setPage={setPage} paginationDetails={attributeType} />
          </>
        )}
      </div>
    </PageWithCard>
  );
};
export default AddAttributeType;
