import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/infrastructure/Layout";
import PersistLogin from "./components/infrastructure/PersistLogin";

import "./css/style.scss";
import Login from "./pages/Auth/Login";
import PageNotFound from "./pages/PageNotFound";
import Logout from "./pages/Auth/Logout";
import RouteWithHeaderAndSidebar from "./components/infrastructure/RouteWithHeaderAndSidebar";
import "react-datepicker/dist/react-datepicker.css";
import MediaLibraryAssets from "./pages/MediaLibrary/MediaLibraryAssets";
import { routes } from "./utils/routes";
import RoleAccess from "./pages/RoleAccess";
import CreateUser from "./pages/Organization/CreateUser";
import Organization from "./pages/Organization/Organization";
import AddDefaults from "./pages/Organization/AddDefaults";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <MediaLibraryAssets />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/Organization" element={<Organization />} />
          <Route
            path="/Organization/:organizationId"
            element={<CreateUser />}
          />
          <Route
            path="/AddDefaults/:organizationId"
            element={<AddDefaults />}
          />
          <Route element={<PersistLogin />}>
            <Route exact path="/logout" element={<Logout />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<RouteWithHeaderAndSidebar />}>
              {routes.map((ele) => (
                <Route element={<RoleAccess roles={ele.allowedRoles} />}>
                  <Route path={ele.route} element={ele.element} />
                </Route>
              ))}
            </Route>
          </Route>
          <Route exact path="/login" element={<Login />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
