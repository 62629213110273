import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import { generateOptions } from '../../utils/Utils';
import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { ClipLoader } from 'react-spinners';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import QueryString from 'qs';

const DirectProductPurchaseOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { vendor } = useSelector(getVendors);
  const { location } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendors());
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    initialValues: {
      product: '',
      location: '',
      manufacturer: '',
      challanNumber: '',
      receivedQty: 0,
      damaged: 0,
      challanDate: '',
      status: '',
      notes: '',
    },
    validationSchema: Yup.object({
      product: Yup.string(),
      location: Yup.string(),
      manufacturer: Yup.string(),
      challanNumber: Yup.string(),
      receivedQty: Yup.number().min(1),
      damaged: Yup.number().min(0),
      challanDate: Yup.string(),
      status: Yup.string(),
      notes: Yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        // const existingChallan = await dispatch(
        //   fetchProductChallans({
        //     challanNumber: values?.challanNumber,
        //     manufacturer: values?.manufacturer,
        //   })
        // );
        // if (existingChallan?.payload?.data?.totalDocs > 0) {
        //   alert("Manufacture With Same Challan Number Exist");
        //   setIsLoading(false);
        //   return;
        // }
        const payload = {
          product: values?.product,
          location: values?.location,
          manufacturer: values?.manufacturer,
          challanNumber: values?.challanNumber,
          receivedQty: Number(values?.receivedQty),
          damaged: Number(values?.damaged),
          challanDate: values?.challanDate,
          status: 'directReceiveOrder',
          notes: values?.notes,
        };
        const response = await authAxiosInstance.post('/productChallan/direct', payload);
        if (response) {
          toast.success('Direct Product Purchase Created!!!..');
          resetForm();
          setIsLoading(false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        toast.error('Error creating Direct Challan');
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <PageWithCard heading="Direct PurchaseOrder">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikAsyncSelect
          getOptions={async value => {
            const string = QueryString.stringify({ search: value });
            const productsResponse = await authAxiosInstance.get(`product?${string}`);
            const options = productsResponse?.data?.data?.docs?.map((el, i) => {
              return {
                label: `${el?.name} / ${el?.masterSku}`,
                value: el?._id,
              };
            });
            return options;
          }}
          name={`product`}
          formik={formik}
          label="Search Product"
        />
        <FormikSelectGroup
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: 'name',
            valueField: '_id',
          })}
          label="Location"
          name="location"
          formik={formik}
        />
        <FormikSelectGroup
          options={generateOptions({
            array: vendor?.docs ?? [],
            labelField: 'companyName',
            valueField: '_id',
          })}
          label="Manufacturer"
          name="manufacturer"
          formik={formik}
        />
        <FormikInputGroup name="challanNumber" label="Challan Number" formik={formik} />
        <FormikInputDateGroup name="challanDate" label="Date" formik={formik} />
        <FormikInputGroup name="receivedQty" label="Quantity" formik={formik} />
        <FormikInputGroup name="damaged" label="Damaged" formik={formik} />
        <FormikTextareaGroup name="notes" label="Notes" formik={formik} />
        <div>
          {isLoading || formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default DirectProductPurchaseOrder;
