import QueryString from "qs";
import { authAxiosInstance } from "./axiosConfig";
import { checkRequired } from "./checkRequired";
import moment from "moment";

export const getUploadObject = async (parsedCsv, config) => {
  let updatedCsv = [];
  let errors = [];
  for (let i = 0; i < parsedCsv.length; i++) {
    const element = parsedCsv[i];
    try {
      const computedData = await compute(element, config);
      updatedCsv.push(computedData);
    } catch (error) {
      console.log(error, "error in computed data");
      errors.push({ index: i + 1, error: JSON.stringify(error) });
    }
  }
  for (let j = 0; j < updatedCsv.length; j++) {
    const element = updatedCsv[j];
    const errorsRequired = checkRequired(element, config);
    errorsRequired.forEach((error) => {
      errors.push({ index: j + 1, error: error });
    });
  }
  console.log(updatedCsv, "in get upload object");
  return { updatedCsv, errors };
};

const compute = async (obj, config) => {
  const keys = Object.keys(obj);
  const final = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const computedKey = await computeValue(key, obj[key], config);
    final[key] = computedKey;
  }

  return final;
};

const computeValue = async (key, rawData, config) => {
  let finalData = null;
  console.log(key, rawData, config);
  const configType = _.at(config, key)[0].type;

  switch (configType) {
    case "array":
      const dataArray = [].concat(rawData);
      const finalArray = [];
      for (let i = 0; i < dataArray.length; i++) {
        const ele = dataArray[i];
        if (!ele) {
          continue;
        }
        if (checkObject(ele)) {
          console.log("recursion");
          const intermediateObj = {};
          const keysArray = Object.keys(ele);
          for (let j = 0; j < keysArray.length; j++) {
            const item = keysArray[j];
            intermediateObj[item] = await computeValue(
              `${key}.fields.${item}`,
              ele[item],
              config
            );
          }
          finalArray.push(intermediateObj);
        } else {
          finalArray.push(ele);
        }
      }
      finalData = finalArray;
      break;

    case "relationalArray":
      const dataArrayRelational = [].concat(rawData);
      const finalArrayRelational = [];
      for (let i = 0; i < dataArrayRelational.length; i++) {
        const element = dataArrayRelational[i];
        if (!element) {
          continue;
        }
        const mapping = _.at(config, key)[0].mapping;
        const findUrl = _.at(config, key)[0].findUrl;
        const callString = QueryString.stringify({
          [mapping]: element,
        });
        const resp = await authAxiosInstance.get(`${findUrl}?${callString}`);
        if (resp?.data?.data?.docs?.[0]?._id) {
          finalArrayRelational.push(resp?.data?.data?.docs?.[0]?._id);
        } else {
          throw `Data not found for ${key.replace(".fields.", "")}`;
        }
      }
      finalData = finalArrayRelational;
      break;

    case "object":
      // const dataObj = [].concat(rawData);
      const dataObj = {};
      // const finalArray = [];
      const objKeys = Object.keys(rawData);
      for (let i = 0; i < objKeys.length; i++) {
        const objKey = objKeys[i];
        if (!rawData[objKey]) {
          continue;
        }
        if (checkObject(rawData[objKey])) {
          console.log("recursion");
          const intermediateObj = {};
          const keysArray = Object.keys(rawData[objKey]);
          for (let j = 0; j < keysArray.length; j++) {
            const item = keysArray[j];
            intermediateObj[item] = await computeValue(
              `${key}.fields.${item}`,
              rawData[objKey][item],
              config
            );
          }
          dataObj[objKey] = intermediateObj;
        } else {
          dataObj[objKey] = await computeValue(
            `${key}.fields.${objKey}`,
            rawData[objKey],
            config
          );
        }
      }
      finalData = dataObj;
      break;

    case "relational":
      console.log("find data");
      const mapping = _.at(config, key)[0].mapping;
      const findUrl = _.at(config, key)[0].findUrl;
      if (!rawData) {
        finalData = null;
        break;
      }
      const callString = QueryString.stringify({
        [mapping]: rawData,
      });

      const resp = await authAxiosInstance.get(`${findUrl}?${callString}`);

      if (resp?.data?.data?.docs?.[0]?._id) {
        finalData = resp?.data?.data?.docs?.[0]?._id;
      } else {
        throw `Data not found for ${key.replace(".fields.", ".")}`;
      }
      break;

    case "date":
      finalData = moment(rawData, "DD/MM/YYYY").toDate().getTime();
      break;

    default:
      finalData = rawData;
      break;
  }

  return finalData;
};

const checkObject = (obj) => {
  return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
};
