import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCashbooks,
  getCashbook,
} from "../../../app/reducers/Cashbook/cashbookSlice";
import PageWithCard from "../../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../../components/Table/TableWithHeadingAndSearch";
import moment from "moment/moment";
import { useFormik } from "formik";
import FormikSelectGroup from "../../../components/formik/FormikSelectGroup";
import {
  fetchLocations,
  getLocation,
} from "../../../app/reducers/Location/locationSlice";
import { computeOpeningBalance, generateOptions } from "../../../utils/Utils";
import FormikInputDateGroup from "../../../components/formik/FormikInputDateGroup";
import PrimaryButton from "../../../components/infrastructure/Buttons/PrimaryButton";
import { PropagateLoader } from "react-spinners";

const ViewCashbook = () => {
  const dispatch = useDispatch();
  const { cashbook, loading: cashbookLoading } = useSelector(getCashbook);
  const { location, loading: locationLoading } = useSelector(getLocation);
  useEffect(() => {
    dispatch(
      fetchCashbooks({
        populate: true,
      })
    );
    dispatch(fetchLocations());
  }, []);

  const cashbookMemo = useMemo(() => {
    return cashbook.docs ?? [];
  }, [cashbook]);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD/MM/YYYY - HH:mm");
      },
    },
    {
      Header: "location",
      accessor: "location.name",
    },
    {
      Header: "Expense Category",
      Cell: (prop) => {
        const { row } = prop;
        return row.original?.expense_category
          ? row.original?.expense_category
          : "-";
      },
    },
    {
      Header: "Amount",
      Cell: ({ row }) => {
        if (row.original.transaction_type == "debit") {
          return (
            <span className="text-red-600 font-semibold">
              {" "}
              -{row.original.amount}{" "}
            </span>
          );
        } else if (row.original.transaction_type == "credit") {
          return (
            <span className="text-green-600 font-semibold">
              {" "}
              +{row.original.amount}{" "}
            </span>
          );
        }
      },
      accessor: "amount",
    },
    {
      Header: "Balance",
      accessor: "balance",
    },
    {
      Header: "Note",
      accessor: "notes",
    },

    {
      Header: "type",
      Cell: ({ row }) => {
        if (row.original.transaction_type == "debit") {
          return <span className="text-red-600">Debit ( - ) </span>;
        } else if (row.original.transaction_type == "credit") {
          return <span className="text-green-600">Credit ( + )</span>;
        }
      },
      accessor: "transaction_type",
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: "",
      from: moment().subtract(30, "days").startOf("day").valueOf(),
      to: moment().endOf("day").valueOf(),
    },
    onSubmit: async (values) => {
      const payload = {
        "location._id": values.location,
        createdAt: {
          $gt: values.from,
          $lt: values.to,
        },
        populate: true,
      };
      dispatch(fetchCashbooks(payload));
    },
  });

  return (
    <PageWithCard heading="View Cashbook">
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-3 gap-4 my-4"
      >
        <FormikSelectGroup
          formik={formik}
          label="Select Location"
          name={"location"}
          options={generateOptions({
            array: location.docs,
            valueField: "_id",
            labelField: "name",
          })}
        />
        <FormikInputDateGroup name={"from"} label={"From"} formik={formik} />
        <FormikInputDateGroup name={"to"} label={"To"} formik={formik} />
        <div>
          <PrimaryButton disabled={formik.isSubmitting} type="submit">
            Submit
          </PrimaryButton>
        </div>
      </form>

      {cashbookLoading ? (
        <div className="flex items-center justify-center">
          <PropagateLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row gap-6 m-2 font-semibold">
            <h2>
              Opening Balance :{" "}
              <span>{computeOpeningBalance(cashbook?.docs)}</span>
            </h2>
            <h2>
              Closing Balance :{" "}
              <span>
                {cashbook?.docs?.[cashbook.docs.length - 1]?.balance
                  ? cashbook?.docs[cashbook.docs.length - 1].balance
                  : 0}
              </span>
            </h2>
          </div>

          <TableWithHeadingAndSearch
            heading="Cashbook"
            columns={columns}
            data={cashbookMemo}
          />
        </>
      )}
    </PageWithCard>
  );
};

export default ViewCashbook;
