import { createSlice } from "@reduxjs/toolkit";
import {
  generateSetEditId,
  resetData,
} from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "productPurchaseOrder";

export const fetchProductPurchaseOrders = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editProductPurchaseOrder = editData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchProductPurchaseOrder = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteProductPurchaseOrder = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createProductPurchaseOrder = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const productPurchaseOrderSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
    resetProductPurchaseOrderData: resetData(generateStateSingle(SLICE_NAME)),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchProductPurchaseOrders, SLICE_NAME),
    ...createData.generateExtraReducers(createProductPurchaseOrder, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteProductPurchaseOrder, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(
      fetchProductPurchaseOrder,
      SLICE_NAME
    ),
    ...editData.generateExtraReducers(editProductPurchaseOrder, SLICE_NAME),
  },
});

export const getProductPurchaseOrders = (state) => state.productPurchaseOrder;
export const { setEditId, resetProductPurchaseOrderData } =
  productPurchaseOrderSlice.actions;
export default productPurchaseOrderSlice.reducer;
