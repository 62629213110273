import React, { useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import ProductSelect from "./ProductSelect";
import SelectedProductRow from "./SelectedProductRow";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import moment from "moment/moment";
import SaleOrderProcessModal from "./SaleOrderProcessModal";
import { object, string } from "yup";
import { createSaleOrder } from "../../app/reducers/SaleOrder/saleOrderSlice";
import { Loader } from "react-feather";

const TableHeader = ({ headers }) => {
  return (
    <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers?.map((header) => (
          <th
            key={header.name}
            className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
          >
            <div style={header.style ?? {}} className="font-semibold text-left">
              {header.name}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const SalesOrder = () => {
  const saleLocationInfoID = JSON.parse(
    localStorage.getItem("saleLocationInfoId")
  );
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: saleLocationInfoID ?? "",
      customer: "",
      products: [],
      created_by: "",
      notes: "",
    },
    validationSchema: object({
      location: string().required(),
      customer: string().required("Please Select a Customer"),
      created_by: string().required("This is required field"),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log("values", values);
      // dispatch(createSaleOrder(values));
      if (values.products.length <= 0)
        return alert("Please selected at least one product to continue");
      // setOpenSaleProcess(true);
      await dispatch(createSaleOrder(values));
      resetForm();
    },
  });

  const [openSaleProcess, setOpenSaleProcess] = useState(false);

  return (
    <div className="px-4 ">
      <SaleOrderProcessModal
        parentFormik={formik}
        isOpen={openSaleProcess}
        setIsOpen={setOpenSaleProcess}
      />
      <h1 className="font-bold text-3xl py-3">Create Sale Order</h1>
      <form className="flex flex-col gap-3" onSubmit={formik.handleSubmit}>
        <FormikAsyncSelect
          required
          name="customer"
          formik={formik}
          label="Search Customer"
          getOptions={async (value) => {
            const string = QueryString.stringify({ search: value });
            const customers = await authAxiosInstance.get(
              `users/customer?${string}`
            );
            const options = customers?.data?.data?.docs?.map((ele) => ({
              label: `${ele?.firstName} ${ele?.lastName} / ${ele?.username}`,
              value: ele?._id,
            }));
            return options;
          }}
          onChange={async (selectedOption) => {
            const string = QueryString.stringify({
              _id: selectedOption?.value,
            });
            const resp = await authAxiosInstance.get(
              `users/customer?${string}`
            );
            const user = resp?.data?.data?.docs[0];

            formik.setFieldValue("customer", user._id);
          }}
        />
        <div className="grid grid-cols-2 gap-3">
          <FormikAsyncSelect
            label="Created By"
            name="created_by"
            formik={formik}
            getOptions={async (searchValue) => {
              const string = QueryString.stringify({ search: searchValue });
              const resp = await authAxiosInstance.get(
                `/users/employee?${string}`
              );
              console.log(resp);
              const options = resp?.data?.data?.docs?.map((ele) => ({
                label: `${ele.firstName} ${ele.lastName}`,
                value: `${ele.firstName} ${ele.lastName}`,
              }));

              return options;
            }}
            required
          />
          <FormikTextareaGroup
            row={1}
            label="Notes"
            name="notes"
            formik={formik}
          />
        </div>

        <FormikProvider value={formik}>
          <FieldArray
            name="products"
            render={(arrayHelpers) => (
              <div className="flex flex-col gap-3">
                <div>
                  <ProductSelect formik={formik} arrayHelpers={arrayHelpers} />
                </div>
                <div className="w-full overflow-x-auto">
                  <table className=" ">
                    <TableHeader
                      headers={[
                        { name: "" },
                        { name: "photo" },
                        { name: "Product" },
                        { name: "qty", style: { width: "40px" } },
                        { name: "MRP", style: { width: "65px" } },
                        { name: "Selling Price" },
                        { name: "Discount" },
                        { name: "Total Discount" },
                        { name: "Tax type" },
                        { name: "Tax Rate", style: { width: "40px" } },
                        { name: "Tax AMT", style: { width: "40px" } },
                        { name: "Total Tax" },
                        { name: "Total Price" },
                      ]}
                    />
                    <tbody>
                      {formik.values.products.map((ele, index) => (
                        <SelectedProductRow
                          key={index}
                          formik={formik}
                          element={ele}
                          index={index}
                          arrayHelpers={arrayHelpers}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          />
        </FormikProvider>
        <div>
          {formik.isSubmitting ? (
            <div className="my-auto">
              <Loader className="animate-spin " />
            </div>
          ) : (
            <PrimaryButton disabled={formik.isSubmitting} type="submit">
              Submit
            </PrimaryButton>
          )}{" "}
        </div>
      </form>
    </div>
  );
};

export default SalesOrder;
