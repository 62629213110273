import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers, getCustomers } from '../../app/reducers/Users/CustomerSlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { toast } from 'react-toastify';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { exportCsv } from '../../utils/Utils';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';

const CustomerSalesReport = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [customerSalesData, setCustomerSalesData] = useState({});
  const [customerSalesCountData, setCustomerSalesCountData] = useState({});
  const { customer, loading } = useSelector(getCustomers);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: [],
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      try {
        setIsLoading(true);
        let filterData = {
          createdAt: { $gt: values?.from, $lt: values?.to },
          page,
        };
        if (values?.customer) {
          filterData['customerId'] = { $in: values?.customer };
        }
        const string = QueryString.stringify(filterData);
        const response = await authAxiosInstance.get(`/report/customer-sales?${string}`);
        if (response?.data) {
          setCustomerSalesData(response?.data?.data);
        } else {
          setCustomerSalesData({});
        }
        const countResponse = await authAxiosInstance.get(`/report/customer-sales-count?${string}`);

        if (countResponse?.data) {
          setCustomerSalesCountData(countResponse?.data?.data?.docs[0]);
        } else {
          setCustomerSalesCountData({});
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Error Fetching Sales ');
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    dispatch(fetchCustomers());
    getCustomerSales();
    getCustomerSalesCountData();
  }, [page]);

  const getCustomerSales = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        page,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(`/report/customer-sales?${string}`);

      if (response?.data) {
        setCustomerSalesData(response?.data?.data);
      } else {
        setCustomerSalesData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Fetching Sales ');
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomerSalesCountData = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        page,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(`/report/customer-sales-count?${string}`);

      if (response?.data) {
        setCustomerSalesCountData(response?.data?.data?.docs[0]);
      } else {
        setCustomerSalesCountData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Fetching Customer Sales Count ');
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: 'SRNo.',
      Cell: ({ row }) => {
        return row.index + customerSalesData?.pagingCounter;
      },
    },
    {
      Header: 'Customer',
      Cell: ({ row }) => {
        return row?.original?.customerData?.firstName + ' ' + row?.original?.customerData?.lastName;
      },
    },
    {
      Header: 'Company',
      accessor: 'customerData.companyName',
    },
    {
      Header: 'Total Sales',
      accessor: 'totalSalesCustomerWise',
    },
  ];

  const exportCsvDownloadCustomerSalesData = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: { $gt: formik?.values?.from, $lt: formik?.values?.to },
        limit: 3000,
      };
      if (formik?.values?.customer) {
        filterData['customerId'] = { $in: formik?.values?.customer };
      }
      const string = QueryString.stringify(filterData);
      const response = await authAxiosInstance.get(`/report/customer-sales?${string}`);

      if (response?.data?.data?.docs?.length > 0) {
        let reportData = response?.data?.data?.docs;
        let csvData = reportData.map(item => {
          const customerName = item?.customerData?.firstName + ' ' + item?.customerData?.lastName;
          return {
            Customer: customerName,
            Company: item?.customerData?.companyName,
            TotalSales: item?.totalSalesCustomerWise,
          };
        });
        console.log(csvData);
        exportCsv(csvData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Downloading data');
    } finally {
      setIsLoading(false);
    }
  };

  const columnsMemoization = useMemo(() => columns ?? [], [columns]);
  const dataMemoization = useMemo(() => customerSalesData?.docs ?? [], [customerSalesData]);

  return (
    <PageWithCard heading="Customer Sales Report">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikMultiSelect
          name="customer"
          formik={formik}
          label="Select Customer"
          options={customer?.docs?.map(el => ({
            value: el._id,
            label: `${el.firstName}-${el.lastName}`,
          }))}
        />
        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />
        <div className="flex gap-3">
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
          <PrimaryButton type="button" onClick={exportCsvDownloadCustomerSalesData}>
            Download
          </PrimaryButton>
        </div>
      </form>
      {isLoading || loading ? (
        <ClipLoader />
      ) : (
        <>
          <div className="mt-2 mb-2">
            {customerSalesCountData && (
              <div className="flex flex-row gap-2">
                <div className="flex flex-row gap-1">
                  <p className="text text-base font-semibold">Total Sales:</p>
                  <p>{customerSalesCountData?.totalCustomerWiseSalesCount}</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-3">
            <TableWithHeadingAndSearch data={dataMemoization} columns={columnsMemoization} />
          </div>
          <PaginationClassic paginationDetails={customerSalesData} setPage={setPage} />
        </>
      )}
    </PageWithCard>
  );
};

export default CustomerSalesReport;
