import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCollections,
  getGeneralBulkUpload,
} from "../../app/reducers/GeneralBulkUpload/generalBulkUploadSlice";
import { useFormik } from "formik";

import Step1 from "./Step1";
import Step2 from "./Step2";
import { authAxiosInstance } from "../../utils/axiosConfig";
import customId from "custom-id";
import { toast } from "react-toastify";
import { getUploadObject } from "../../utils/getUploadObject";
import { ClipLoader } from "react-spinners";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { exportCsv } from "../../utils/Utils";

const GeneralBulkUpload = () => {
  const dispatch = useDispatch();
  const { collections } = useSelector(getGeneralBulkUpload);
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadObjectLoading, setUploadObjectLoading] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const [showUploadData, setShowUploadData] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [uploadData, setUploadData] = useState([]);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [createErrors, setCreateErrors] = useState([]);
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);

  useEffect(() => {
    dispatch(fetchCollections());
  }, []);

  // const batchNumber = nanoid();
  const [batchNumber, setBatchNumber] = useState(customId({}));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      collection: "",
      csv: "",
      parsedCsvArr: [],
      headings: [],
      analyzedObj: {},
      relationalFields: [],
      fileData: [],
      createUrl: "",
      batchNumber: batchNumber,
    },
    onSubmit: async (values) => {
      console.log(values, "values");
      setUploadObjectLoading(true);
      setShowForm(false);
      const { updatedCsv, errors } = await getUploadObject(
        values.parsedCsvArr,
        values.analyzedObj
      )
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUploadObjectLoading(false);
        });

      console.log(updatedCsv, "upload Data");
      setUploadData(updatedCsv);
      setUploadErrors(errors);
      setShowUploadData(true);
    },
  });

  const uploadDataToServer = async () => {
    if (uploadErrors.length > 0) {
      const confirm = window.confirm(
        "File you are about to upload has errors are you sure you want to upload this file"
      );
      if (!confirm) {
        return toast.info("Upload canceled by user");
      }
    }
    setShowUploadData(false);
    setProgressLoading(true);
    setTotalItem(uploadData.length);

    for (let i = 0; i < uploadData.length; i++) {
      const element = uploadData[i];
      await authAxiosInstance
        .post(formik.values.createUrl, { ...element, uploadBatch: batchNumber })
        .catch((error) => {
          console.log(error);
          setCreateErrors((prevState) => {
            let errorResponse = error?.response?.data?.error;
            if (errorResponse) {
              console.log(typeof errorResponse);
              return [
                ...prevState,
                {
                  index: i + 1,
                  error: errorResponse.replace(",", "-"),
                },
              ];
            } else {
              return [
                ...prevState,
                {
                  index: i + 1,
                  error: "Internal Server Error",
                },
              ];
            }
          });
        })
        .finally(() => {
          setCurrentItem((prevState) => prevState + 1);
        });
    }
  };

  return (
    <PageWithCard heading="General Bulk Upload">
      {uploadObjectLoading && (
        <div className="flex">
          <span>Generating Upload Object</span>
          <ClipLoader />
        </div>
      )}
      {showUploadData && (
        <div className="flex flex-col gap-4">
          <div>
            Upload Data generated with {uploadData?.length} entries and{" "}
            <span
              onClick={() => {
                exportCsv(uploadErrors);
              }}
              className="text-indigo-500 inline underline cursor-pointer"
            >
              {uploadErrors.length} errors
            </span>
          </div>
          <div>
            <PrimaryButton type="button" onClick={uploadDataToServer}>
              Start Upload
            </PrimaryButton>
          </div>
        </div>
      )}
      {progressLoading ? (
        <ProgressBar
          currentItem={currentItem}
          errorData={createErrors}
          totalItem={totalItem}
        />
      ) : (
        <></>
      )}
      {showForm && (
        <form onSubmit={formik.handleSubmit}>
          <Step1.Component
            formik={formik}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <Step2.Component
            formik={formik}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </form>
      )}
    </PageWithCard>
  );
};

export default GeneralBulkUpload;
