import React from 'react';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';

const computeValue = (options, val) => {
    const resp = options.find((option) => option.value.toString() === val);

    return resp ? resp : null;
};

const FormikCreatableSelect = ({
    formik,
    label = '',
    required = false,
    options,
    name,
    refetch = false,
    ignoreDotInName = false,
    ...props
}) => {
    const onMenuOpen = () => {
        setTimeout(() => {
            const selectedEl = document.getElementsByClassName(
                'react-select__option--is-selected'
            )[0];

            if (selectedEl) {
                selectedEl.scrollIntoView({
                    behavior: 'instant',
                    block: 'center',
                    inline: 'start',
                });
            }
        }, 150);
    };
    const onChange = props?.onChange
        ? props.onChange
        : (selectedOption) => {
              formik.setFieldValue(name, selectedOption?.value.toString());
          };
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="w-full"
        >
            <div className="flex items-center gap-2  mb-1">
                <label className="block text-sm font-medium" htmlFor={name}>
                    {label}{' '}
                    {required && <span className="text-rose-500">*</span>}
                </label>
                {refetch && (
                    <p onClick={refetch} className="text-xs underline">
                        Refetch
                    </p>
                )}
            </div>
            <CreatableSelect
                name={name}
                value={computeValue(options, _.at(formik.values, name)[0])}
                options={options}
                onBlur={() => formik.setFieldTouched(name, true)}
                onChange={onChange}
                menuPortalTarget={document.body}
                onMenuOpen={onMenuOpen}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                {...props}
            />

            {formik?.errors?.[name] && formik?.touched?.[name] ? (
                <p className="text-xs text-red-500">{formik?.errors?.[name]}</p>
            ) : null}
        </div>
    );
};

export default FormikCreatableSelect;
