import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchProductChallans,
  getProductChallans,
} from "../../app/reducers/ProductChallan/productChallanSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import moment from "moment";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";

const ViewChallanByPurchaseOrder = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const { productChallan, loading } = useSelector(getProductChallans);
  const { baseUrl } = useSelector(getMediaLibrary);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductChallans({ poRef: id, populate: true, page }));
  }, [id, page]);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format("DD/MM/YYYY")
          : "No Date Found";
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: "relative" }}>
              {_.isEmpty(row?.original?.product?.photo) ? (
                "No Image"
              ) : (
                <img
                  src={`${baseUrl}${row.original?.product?.photo}`}
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Product",
      accessor: "product.name",
    },
    {
      Header: "Challan Number",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {row?.original?.challanNumber}
          </div>
        );
      },
    },
    {
      Header: "Challan Date",
      Cell: ({ row }) => {
        return row?.original?.challanDate
          ? moment(row?.original?.challanDate).format("DD/MM/YYYY")
          : "No Date Found";
      },
    },
    {
      Header: "Received Quantity",
      accessor: "receivedQty",
    },
    {
      Header: "Damaged",
      accessor: "damaged",
    },
    {
      Header: "Notes",
      accessor: "notes",
    },
  ];

  const dataMemoization = useMemo(() => {
    return productChallan?.docs ?? [];
  }, [productChallan]);

  const columnMemoization = useMemo(() => columns ?? [], [columns]);

  return (
    <PageWithCard heading="View Challans">
      <TableWithHeadingAndSearch
        heading={`View Challans By Purchase Order`}
        columns={columnMemoization}
        data={dataMemoization}
      />
      <PaginationClassic setPage={setPage} paginationDetails={productChallan} />
    </PageWithCard>
  );
};

export default ViewChallanByPurchaseOrder;
