import { useEffect, useMemo, useState } from "react";
import FormikMultiSelect from "../components/formik/FormikMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../app/reducers/Location/locationSlice";
import { useFormik } from "formik";
import { authAxiosInstance } from "../utils/axiosConfig";
import QueryString from "qs";
import { generateOptions } from ".././utils/Utils";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import TableWithHeadingAndSearch from ".././components/Table/TableWithHeadingAndSearch";
import UniversalSearch from "./UniversalSearch/UniversalSearch";
import ShowDropdown from "../components/infrastructure/ShowDropdown";
import moment from "moment";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [productPendingBalance, setProductPendingBalance] = useState([]);
  const [productInventoryTotalReceive, setProductInventoryTotalReceive] =
    useState([]);
  const [productInventoryTotalDamaged, setProductInventoryTotalDamaged] =
    useState([]);
  const [
    productInventoryTotalProductValuation,
    setProductInventoryTotalProductValuation,
  ] = useState([]);
  const [
    productInventoryParentSkuWiseData,
    setProductInventoryParentSkuWiseData,
  ] = useState([]);
  const [totalSumOfSalesProduct, setTotalSumOfSalesProduct] = useState({});
  const [totalSalesValuation, setTotalSalesValuation] = useState({});
  const [totalSumOfSalesReturnProduct, setTotalSumOfSalesReturnProduct] =
    useState({});
  const [totalSalesReturnValuation, setTotalSalesReturnValuation] = useState(
    {}
  );
  const { location, loading } = useSelector(getLocation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationInfold = JSON.parse(localStorage.getItem("locationInfoId"));

  // useEffect(() => {
  //   dispatch(fetchLocations());
  // }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { location: locationInfold ?? [] },
    onSubmit: () => {},
  });

  const locationData = useMemo(
    () => (location?.docs ? location.docs : []),
    [location, locationInfold]
  );

  useEffect(() => {
    getDashboardTotalSaleCount();
    getDashboardTotalSaleReturnCount();
    getDashboardCount();
    dispatch(fetchLocations());
  }, [JSON.stringify(formik?.values?.location)]);

  const getDashboardCount = async () => {
    try {
      setIsLoading(true);
      const filterQuery = QueryString.stringify({
        filteredLocationArray: formik?.values?.location,
      });

      const response = await authAxiosInstance.get(`dashboard?${filterQuery}`);
      if (response) {
        setProductPendingBalance(response?.data?.data?.productPendingBalance);
        setProductInventoryTotalReceive(
          response?.data?.data?.productInventoryTotalReceivedData
        );
        setProductInventoryTotalDamaged(
          response?.data?.data?.productInventoryTotalDamagedData
        );
        setProductInventoryTotalProductValuation(
          response?.data?.data?.productInventoryTotalProductValuationData
        );
        setProductInventoryParentSkuWiseData(
          response?.data?.data?.productInventoryTotalParentSkuWiseValuationData
        );
      } else {
        setProductPendingBalance([]);
        setProductInventoryTotalReceive([]);
        setProductInventoryTotalDamaged([]);
        setProductInventoryTotalProductValuation([]);
        setProductInventoryParentSkuWiseData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to Get Dashboard Data");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDashboardTotalSaleCount = async () => {
    try {
      setIsLoading(true);
      let filterSales = {
        location: { $in: formik.values.location },
        createdAt: {
          $gt: moment().startOf("day").valueOf(),
          $lt: moment().endOf("day").valueOf(),
        },
      };
      const query = QueryString.stringify(filterSales);
      const responseCount = await authAxiosInstance.get(
        `dashboard/sale-count?${query}`
      );

      const responseValuation = await authAxiosInstance.get(
        `dashboard/sale-valuation?${query}`
      );

      if (responseCount || responseValuation) {
        setTotalSumOfSalesProduct(responseCount?.data?.data?.docs[0]);
        setTotalSalesValuation(responseValuation?.data?.data?.docs[0]);
      } else {
        setTotalSumOfSalesProduct({});
        setTotalSalesValuation({});
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to Get Total Sales");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDashboardTotalSaleReturnCount = async () => {
    try {
      setIsLoading(true);
      let filterSales = {
        location: { $in: formik.values.location },
        createdAt: {
          $gt: moment().startOf("day").valueOf(),
          $lt: moment().endOf("day").valueOf(),
        },
      };
      const query = QueryString.stringify(filterSales);
      const responseCount = await authAxiosInstance.get(
        `dashboard/sale-return-count?${query}`
      );

      const responseValuation = await authAxiosInstance.get(
        `dashboard/sale-return-valuation?${query}`
      );

      if (responseCount || responseValuation) {
        setTotalSumOfSalesReturnProduct(responseCount?.data?.data?.docs[0]);
        setTotalSalesReturnValuation(responseValuation?.data?.data?.docs[0]);
      } else {
        setTotalSumOfSalesReturnProduct({});
        setTotalSalesReturnValuation({});
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to Get Total Sales Return");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: "Parent Sku",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() => {
              const string = QueryString.stringify({
                location: formik.values.location,
              });
              navigate(
                `/dashboard/viewProductByParentSku/${row?.original?._id}?${string}`
              );
            }}
          >
            {row?.original?._id}
          </div>
        );
      },
      accessor: "_id",
    },

    {
      Header: "Received",
      accessor: "sumOfTotalReceived",
    },
    {
      Header: "Damaged",
      accessor: "sumOfTotalDamaged",
    },
  ];

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
          Dashboard
        </h1>
      </div>

      {/* Content */}

      {loading || isLoading ? (
        <ClipLoader />
      ) : (
        <FormikMultiSelect
          label="Select Location"
          name="location"
          formik={formik}
          options={generateOptions({
            array: locationData ?? [],
            valueField: "_id",
            labelField: "name",
          })}
        />
      )}

      <div className="bg-white  rounded-sm mb-8">
        <div className="grid w-full grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  justify-center sm:justify-around p-2 sm:p-8 items-center gap-2">
          {/* // */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Product Pending</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productPendingBalance &&
                  productPendingBalance.length &&
                  productPendingBalance.map((el, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div
                        key={i}
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/dashboard/viewVendorWisePurchaseOrder`)
                        }
                      >
                        {el?.sumOfCurrentProductPendingBalance}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* // */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">
                Total Product Received
              </header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productInventoryTotalReceive &&
                  productInventoryTotalReceive.length &&
                  productInventoryTotalReceive.map((el, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{el?.productTotalInventoryReceived}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* // */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Damaged Product</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productInventoryTotalDamaged &&
                  productInventoryTotalDamaged.length &&
                  productInventoryTotalDamaged.map((el, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{el?.productTotalInventoryDamaged}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* // */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Inventory Valuation</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productInventoryTotalProductValuation &&
                  productInventoryTotalProductValuation.length &&
                  productInventoryTotalProductValuation.map((el, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{el?.totalInventoryProductValuation}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* totalSale */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Today's Sales</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSumOfSalesProduct ? (
                  <div className="flex-col items-center text-lg">
                    <div>{totalSumOfSalesProduct?.totalTodayQty}</div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>
          {/* totalSale Valuation */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">
                Today's Sales Valuation
              </header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSalesValuation ? (
                  <div className="flex-col items-center text-lg">
                    <div>{totalSalesValuation?.totalValuation}</div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>

          {/* totalSale return */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Today's Return</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSumOfSalesReturnProduct ? (
                  <div className="flex-col items-center text-lg">
                    <div>{totalSumOfSalesReturnProduct?.totalTodayQty}</div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>
          {/* totalSale return Valuation */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">
                Today's Return Valuation
              </header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSalesReturnValuation ? (
                  <div className="flex-col items-center text-lg">
                    <div>{totalSalesReturnValuation?.totalValuation}</div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>
          {/* net sale =sale valuation-return valuation */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Today's Net Sales</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSalesReturnValuation || totalSalesValuation ? (
                  <div className="flex-col items-center text-lg">
                    <div>
                      {totalSalesValuation
                        ? totalSalesValuation?.totalValuation
                        : 0 - totalSalesReturnValuation
                        ? totalSalesReturnValuation?.totalValuation
                        : 0}
                    </div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {productInventoryParentSkuWiseData && (
        <div className=" mx-8">
          <ShowDropdown allowedRoles={["admin"]} heading="Inventory">
            <TableWithHeadingAndSearch
              columns={columns}
              data={productInventoryParentSkuWiseData}
              heading="Inventory"
            />
          </ShowDropdown>
        </div>
      )}
      {/* imported Universal Search */}
      <UniversalSearch />
    </div>
  );
};

export default Dashboard;
