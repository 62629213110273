import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { editEmployee, fetchEmployee, getEmployees } from '../../app/reducers/Users/EmployeeSlice';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { ROLES } from '../../utils/dropdownOptions';

const EditEmployeePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { elementEditData, elementEditDataLoading } = useSelector(getEmployees);
  const { user } = useSelector(getAuth);
  useEffect(() => {
    dispatch(fetchEmployee({ _id: id }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
      password: '',
    },
    validationSchema: object({
      firstName: string().required(),
      username: string().required(),
    }),
    onSubmit: async values => {
      console.log(values);
      await dispatch(editEmployee(values));
      await dispatch(fetchEmployee({ _id: id }));
    },
  });
  return (
    <PageWithCard>
      {elementEditDataLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup label="System Id" name="_id" required readOnly formik={formik} />
          <FormikInputGroup label="Phone" name="username" required readOnly formik={formik} />
          <FormikInputGroup label="First Name" name="firstName" required formik={formik} />
          <FormikInputGroup label="Last Name" name="lastName" formik={formik} />
          <FormikInputGroup label="Password" name="password" type="password" formik={formik} />
          <FormikCheckbox label="Status" name="status" formik={formik} />
          <FormikSelectGroup
            formik={formik}
            label="Role"
            name="role"
            required
            options={generateOptions({
              array: user.role == 'admin' ? ROLES : ROLES.filter(d => d.value !== 'admin'),
              valueField: 'value',
              labelField: 'label',
            })}
          />
          <div>
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default EditEmployeePage;
