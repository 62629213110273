import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads, getLeads } from "../../app/reducers/Lead/leadSlice";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import moment from "moment";
import { ChevronLeft, Download, Edit, Eye, Mail } from "react-feather";
import whatsappIcon from "../../images/whatsapp_PNG.png";
import { useNavigate } from "react-router-dom";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import MessageTextModal from "./MessageTextModal";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { exportCsv } from "../../utils/Utils";

const ViewLead = () => {
  const { lead } = useSelector(getLeads);
  console.log("lead", lead);
  const [linkOption, setLinkOption] = useState({});
  const [openMessageBox, setOpenMessageBox] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      fetchLeads({
        populate: true,
        editStatus: "approved",
        sort: { leadNumber: 1 },
      })
    );
  }, []);

  const replacePlaceholder = (inputStr, replacementArray) => {
    let replacedStr = inputStr;

    for (let i = 0; i < replacementArray.length; i++) {
      replacedStr = replacedStr.replace("{#var#}", replacementArray[i]);
    }

    return replacedStr;
  };

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchLeads({ search, editStatus: "approved", populate: true }));
    }, 300)
  );

  const cols = [
    {
      Header: "Lead Number",
      accessor: "leadNumber",
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Name",
      accessor: "name",
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Phone Numbers",
      Cell: ({ row }) => {
        return (
          <ul>
            {row?.original?.contactNumbers?.map((contactNumber) => (
              <li>{contactNumber}</li>
            ))}
          </ul>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: ({ row }) => {
        return (
          <div className="max-w-md">
            <p className="text-wrap">{row.original.notes}</p>
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status.name",
      Cell: ({ row }) => {
        return <span>{row?.original?.status?.name}</span>;
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Meeting Date",
      Cell: ({ row }) => {
        return (
          <span>
            {row?.original?.meetingDate
              ? moment(row?.original?.meetingDate).format("DD/MM/YYYY")
              : null}
          </span>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Meeting Time",
      Cell: ({ row }) => {
        return (
          <span>
            {row?.original?.meetingTime
              ? moment(row?.original?.meetingTime).format("hh:mm a")
              : null}
          </span>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Products",
      Cell: ({ row }) => {
        return (
          <ul>
            {row?.original?.product?.map((n) => (
              <li>{n.name}</li>
            ))}
          </ul>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Reference",
      Cell: ({ row }) => {
        return <span>{row?.original?.reference?.name}</span>;
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center gap-2">
            <Edit
              size={22}
              color="#292ba9"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                navigate(`/lead/edit/${row?.original?._id}`);
              }}
            />
            <Eye
              size={22}
              color="#408bff"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                navigate(`/leadHistory/${row?.original?.leadNumber}`);
              }}
            />
            <span
              onClick={async (e) => {
                e.stopPropagation();
                const template = "Hello, {#var#}, We have a meeting";
                const message = replacePlaceholder(template, [
                  `${row?.original?.name}`,
                ]);
                const linkOption = {
                  phone: `${row?.original?.contactNumbers[0]?.contactNumber}`,
                  text: message,
                };
                setLinkOption(linkOption);
                setOpenMessageBox(true);
              }}
            >
              <img
                src={whatsappIcon}
                alt="Whatsapp"
                className="w-[30px] h-[20px]"
              />
            </span>
            <Mail
              size={22}
              color="#c82a2a"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                const subject = encodeURIComponent("Hello");
                const body = encodeURIComponent(
                  "I would like to inquire about..."
                );
                const mailtoLink = `mailto:${row?.original?.emails[0]}?subject=${subject}&body=${body}`;
                window.location.href = mailtoLink;
              }}
            />
          </div>
        );
      },
      cellStyle: {
        width: "40%",
      },
    },
    // {
    //   Header: "Chat",
    //   Cell: ({ row }) => {
    //     return (
    //       <div className="flex flex-row items-center gap-4 w-full">
    //         <span
    //           onClick={async (e) => {
    //             e.stopPropagation();
    //             const template = "Hello, {#var#}, We have a meeting";
    //             const message = replacePlaceholder(template, [
    //               `${row?.original?.name}`,
    //             ]);
    //             const linkOption = {
    //               phone: `${row?.original?.contactNumbers[0]?.contactNumber}`,
    //               text: message,
    //             };
    //             setLinkOption(linkOption);
    //             setOpenMessageBox(true);
    //           }}
    //         >
    //           <img
    //             src={whatsappIcon}
    //             alt="Whatsapp"
    //             className="w-[30px] h-[30px]"
    //           />
    //         </span>
    //         <Mail
    //           color="#c82a2a"
    //           className="cursor-pointer"
    //           onClick={async (e) => {
    //             e.stopPropagation();
    //             const subject = encodeURIComponent("Hello");
    //             const body = encodeURIComponent(
    //               "I would like to inquire about..."
    //             );
    //             const mailtoLink = `mailto:${row?.original?.email}?subject=${subject}&body=${body}`;
    //             window.location.href = mailtoLink;
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    //   cellStyle:{
    //     width : '20%'
    //   }
    // },
  ];

  const leadMemo = useMemo(() => (lead?.docs ? lead?.docs : []), [lead]);

  return (
    <PageWithCard heading="View Lead">
      <MessageTextModal
        linkOption={linkOption}
        openMessageBox={openMessageBox}
        setOpenMessageBox={setOpenMessageBox}
      />
      <TableWithHeadingAndGlobalSearch
        heading={
          <>
            <div className="flex flex-row items-center gap-4">
              <span>Leads</span>
              {isDownloading ? (
                <ClipLoader />
              ) : (
                <SecondaryButton
                  onClick={async (e) => {
                    e.stopPropagation();
                    try {
                      setIsDownloading(true);
                      let data = {
                        populate: true,
                        limit: 5000,
                      };
                      const stringData = QueryString.stringify(data);
                      const resp = await authAxiosInstance.get(
                        `/lead?${stringData}`
                      );
                      console.log("resp data", resp?.data?.data);
                      if (resp?.data?.data?.docs?.length > 0) {
                        let mainData = resp?.data?.data?.docs.map(
                          (lead, index) => {
                            let {
                              name,
                              contactNumbers,
                              emails,
                              product,
                              meetingDate,
                              meetingTime,
                              leadNumber,
                              status,
                              editStatus,
                              reference,
                              editSummary,
                              notes,
                              createdBy,
                            } = lead;
                            const numbersCon = contactNumbers
                              .map((contactNumber) => contactNumber)
                              .join(", ");
                            console.log("numbers", numbersCon);
                            const emailCon = emails
                              .map((email) => email)
                              .join(", ");
                            const productCon = product
                              .map((n) => n.name)
                              .join(", ");
                            return {
                              srNo: `${index + 1}`,
                              leadNumber: leadNumber,
                              name: name,
                              contactNumbers: numbersCon,
                              emails: emailCon,
                              status: status?.name,
                              products: productCon,
                              editStatus: editStatus,
                              meetingDate: meetingDate
                                ? moment(meetingDate).format("DD/MM/YYYY")
                                : null,
                              meetingTime: meetingDate
                                ? moment(meetingTime).format("HH:mm a")
                                : null,
                              reference: reference?.name,
                              editSummary: editSummary,
                              notes: notes,
                              createdBy: createdBy ? createdBy : null,
                            };
                          }
                        );
                        console.log("main data", mainData);
                        exportCsv(mainData);
                      }
                    } catch (error) {
                      console.log("Error", error);
                      toast.error("Failed to download csv file");
                    } finally {
                      setIsDownloading(false);
                    }
                  }}
                >
                  <Download size={18} className="mr-2" /> Leads Csv
                </SecondaryButton>
              )}
            </div>
          </>
        }
        columns={cols}
        data={leadMemo}
        searchFunction={debouncedSearch}
      />
    </PageWithCard>
  );
};

export default ViewLead;
