import React, { useEffect, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import ProgressBar from '../../components/progressBar/ProgressBar';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { Minus, Plus } from 'react-feather';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { ClipLoader } from 'react-spinners';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import * as Yup from 'yup';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import QueryString from 'qs';

const ScanInwardPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const { vendor } = useSelector(getVendors);
  const { location } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendors());
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    initialValues: {
      products: [],
      location: '',
      manufacturer: '',
      challanNumber: '',
      damaged: 0,
      challanDate: '',
      status: '',
      notes: '',
    },
    validationSchema: Yup.object({
      location: Yup.string(),
      manufacturer: Yup.string(),
      challanNumber: Yup.string(),
      damaged: Yup.number().min(0),
      challanDate: Yup.string(),
      status: Yup.string(),
      notes: Yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      //   const existingChallan = await dispatch(
      //     fetchProductChallans({
      //       challanNumber: values?.challanNumber,
      //       manufacturer: values?.manufacturer,
      //     })
      //   );
      //   if (existingChallan?.payload?.data?.totalDocs > 0) {
      //     alert("Manufacture With Same Challan Number Exist");
      //     setIsLoading(false);
      //     return;
      //   }
      let filterProduct = values?.products?.filter(ele => ele.sku);
      if (filterProduct?.length > 0) {
        setProgressLoading(true);
        setTotalItem(filterProduct.length);
        let challanIncrementCount = 0;
        for (let i = 0; i < filterProduct.length; i++) {
          try {
            const singleProduct = filterProduct[i];
            challanIncrementCount += 1;
            let payload = {
              product: singleProduct?.product,
              location: values?.location,
              manufacturer: values?.manufacturer,
              challanNumber: `${values?.challanNumber}_${challanIncrementCount}`,
              receivedQty: Number(singleProduct?.receivedQty),
              damaged: Number(0),
              challanDate: values?.challanDate,
              status: 'directReceiveOrder',
              notes: values?.notes,
            };
            const challanResponse = await authAxiosInstance.post('/productChallan/direct', payload);

            if (challanResponse?.data) {
              setCurrentItem(prevState => prevState + 1);
            }
          } catch (error) {
            console.log(error);
            setCurrentItem(prevState => prevState + 1);
            toast.error('Error on Submit');
            setErrorData(prev => [
              ...prev,
              {
                ...singleProduct[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      } else {
        toast.error('Please Add Atleast One Product');
      }
    },
  });

  const onKeyPressBarcode = async (event, i, arrayHelpers) => {
    if (event.keyCode === 13 && event.target.value) {
      event.preventDefault();
      let checkExist = formik.values.products?.find(d => d.sku == event.target.value);

      const string = QueryString.stringify({
        masterSku: event.target.value,
        manufacturer: formik?.values?.manufacturer,
      });

      const resp = await authAxiosInstance.get(`product?${string}`);
      const product = resp.data.data.docs[0];

      if (!product) {
        alert('Product Not found');
      } else {
        if (checkExist) {
          let incQty = checkExist.receivedQty + 1;
          let productIndex = formik.values.products?.findIndex(d => d.sku == event.target.value);
          formik.setFieldValue(`products.${productIndex}.receivedQty`, incQty);
          formik.setFieldValue(`products.${i}.product`, '');
          formik.setFieldValue(`products.${i}.id`, '');
          return toast.success('Qty updated on product');
        }

        formik.setFieldValue(`products.${i}.sku`, product.masterSku);
        formik.setFieldValue(`products.${i}.productName`, product.name);
        formik.setFieldValue(`products.${i}.product`, product._id);
        formik.setFieldValue(`products.${i}.receivedQty`, 1);
        arrayHelpers.push('');
      }
    }
  };

  const increment = async (e, i) => {
    e.stopPropagation();
    let data = formik?.values?.products[i];
    let incQty = data.receivedQty + 1;
    formik.setFieldValue(`products.${i}.receivedQty`, incQty);
  };

  const decrement = async (e, i) => {
    e.stopPropagation();
    let data = formik.values.products[i];
    if (data.receivedQty == 1) {
      window.alert('Not allowed');
    } else {
      let incQty = data.receivedQty - 1;
      formik.setFieldValue(`products.${i}.receivedQty`, incQty);
    }
  };
  return (
    <PageWithCard heading="Scan InWard">
      {progressLoading ? (
        <ProgressBar currentItem={currentItem} totalItem={totalItem} errorData={errorData} />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
          <FormikSelectGroup
            options={generateOptions({
              array: vendor?.docs ?? [],
              labelField: 'companyName',
              valueField: '_id',
            })}
            label="Manufacturer"
            name="manufacturer"
            formik={formik}
          />
          <FormikSelectGroup
            options={generateOptions({
              array: location?.docs ?? [],
              labelField: 'name',
              valueField: '_id',
            })}
            label="Location"
            name="location"
            formik={formik}
          />

          <FormikInputGroup name="challanNumber" label="Challan Number" formik={formik} />
          <FormikInputDateGroup name="challanDate" label="Date" formik={formik} />
          <FormikTextareaGroup name="notes" label="Notes" formik={formik} />
          {formik?.values?.location &&
            formik?.values?.challanNumber &&
            formik?.values?.manufacturer &&
            formik?.values?.challanDate && (
              <>
                <FormikProvider value={formik}>
                  <FieldArray
                    name="products"
                    render={arrayHelpers => {
                      return (
                        <div className="flex flex-col gap-2">
                          <div>
                            {formik.values.products.map((ele, index) => (
                              <div
                                className="relative p-4 mb-2"
                                style={{
                                  border: '1px solid #d6c7c7',
                                  borderRadius: '5px',
                                }}
                                key={index}
                              >
                                {!formik.values.products[index].sku && (
                                  <FormikInputGroup
                                    autoFocus={true}
                                    formik={formik}
                                    label={`Barcode${index + 1}`}
                                    name={`products.${index}.id`}
                                    onKeyDown={e => {
                                      onKeyPressBarcode(e, index, arrayHelpers);
                                    }}
                                  />
                                )}

                                {formik.values.products[index].sku && (
                                  <div className="flex gap-3">
                                    <FormikInputGroup
                                      formik={formik}
                                      label={`sku`}
                                      name={`products.${index}.sku`}
                                      readOnly
                                    />

                                    <FormikInputGroup
                                      formik={formik}
                                      label={`Product Name`}
                                      name={`products.${index}.productName`}
                                      readOnly
                                    />

                                    <Minus
                                      onClick={e => decrement(e, index)}
                                      style={{
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                    <FormikInputGroup
                                      formik={formik}
                                      label="Quantity"
                                      name={`products.${index}.receivedQty`}
                                      readOnly
                                    />
                                    <Plus
                                      onClick={e => increment(e, index)}
                                      style={{
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </div>
                                )}

                                <div>
                                  <DangerButton
                                    className="mt-3"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                    type="button"
                                  >
                                    Remove
                                  </DangerButton>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="flex gap-3">
                            <SecondaryButton
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                              type="button"
                            >
                              Add Product
                            </SecondaryButton>
                          </div>
                        </div>
                      );
                    }}
                  />
                  {formik.errors['product'] &&
                  !Array.isArray(formik.errors['product']) &&
                  formik.errors['product'] ? (
                    <p className="text-xs text-red-500">{formik.errors['product']}</p>
                  ) : null}
                </FormikProvider>
              </>
            )}
          <div>
            {isLoading || formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default ScanInwardPage;
