import QueryString from "qs";
import React, { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";

const ProductInputFields = ({ formik, arrayHelpers, saleOrderProductArr }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const handleOnChange = async (product) => {
    console.log(product);

    const inventoryString = QueryString.stringify({
      product: product.product,
      location: formik?.values?.location,
      populate: true,
    });

    const inventoryRsp = await authAxiosInstance.get(
      `sale/current-inventory?${inventoryString}`
    );
    const stock = inventoryRsp?.data?.data;

    if (stock?.received <= 0) {
      return alert("Product out of stock");
    }

    if (!stock) {
      return alert("Stock not found ");
    }

    const checkExist = formik.values.products.findIndex(
      (prod) => prod.product == product.product
    );

    if (checkExist !== -1) {
      formik.setFieldValue(
        `products.${checkExist}.quantity`,
        formik.values.products[checkExist]?.quantity + 1
      );
      return toast.success("Quantity Increased by One");
    }
    arrayHelpers.push({
      product: product.product,
      name: product?.name,
      quantity: 1,
      saleOrderQuantity: product.quantity,
    });
    setSelectedProduct(null);
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      //   const string = QueryString.stringify({ search: inputValue });
      //   const product = await authAxiosInstance.get(`/product?${string}`);
      //   const options = product?.data?.data?.docs?.map((ele) => ({
      //     label: `${ele.name}`,
      //     value: JSON.stringify(ele),
      //   }));

      //   if (options.length > 0) {
      // const product = JSON.parse(options[0].value); // Assuming the first option is the desired one
      // setSelectedProduct(product);
      handleOnChange(selectedProduct);
      //   } else {
      //     toast.error("No product found");
      //   }
    }
  };
  return (
    <div className="grid grid-cols-2 gap-3">
      <FormikSelectGroup
        name={``}
        label="Product"
        formik={formik}
        options={saleOrderProductArr.map((product) => ({
          label: `${product?.name} - ${product?.product_sku}`,
          value: JSON.stringify(product),
        }))}
        onChange={async (selectedOption) => {
          const product = JSON.parse(selectedOption.value);
          setSelectedProduct(product);
          handleOnChange(product);
        }}
      />
      <FormikSelectGroup
        name={``}
        label="Master SKU"
        formik={formik}
        options={saleOrderProductArr.map((product) => ({
          label: `${product?.name} - ${product?.product_sku}`,
          value: JSON.stringify(product),
        }))}
        onChange={async (selectedOption) => {
          const product = JSON.parse(selectedOption.value);
          setSelectedProduct(product);
          handleOnChange(product);
        }}
        onInputChange={(value) => setInputValue(value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default ProductInputFields;
