import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";

const ViewDispatchedSaleOrder = () => {
  const { saleOrderId } = useParams();
  const [dispatchData, setDispatchData] = useState([]);

  async function fetchDispatch() {
    const string = QueryString.stringify({
      populate: true,
      sale_order_ref: saleOrderId,
    });
    const fetchDispatchResp = await authAxiosInstance.get(
      `/dispatch?${string}`
    );
    console.log(fetchDispatchResp);
    setDispatchData(fetchDispatchResp?.data?.data?.docs?.[0] ?? []);
  }

  useEffect(() => {
    fetchDispatch();
  }, []);
  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Product",
      accessor: "product.name",
    },
    {
      Header: "barcode",
      accessor: "product.masterSku",
    },
    {
      Header: "QTY",
      accessor: "quantity",
    },
  ];

  return (
    <PageWithCard heading={dispatchData?.saleOrder?.saleOrderNumber}>
      <TableWithHeadingAndSearch
        data={dispatchData?.products ?? []}
        columns={columns}
      />
    </PageWithCard>
  );
};

export default ViewDispatchedSaleOrder;
