import React from "react";
import {
  editAttributeType,
  fetchAttributeType,
  fetchAttributeTypes,
  getAttributeTypes,
  setEditId,
} from "../../app/reducers/AttributeType/attributeTypeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import EditModal from "../../components/infrastructure/Modals/EditModal";
import { ClipLoader } from "react-spinners";

const EditAttributeModal = () => {
  const {
    editDataLoading: loading,
    elementEditId,
    elementEditData,
  } = useSelector(getAttributeTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (elementEditId) {
      dispatch(fetchAttributeType({ _id: elementEditId }));
    }
  }, [elementEditId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
    },
    onSubmit: async (values) => {
      await dispatch(editAttributeType(values));
      await dispatch(fetchAttributeTypes());
      formik.resetForm();
    },
  });
  return (
    <EditModal
      editProperty={elementEditId}
      setEditProperty={setEditId}
      getEditData={fetchAttributeType}
      title="Edit Attribute Type"
    >
      {loading ? (
        <ClipLoader />
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-4 p-4"
        >
          <FormikInputGroup formik={formik} name="type" label="Type" required />
          <div className="mt-4">
            <PrimaryButton type="submit">Edit</PrimaryButton>
          </div>
        </form>
      )}
    </EditModal>
  );
};
export default EditAttributeModal;
