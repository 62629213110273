import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";

import PageWithCard from "../../components/infrastructure/PageWithCard";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import {
  deleteEmployee,
  fetchEmployees,
  getEmployees,
} from "../../app/reducers/Users/EmployeeSlice";

const ViewEmployees = () => {
  const { employees, loading } = useSelector(getEmployees);
  const dispatch = useDispatch();

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchProducts({ search: search, populate: true }));
    }, 300),
    []
  );
  const [page, setPage] = useState(1);

  const cols = [
    {
      Header: "Name",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.firstName} {row.original.lastName}
          </span>
        );
      },
    },

    {
      Header: "Phone",
      accessor: "username",
    },

    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div
            className="flex gap-2 items-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Trash
              onClick={async () => {
                await dispatch(deleteEmployee({ id: row?.original?._id }));
                await dispatch(fetchEmployees());
              }}
              color="red"
            />
          </div>
        );
      },
    },
  ];
  const data = useMemo(
    () => (employees?.docs ? employees.docs : []),
    [employees]
  );
  useEffect(() => {
    dispatch(fetchEmployees());
  }, [page]);
  return (
    <PageWithCard heading="View Employee">
      <div className="p-4">
        <TableWithHeadingAndGlobalSearch
          loading={loading}
          data={data}
          searchFunction={debouncedSearch}
          columns={cols}
        />
        <PaginationClassic setPage={setPage} paginationDetails={employees} />
      </div>
    </PageWithCard>
  );
};

export default ViewEmployees;
