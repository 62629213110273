import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { generateOptions } from '../../utils/Utils';
import ProductPurchaseOrderContainer from './ProductPurchaseOrderContainer';
import { fetchProducts, getProducts } from '../../app/reducers/Product/productSlice';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { ClipLoader } from 'react-spinners';

const ProductPurchaseOrder = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const { location } = useSelector(getLocation);
  const { vendor } = useSelector(getVendors);
  const { product } = useSelector(getProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendors());
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    initialValues: {
      manufacturer: '',
      location: '',
      notes: '',
      products: [],
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      notes: Yup.string(),
      products: Yup.array().of(
        Yup.object({
          product: Yup.string().required(),
          quantity: Yup.number().min(1, 'please add atleast 1 Quantity').required(),
        })
      ),
    }),
    onSubmit: async values => {
      if (values?.products?.length > 0) {
        setProgressLoading(true);
        setTotalItem(values.products.length);
        for (let i = 0; i < values.products.length; i++) {
          try {
            const productsDataArr = values?.products[i];
            let data = {
              manufacturer: values?.manufacturer,
              location: values?.location,
              notes: values?.notes,
              product: productsDataArr?.product,
              quantity: productsDataArr?.quantity,
              ...productsDataArr,
            };
            const poResponse = await authAxiosInstance.post('productPurchaseOrder', data);

            if (poResponse?.data) {
              setCurrentItem(prevState => prevState + 1);
            }
          } catch (error) {
            console.log('onSubmit Error ProductPurchaseOrder for loop: ', error);
            setCurrentItem(prevState => prevState + 1);
            toast.error('Error on Submit');
            setErrorData(prev => [
              ...prev,
              {
                ...values.products[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      }
    },
  });

  return (
    <PageWithCard heading="Create Purchase Order">
      {progressLoading ? (
        <ProgressBar currentItem={currentItem} totalItem={totalItem} errorData={errorData} />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
          <FormikSelectGroup
            label="Select Manufacturer "
            formik={formik}
            name="manufacturer"
            onChange={async selectedOption => {
              await dispatch(fetchProducts({ manufacturer: selectedOption?.value }));
              formik.setFieldValue('manufacturer', selectedOption?.value);
            }}
            options={generateOptions({
              array: vendor?.docs ?? [],
              valueField: '_id',
              labelField: 'companyName',
            })}
            required
          />
          <FormikSelectGroup
            label="Select Location"
            formik={formik}
            name="location"
            options={generateOptions({
              array: location?.docs ?? [],
              valueField: '_id',
              labelField: 'name',
            })}
            required
          />
          <FormikTextareaGroup name="notes" label="Notes" formik={formik} />

          {formik?.values?.manufacturer && (
            <FormikProvider value={formik}>
              <FieldArray
                name="products"
                render={arrayHelpers => {
                  return (
                    <div className="flex flex-col gap-2">
                      <div>
                        {formik.values.products.map((ele, index) => (
                          <div
                            className="relative p-4 mb-2"
                            style={{
                              border: '1px solid #d6c7c7',
                              borderRadius: '5px',
                            }}
                            key={index}
                          >
                            <ProductPurchaseOrderContainer
                              formik={formik}
                              index={index}
                              key={index}
                              arrayHelpers={arrayHelpers}
                              productData={product?.docs}
                            />
                          </div>
                        ))}
                      </div>
                      <div>
                        <SecondaryButton
                          onClick={() => {
                            arrayHelpers.push({
                              quantity: 0,
                            });
                          }}
                          type="button"
                        >
                          Add More
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          )}
          <div>
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default ProductPurchaseOrder;
