import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import AddProduct from "./AddProduct";
import AddedProductRow from "./AddedProductRow";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { toast } from "react-toastify";
import { object, string } from "yup";

const TableHeader = ({ headers }) => {
  return (
    <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers?.map((header) => (
          <th
            key={header.name}
            className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
          >
            <div style={header.style ?? {}} className="font-semibold text-left">
              {header.name}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const StockOut = () => {
  const storeLocation = JSON.parse(localStorage.getItem("saleLocationInfoId"));
  const { location } = useSelector(getLocation);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: storeLocation,
      to: "",
      products: [],
    },
    validationSchema: object({
      from: string().required(),
      to: string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (values.products.length <= 0)
        return alert("Please selected at least one product to continue");

      const stockTransferRes = await authAxiosInstance.post(
        "/stockTransfer/create",
        values
      );
      console.log(stockTransferRes);
      if (stockTransferRes.data.message == "success") {
        resetForm();
        return toast.success("Stock Transfer Created!");
      }

      toast.error("Failed to Crate Stock Transfer!");
    },
  });

  return (
    <PageWithCard heading="Stock Out">
      <form onSubmit={formik.handleSubmit}>
        <FormikSelectGroup
          required
          label="From"
          name={"from"}
          formik={formik}
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: "name",
            valueField: "_id",
          })}
        />
        <FormikSelectGroup
          required
          label="To"
          name={"to"}
          formik={formik}
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: "name",
            valueField: "_id",
          })}
        />
        <FormikProvider value={formik}>
          <FieldArray
            name="products"
            render={(arrayHelpers) => (
              <div>
                <div>
                  <AddProduct arrayHelpers={arrayHelpers} formik={formik} />
                </div>
                <div className="w-full overflow-x-auto">
                  <table className=" ">
                    <TableHeader
                      headers={[
                        { name: "" },
                        { name: "photo" },
                        { name: "name" },
                        { name: "barcode" },
                        { name: "qty" },
                        { name: "Available Qty" },
                      ]}
                    />
                    <tbody>
                      {formik.values.products.map((ele, index) => (
                        <AddedProductRow
                          key={index}
                          formik={formik}
                          element={ele}
                          index={index}
                          arrayHelpers={arrayHelpers}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          />
        </FormikProvider>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </form>
    </PageWithCard>
  );
};

export default StockOut;
