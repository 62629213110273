import React, { useState } from "react";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";

const mappingFields = ({ formik, index }) => {
  const [relationalModelFields, setRelationalModelFields] = useState([]);
  return (
    <div className="grid grid-cols-4 gap-3">
      <div className="col-span-2">
        <FormikInputGroup
          formik={formik}
          label={"File heading"}
          name={`headings.${index}.heading`}
          readOnly
        />
      </div>
      <div
        style={{
          gridColumn: `${
            relationalModelFields.length > 0
              ? "span 1 / span 1"
              : "span 2 / span 2"
          }`,
        }}
        // className="col-span-1"
      >
        <FormikSelectGroup
          formik={formik}
          label="Mapping"
          name={`headings.${index}.field`}
          onChange={async (options) => {
            console.log(options);
            console.log("options", options);
            const configObj = JSON.parse(options.value);
            if (configObj.ref) {
              console.log("configObj.ref" + configObj.ref);
              const string = QueryString.stringify({ model: configObj.ref });
              const relationalModelResp = await authAxiosInstance.get(
                `generalBulkUpload/getRelationalModel?${string}`
              );
              console.log(relationalModelResp);
              setRelationalModelFields(relationalModelResp.data?.data);
            } else {
              setRelationalModelFields([]);
            }
            formik.setFieldValue(`headings.${index}.field`, options.value);
          }}
          options={Object.keys(formik.values.analyzedObj)
            .sort()
            .map((e) => ({
              label: e,
              value: JSON.stringify({
                field: e,
                ...formik.values.analyzedObj[e],
              }),
            }))}
        />
      </div>
      <div
        style={{
          gridColumn: `${
            relationalModelFields.length > 0
              ? "span 1  / span 1"
              : "span 2 / span 2"
          }`,
        }}
        // className="col-span-1"
      >
        {relationalModelFields.length > 0 && (
          <FormikSelectGroup
            formik={formik}
            label="Relational field"
            name={`headings.${index}.relationalField`}
            options={relationalModelFields.map((e) => ({
              label: e,
              value: e,
            }))}
          />
        )}
      </div>
    </div>
  );
};

export default mappingFields;
