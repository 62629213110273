// src/components/Organization.js
import React, { useState } from "react";
import axios from "axios";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { useFormik } from "formik";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { createOrganization } from "../../app/reducers/Organization/organizationSlice";
import { useNavigate } from "react-router-dom";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import { object, string } from "yup";

const Organization = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const formik = useFormik({
    initialValues: {
      company_name: "",
      gst_number: "",
      country: "",
      state: "",
      city: "",
      address: "",
    },
    validationSchema: object({
      company_name: string().required(),
      gst_number: string(),
      country: string().required(),
      state: string().required(),
      city: string().required(),
      address: string().required(),
    }),
    onSubmit: async (values) => {
      try {
        const res = await dispatch(createOrganization(values));
        console.log("res", res);
        if (res?.payload?.data?._id) {
          router(`/organization/${res?.payload?.data?._id}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div className="flex flex-col items-center justify-center h-screen ">
      <div className=" flex flex-col gap-4 w-[90%]  md:w-[40%]">
        <div>
          <h1 className="text-2xl font-semibold">Register Organization</h1>
          <div className="border border-black w-full" />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col  gap-4 ">
          <FormikInputGroup
            formik={formik}
            name={"company_name"}
            label="Company name"
            required
          />
          <FormikInputGroup
            formik={formik}
            name={"gst_number"}
            label="GST number"
          />
          <FormikInputGroup
            formik={formik}
            name={"country"}
            label="Country"
            required
          />
          <FormikInputGroup
            formik={formik}
            name={"state"}
            label="State"
            required
          />
          <FormikInputGroup
            formik={formik}
            name={"city"}
            label="City"
            required
          />
          <FormikInputGroup
            formik={formik}
            name={"pincode"}
            label="Pin code"
            required
          />
          <FormikTextareaGroup
            formik={formik}
            name={"address"}
            label="Address"
            required
          />
          <PrimaryButton type="submit">Next</PrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default Organization;
