import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchProductPurchaseOrders,
  getProductPurchaseOrders,
} from '../../app/reducers/ProductPurchaseOrder/productPurchaseOrderSlice';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { generateOptions } from '../../utils/Utils';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import moment from 'moment';
import _ from 'lodash';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';

const ViewProductPurchaseOrderByVendor = () => {
  const { id } = useParams();
  const { productPurchaseOrder, loading } = useSelector(getProductPurchaseOrders);
  const { location } = useSelector(getLocation);
  const locationInfoId = JSON.parse(localStorage.getItem('locationInfoId'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: locationInfoId ?? [],
      status: ['pending'],
    },
    onSubmit: async values => {
      let filterData = {
        'manufacturer._id': id,
        populate: true,
        'location._id': { $in: values?.location },
        status: { $in: values?.status },
      };
      dispatch(fetchProductPurchaseOrders(filterData));
    },
  });

  useEffect(() => {
    dispatch(
      fetchProductPurchaseOrders({
        'manufacturer._id': id,
        populate: true,
        'location._id': { $in: formik?.values?.location },
        status: { $in: formik?.values?.status },
      })
    );
  }, [id]);

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'PO Number',
      Cell: ({ row }) => {
        return <div>{row?.original?.poNumber}</div>;
      },
    },
    {
      Header: 'Product',
      accessor: 'product.name',
    },
    {
      Header: 'Location',
      accessor: 'location.name',
    },

    {
      Header: 'manufacturer',
      accessor: 'manufacturer.companyName',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Total Ordered',
      accessor: 'quantity',
    },
    {
      Header: 'Remaining Ordered',
      accessor: 'remaining',
    },
  ];

  const dataMemo = useMemo(() => productPurchaseOrder?.docs ?? [], [productPurchaseOrder]);
  const columnsMemo = useMemo(() => columns ?? [], [columns]);

  const debouncedSearch = useCallback(
    _.debounce(search => {
      dispatch(
        fetchProductPurchaseOrders({
          'manufacturer._id': id,
          populate: true,
          'location._id': { $in: formik?.values?.location },
          status: { $in: formik?.values?.status },
          search,
        })
      );
    }, 300),
    []
  );

  return (
    <PageWithCard heading="VendorWise Po">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikMultiSelect
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: 'name',
            valueField: '_id',
          })}
          name="location"
          label="Select Location"
          formik={formik}
        />
        <FormikMultiSelect
          formik={formik}
          name="status"
          label="Status"
          options={generateOptions({
            array: [
              { label: 'Direct', value: 'direct' },
              { label: 'Pending', value: 'pending' },
              { label: 'Completed', value: 'completed' },
              { label: 'Settled', value: 'settled' },
              { label: 'Incomplete', value: 'incomplete' },
            ],
            labelField: 'label',
            valueField: 'value',
          })}
        />
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      <div className="mt-3">
        <TableWithHeadingAndGlobalSearch
          columns={columnsMemo}
          data={dataMemo}
          loading={loading}
          searchFunction={value => {
            debouncedSearch(value);
          }}
        />
      </div>
    </PageWithCard>
  );
};

export default ViewProductPurchaseOrderByVendor;
