import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAttribute,
  fetchAttributes,
  getAttributes,
  setEditId,
} from '../../app/reducers/Attribute/attributeSlice';
import { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { Edit2, Trash } from 'react-feather';
import {
  fetchAttributeTypes,
  getAttributeTypes,
} from '../../app/reducers/AttributeType/attributeTypeSlice';
import { useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useCallback } from 'react';
import { useState } from 'react';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const ViewAttribute = () => {
  const [page, setPage] = useState(1);
  const { attribute, loading } = useSelector(getAttributes);
  const { attributeType } = useSelector(getAttributeTypes);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchAttributes({
        populate: true,
        // "type._id": formik?.values?.type,
        page,
      })
    );
    dispatch(fetchAttributeTypes());
  }, [page]);

  const formik = useFormik({
    initialValues: {
      type: '',
    },
    onSubmit: async values => {
      await dispatch(
        fetchAttributes({
          'type._id': values?.type,
          populate: true,
          page,
        })
      );
      // formik.resetForm();
    },
  });

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Code',
      accessor: 'code',
    },
    {
      Header: 'Type',
      accessor: 'type.type',
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={e => {
                navigate(`/master/editAttribute/${row?.original?._id}`);
              }}
            />

            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteAttribute({ id: row?.original?._id }));
                await dispatch(fetchAttributes({ populate: true }));
              }}
            />
          </div>
        );
      },
    },
  ];

  const attributeMemoData = useMemo(() => attribute?.docs ?? [], [attribute]);
  const columnsMemoData = useMemo(() => columns, [columns]);

  const debouncedSearch = useCallback(
    _.debounce(search => {
      dispatch(
        fetchAttributes({
          populate: true,
          'type._id': formik?.values?.type,
          search,
          page,
        })
      );
    }, 300),
    [attribute?.docs]
  );

  return (
    <PageWithCard heading="View Attribute">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikSelectGroup
          formik={formik}
          name="type"
          label="Type"
          required
          options={generateOptions({
            array: attributeType?.docs ?? [],
            valueField: '_id',
            labelField: 'type',
          })}
        />
        <div className="mb-2">
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      <TableWithHeadingAndGlobalSearch
        heading={`View Attribute`}
        loading={loading}
        columns={columnsMemoData}
        data={attributeMemoData}
        searchFunction={value => {
          debouncedSearch(value);
        }}
      />
      <PaginationClassic
        paginationDetails={{
          ...attribute,
          docs: attribute.docs,
        }}
        setPage={setPage}
      />
    </PageWithCard>
  );
};
export default ViewAttribute;
