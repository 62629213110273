import QueryString from "qs";
import React, { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";

const AddProduct = ({ formik, arrayHelpers, pageType = "" }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const handleOnChange = async (product) => {
    // let product = product;

    console.log(product);

    const inventoryString = QueryString.stringify({
      product: product?._id,
      location: formik?.values?.from,
      populate: true,
    });

    const inventoryRsp = await authAxiosInstance.get(
      `sale/current-inventory?${inventoryString}`
    );
    const stock = inventoryRsp?.data?.data;

    if (stock?.received <= 0) {
      return alert("Product out of stock");
    }

    if (!stock) {
      return alert("Stock not found ");
    }

    const checkExist = formik.values.products.findIndex(
      (prod) => prod.product == product._id
    );

    if (checkExist !== -1) {
      formik.setFieldValue(
        `products.${checkExist}.quantity`,
        formik.values.products[checkExist]?.quantity + 1
      );
      return toast.success("Quantity Increased by One");
    }
    // console.log(product.sellingPrice);
    arrayHelpers.push({
      product: product?._id,
      name: product?.name,
      quantity: 1,
      masterSku: product.masterSku,
      photo: product.photo,
      availableQuantity: stock?.received,
    });
    setSelectedProduct(null);
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      const string = QueryString.stringify({ search: inputValue });
      const product = await authAxiosInstance.get(`/product?${string}`);
      const options = product?.data?.data?.docs?.map((ele) => ({
        label: `${ele.name}`,
        value: JSON.stringify(ele),
      }));

      if (options.length > 0) {
        const product = JSON.parse(options[0].value); // Assuming the first option is the desired one
        // setSelectedProduct(product);
        handleOnChange(product);
      } else {
        toast.error("No product found");
      }
    }
  };
  return (
    <div className="grid grid-cols-2 gap-3">
      <FormikAsyncSelect
        name={``}
        label="Product"
        formik={formik}
        getOptions={async (value) => {
          const string = QueryString.stringify({ search: value });
          const product = await authAxiosInstance.get(`/product?${string}`);
          const options = product?.data?.data?.docs?.map((ele) => ({
            label: `${ele.name} - ${ele.masterSku}`,
            value: JSON.stringify(ele),
          }));
          return options;
        }}
        onChange={async (selectedOption) => {
          const product = JSON.parse(selectedOption.value);
          await setSelectedProduct(product);
          handleOnChange(product);
        }}
      />
      <FormikAsyncSelect
        name={``}
        label="Master SKU"
        formik={formik}
        getOptions={async (value) => {
          const string = QueryString.stringify({ masterSku: value });
          const product = await authAxiosInstance.get(`/product?${string}`);
          const options = product?.data?.data?.docs?.map((ele) => ({
            label: `${ele.name}`,
            value: JSON.stringify(ele),
          }));
          return options;
        }}
        onChange={async (selectedOption) => {
          const product = JSON.parse(selectedOption.value);
          setSelectedProduct((prev) => product);
          handleOnChange(product);
        }}
        onInputChange={(value) => setInputValue(value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default AddProduct;
