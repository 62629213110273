import React, { useEffect } from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { PAYMENT_METHOD } from "../../utils/dropdownOptions";
import moment from "moment";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  createSaleOrder,
  editSaleOrder,
} from "../../app/reducers/SaleOrder/saleOrderSlice";
import { Loader, X, XSquare } from "react-feather";
import { createCreditbook } from "../../app/reducers/Creditbook/creditbookSlice";
import { createCashbook } from "../../app/reducers/Cashbook/cashbookSlice";
import { fetchBanks, getBanks } from "../../app/reducers/Bank/bankSlice";
import { createBankbook } from "../../app/reducers/Bankbook/bankbookSlice";

const SaleOrderProcessModal = ({
  isOpen,
  setIsOpen,
  parentFormik,
  edit = false,
}) => {
  const calculateFinalAmount = parentFormik.values.products?.reduce(
    (acc, ele) => {
      acc += Number(ele.total_price);
      return acc;
    },
    0
  );
  const { bank, loading: bankLoading } = useSelector(getBanks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBanks());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      received_amount: parentFormik.values?.received_amount ?? [
        {
          amount: 0,
          date: moment().format("MM/DD/YYYY"),
          method: "cash",
          reference: "",
        },
      ],
      final_amount: calculateFinalAmount || 0,
    },
    onSubmit: async (values, { resetForm }) => {
      console.log("value", values);
      const payload = { ...parentFormik.values, ...values };
      // const methodWiseAmount = values.received_amount.reduce((acc, ele) => {
      //   acc[ele.method] = ele;
      //   return acc;
      // }, {});

      // console.log(methodWiseAmount);
      let saleOrderResp;
      if (edit) {
        saleOrderResp = await dispatch(editSaleOrder(payload));
        console.log(payload);
      } else {
        saleOrderResp = await dispatch(createSaleOrder(payload));
      }
      console.log(saleOrderResp, "saleOrderResp");
      // const saleOrderNumber = saleOrderResp.payload.data.saleOrderNumber;
      // for (let i in methodWiseAmount) {
      //   console.log(i);
      //   if (i == "credit") {
      //     await dispatch(
      //       createCreditbook({
      //         transaction_type: "credit",
      //         amount: methodWiseAmount.credit.amount,
      //         user: parentFormik.values.customer,
      //         notes: methodWiseAmount.credit.reference,
      //         expense_category: "SALE_ORDER",
      //         saleOrderNumber,
      //       })
      //     );
      //   } else if (i == "cash") {
      //     await dispatch(
      //       createCashbook({
      //         transaction_type: "credit",
      //         amount: methodWiseAmount.cash.amount,
      //         location: parentFormik.values.location,
      //         notes: methodWiseAmount.cash.reference,
      //         expense_category: "SALE_ORDER",
      //         saleOrderNumber,
      //       })
      //     );
      //   } else {
      //     await dispatch(
      //       createBankbook({
      //         transaction_type: "bank",
      //         amount: methodWiseAmount[i].amount,
      //         bank: methodWiseAmount[i].method,
      //         notes: methodWiseAmount[i].reference,
      //         expense_category: "SALE_ORDER",
      //         saleOrderNumber,
      //       })
      //     );
      //   }
      // }

      // console.log({ ...values, ...parentFormik.values });

      setIsOpen(false);
      resetForm();
      parentFormik.resetForm();
    },
  });

  const calculateTotalReceivedAmount = formik.values.received_amount.reduce(
    (acc, ele) => {
      acc += Number(ele.amount);

      return acc;
    },
    0
  );
  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={"Payment Process"}
    >
      <form className="p-4 pb-20 relative" onSubmit={formik.handleSubmit}>
        <FormikInputGroup
          formik={formik}
          name="final_amount"
          label="Final Amount"
          readOnly
        />
        <FormikInputGroup
          formik={formik}
          name="amount_due"
          value={calculateFinalAmount - calculateTotalReceivedAmount}
          label="Amount Due"
          readOnly
        />
        <FormikProvider value={formik}>
          <FieldArray
            name="received_amount"
            render={(arrayHelpers) => (
              <div>
                {formik.values.received_amount.map((ele, index) => (
                  <div key={index} className="grid grid-cols-4 gap-3 py-2">
                    <XSquare
                      className="absolute right-4"
                      onClick={(e) => {
                        e.stopPropagation();

                        arrayHelpers.remove(index);
                      }}
                    />
                    <FormikSelectGroup
                      name={`received_amount.${index}.method`}
                      label="Method"
                      options={[
                        ...(bank.docs ?? []).map((item) => ({
                          value: item?._id,
                          label: item?.name,
                        })),
                        ...PAYMENT_METHOD,
                      ]}
                      onChange={(selectedOption) => {
                        const methodExist = formik.values.received_amount.some(
                          (item) => item.method == selectedOption.value
                        );

                        console.log(methodExist);
                        if (methodExist) {
                          return alert("Method already selected");
                        }
                        formik.setFieldValue(
                          `received_amount.${index}.method`,
                          selectedOption.value
                        );
                      }}
                      formik={formik}
                    />
                    <FormikInputGroup
                      name={`received_amount.${index}.amount`}
                      formik={formik}
                      label="Amount"
                      type="number"
                    />
                    <FormikInputDateGroup
                      name={`received_amount.${index}.date`}
                      formik={formik}
                      label="Date"
                    />
                    <FormikInputGroup
                      name={`received_amount.${index}.reference`}
                      formik={formik}
                      label="Reference"
                    />
                  </div>
                ))}
                <div className="py-3 flex gap-3">
                  <PrimaryButton
                    type="button"
                    onClick={() => {
                      arrayHelpers.push({
                        amount: 0,
                        date: moment().format("MM/DD/YYYY"),
                        method: "",
                        reference: "",
                      });
                    }}
                  >
                    Add
                  </PrimaryButton>
                  {formik.isSubmitting ? (
                    <div className="my-auto">
                      <Loader className="animate-spin " />
                    </div>
                  ) : (
                    <PrimaryButton disabled={formik.isSubmitting} type="submit">
                      Submit
                    </PrimaryButton>
                  )}
                </div>
              </div>
            )}
          />
        </FormikProvider>
      </form>
    </ModalBasic>
  );
};

export default SaleOrderProcessModal;
