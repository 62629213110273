import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "stockAdjustment";

export const fetchStockAdjustments = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editStockAdjustment = editData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchStockAdjustment = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteStockAdjustment = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createStockAdjustment = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const stockAdjustmentSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchStockAdjustments, SLICE_NAME),
    ...createData.generateExtraReducers(createStockAdjustment, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteStockAdjustment, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchStockAdjustment, SLICE_NAME),
    ...editData.generateExtraReducers(editStockAdjustment, SLICE_NAME),
  },
});

export const getStockAdjustments = (state) => state.stockAdjustment;
export const { setEditId } = stockAdjustmentSlice.actions;
export default stockAdjustmentSlice.reducer;
