import { FieldArray, FormikProvider, useFormik } from 'formik';

import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';

const ConfirmPopUp = ({ isOpen, setIsOpen, data }) => {
    const dispatch = useDispatch();

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '500px',
            width: '-webkit-fill-available',
            maxHeight: '80%',
            overflow: 'auto',
        },
        overlay: {
            zIndex: '1111111111',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            contentLabel="Confirm Pop Up Modal"
            onRequestClose={(e) => {
                setIsOpen();
            }}
        >
            <div className="text-center font-bold">Confirm Pop Up</div>
            {data?.message && (
                <div className="py-6 px-5 text-center">{data.message}</div>
            )}
            <div className="flex gap-5 justify-center">
                {data?.buttons &&
                    data.buttons?.length > 0 &&
                    data.buttons.map((button) => (
                        <PrimaryButton onClick={button.onClick}>
                            {button.name}
                        </PrimaryButton>
                    ))}
            </div>
        </Modal>
    );
};

export default ConfirmPopUp;
