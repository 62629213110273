import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "bank";

export const fetchBanks = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editBank = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchBank = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteBank = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createBank = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const bankSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchBanks, SLICE_NAME),
    ...createData.generateExtraReducers(createBank, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteBank, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchBank, SLICE_NAME),
    ...editData.generateExtraReducers(editBank, SLICE_NAME),
  },
});

export const getBanks = (state) => state.bank;
export const { setEditId } = bankSlice.actions;
export default bankSlice.reducer;
