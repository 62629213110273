import React, { useEffect, useMemo, useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import QueryString from "qs";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { generateOptions } from "../../utils/Utils";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const ViewVendorWisePurchaseOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    productPurchaseOrderVendorWiseData,
    setProductPurchaseOrderVendorWiseData,
  ] = useState([]);
  const { location, loading } = useSelector(getLocation);
  const locationInfoId = JSON.parse(localStorage.getItem("locationInfoId"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: locationInfoId ?? [],
    },
    onSubmit: () => {},
  });

  const locationData = useMemo(() => {
    return location?.docs ?? [];
  }, [location, locationInfoId]);

  useEffect(() => {
    getVendorWiseData();
  }, [JSON.stringify(formik?.values?.location)]);

  const getVendorWiseData = async () => {
    try {
      setIsLoading(true);
      const filterQuery = QueryString.stringify({
        filteredLocationArray: formik?.values?.location,
      });
      const response = await authAxiosInstance.get(
        `dashboard/vendor-wise-count?${filterQuery}`
      );
      if (response) {
        setProductPurchaseOrderVendorWiseData(
          response?.data?.data?.vendorsAndVendorWiseRemainingBalanceData
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Vendor Name",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() =>
              navigate(
                `/dashboard/viewVendorWisePurchaseOrder/viewProductPurchaseOrderByVendor/${row?.original?.manufacturer?._id}`
              )
            }
          >
            {row?.original?.manufacturer?.firstName +
              " " +
              row?.original?.manufacturer?.lastName}
          </div>
        );
      },
    },
    {
      Header: "Company Name",
      accessor: "manufacturer.companyName",
    },
    {
      Header: "Remaining Balance",
      accessor: "sumOfVendorWiseRemainingBalance",
    },
  ];

  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(
    () =>
      productPurchaseOrderVendorWiseData
        ? productPurchaseOrderVendorWiseData
        : [],
    [productPurchaseOrderVendorWiseData]
  );

  return (
    <PageWithCard heading="VendorWise Data">
      <form>
        {isLoading || loading ? (
          <ClipLoader />
        ) : (
          <FormikMultiSelect
            name="location"
            formik={formik}
            label="Selected Location"
            options={generateOptions({
              array: locationData ?? [],
              labelField: "name",
              valueField: "_id",
            })}
          />
        )}
      </form>

      <div className="mt-2">
        <TableWithHeadingAndSearch columns={columnsMemo} data={dataMemo} />
      </div>
    </PageWithCard>
  );
};

export default ViewVendorWisePurchaseOrder;
