import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  editLead,
  fetchLead,
  getLeads,
} from "../../app/reducers/Lead/leadSlice";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { array, object, string } from "yup";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikCreatableSelect from "../../components/formik/FormikCreatableSelect";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { generateOptions } from "../../utils/Utils";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import { X } from "react-feather";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import {
  fetchReferences,
  getReferences,
} from "../../app/reducers/Reference/referenceSlice";
import {
  fetchStatuses,
  getStatuses,
} from "../../app/reducers/Status/statusSlice";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import {
  fetchProducts,
  getProducts,
} from "../../app/reducers/Product/productSlice";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import { ClipLoader } from "react-spinners";
import FormikTime from "../../components/formik/FormikTime";
import moment from "moment";
import FormikLabel from "../../components/formik/FormikLabel";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { LEAD_MEETING_SCHEDULE } from "../../utils/dropdownOptions";
import {
  fetchEmployees,
  getEmployees,
} from "../../app/reducers/Users/EmployeeSlice";

const EditLead = () => {
  const { id } = useParams();
  const { elementEditData, editDataLoading } = useSelector(getLeads);
  console.log("ele data", elementEditData);
  const { reference, loading: refLoading } = useSelector(getReferences);
  const { status, loading: statsLoading } = useSelector(getStatuses);
  const { employees, loading: employeesLoading } = useSelector(getEmployees);
  const { product } = useSelector(getProducts);
  const [isLoading, setIsLoading] = useState({ ref: false, stat: false });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLead({ _id: id }));
  }, [id]);
  useEffect(() => {
    dispatch(fetchProducts({ populate: true }));
    dispatch(fetchEmployees({ populate: true }));
    dispatch(fetchReferences({ populate: true }));
    dispatch(fetchStatuses({ populate: true }));
  }, []);

  const computeInitialValue = (data) => {
    if (data) {
      return {
        ...data,
        meetingDate: data?.meetingDate
          ? moment(data?.meetingDate).toDate()
          : null,
        meetingTime: data?.meetingTime
          ? moment(data?.meetingTime).toDate()
          : null,
        editSummary: "",
      };
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: computeInitialValue(elementEditData),
    validationSchema: object({
      name: string().required(),
      reference: string().required(),
      status: string().required(),
      products: array().required(),
      editSummary: string().required(),
      createdBy: string().required(),
    }),
    onSubmit: (values) => {
      console.log("edit values", values);
      dispatch(editLead(values));
      dispatch(fetchLead({ _id: id }));
    },
  });
  const handleRefCreate = async (inputValue, route, formikName) => {
    setIsLoading((state) => ({ ...state, ref: true }));
    let data = { name: inputValue };
    const resp = await authAxiosInstance.post(`/${route}`, data);
    console.log("resp", resp?.data?.data);
    console.log("string", resp.data?.data?._id.toString());
    if (resp.data) {
      await dispatch(fetchReferences({ populate: true })).then((res) => {
        formik.setFieldValue(formikName, resp.data?.data?._id.toString());
      });
      setIsLoading((state) => ({ ...state, ref: false }));
    }
  };
  const handleStatCreate = async (inputValue, route, formikName) => {
    setIsLoading((state) => ({ ...state, stat: true }));
    let data = { name: inputValue };
    const resp = await authAxiosInstance.post(`/${route}`, data);
    console.log("resp", resp?.data?.data);
    console.log("string", resp.data?.data?._id.toString());
    if (resp.data) {
      await dispatch(fetchStatuses({ populate: true })).then((res) => {
        formik.setFieldValue(formikName, resp.data?.data?._id.toString());
      });
      setIsLoading((state) => ({ ...state, stat: false }));
    }
  };
  return (
    <PageWithCard heading="Edit Lead">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-4">
          <div className="w-full">
            <FormikInputGroup
              formik={formik}
              label="Name"
              name="name"
              required
            />
          </div>
          <div className="w-full">
            <FormikInputGroup formik={formik} label="Place" name="place" />
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="w-full">
            <FormikProvider value={formik} name="contactNumbers">
              <FieldArray
                name="contactNumbers"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                      {formik?.values?.contactNumbers?.map((ele, index) => (
                        <div className="mb-2" key={index}>
                          <div className="relative">
                            {index !== 0 && (
                              <X
                                className=" right-0 text-red-500"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}

                            <div className="flex flex-col gap-2">
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="Phone" />
                                <div className="w-[80%]">
                                  <FormikPhoneInputGroup
                                    formik={formik}
                                    name={`contactNumbers.${index}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            arrayHelpers.push("91");
                          }}
                        >
                          Add more
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          </div>
          <div className="w-full">
            {/* <FormikInputGroup formik={formik} label="Email" name="email" /> */}
            <FormikProvider value={formik} name="emails">
              <FieldArray
                name="emails"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                      {formik?.values?.emails?.map((ele, index) => (
                        <div className="mb-2" key={index}>
                          <div className="relative">
                            {index !== 0 && (
                              <X
                                className=" right-0 text-red-500"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}

                            <div className="flex flex-col gap-2">
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="Email" />
                                <div className="w-[80%]">
                                  <FormikInputGroup
                                    formik={formik}
                                    name={`emails.${index}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            arrayHelpers.push("");
                          }}
                        >
                          Add more
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="w-full">
            {/* <FormikInputGroup
              formik={formik}
              label="Source"
              name="source"
              required
            /> */}
            {editDataLoading ? (
              <ClipLoader />
            ) : (
              <FormikCreatableSelect
                formik={formik}
                label="Source"
                name="reference"
                required
                isLoading={isLoading.ref}
                isDisabled={isLoading.ref}
                options={generateOptions({
                  array: reference?.docs ? reference?.docs : [],
                  labelField: "name",
                  valueField: "_id",
                })}
                onCreateOption={(value) =>
                  handleRefCreate(value, "reference", "reference")
                }
              />
            )}
          </div>
          <div className="w-full">
            {/* <FormikInputGroup
              formik={formik}
              label="Status"
              name="status"
              required
            /> */}
            {editDataLoading ? (
              <ClipLoader />
            ) : (
              <FormikCreatableSelect
                formik={formik}
                label="Status"
                name="status"
                required
                isLoading={isLoading.stat}
                isDisabled={isLoading.stat}
                options={generateOptions({
                  array: status?.docs ? status?.docs : [],
                  labelField: "name",
                  valueField: "_id",
                })}
                onCreateOption={(value) =>
                  handleStatCreate(value, "status", "status")
                }
              />
            )}
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="w-full">
            <FormikMultiSelect
              formik={formik}
              label="Products"
              name="products"
              required
              options={generateOptions({
                array: product?.docs ? product?.docs : [],
                labelField: "name",
                valueField: "_id",
              })}
            />
          </div>
          {/* <div className="w-full flex flex-row items-center gap-4">
            <div className="w-1/2">
              <FormikInputDateGroup
                formik={formik}
                label="Meeting Date"
                name="meetingDate"
              />
            </div>
            <div className="w-1/2">
              <FormikTime
                formik={formik}
                label="Meeting Time"
                name="meetingTime"
              />
            </div>
          </div> */}
          {
            <div className="w-full">
              <FormikSelectGroup
                formik={formik}
                label="Meeting Schedule Status"
                name="isMeetingSchedule"
                options={LEAD_MEETING_SCHEDULE}
              />
            </div>
          }
        </div>
        {formik?.values?.isMeetingSchedule === "yes" && (
          <div className="w-1/2 flex flex-row items-center gap-4">
            <div className="w-full">
              <FormikInputDateGroup
                formik={formik}
                label="Meeting Date"
                name="meetingDate"
              />
            </div>
            <div className="w-full">
              <FormikTime
                formik={formik}
                label="Meeting Time"
                name="meetingTime"
              />
            </div>
          </div>
        )}
        <FormikTextareaGroup formik={formik} label="Notes" name="notes" />
        <FormikProvider value={formik}>
          <FieldArray
            name="files"
            render={(arrayHelpers) => {
              return (
                <>
                  <div className="mb-2 ">
                    <SecondaryButton
                      onClick={(e) => {
                        e.stopPropagation();
                        arrayHelpers.push({
                          fileName: "",
                          fileDocs: "",
                        });
                      }}
                    >
                      Add Files
                    </SecondaryButton>
                  </div>
                  <table className="table-auto w-full">
                    <tbody className="text-sm divide-y divide-slate-200">
                      {formik?.values?.files?.map((ele, i) => (
                        <tr key={i} className="flex flex-row items-start pl-6">
                          <td className="px-2 pb-7 whitespace-nowrap first:pl-5 last:pr-5 relative w-[30%] ">
                            <div className="">
                              <X
                                onClick={(e) => {
                                  e.stopPropagation();
                                  arrayHelpers.remove(i);
                                }}
                                color="red"
                                className="absolute top-[40%] left-[-15%] md:left-[-10%]"
                              />
                              <FormikInputGroup
                                formik={formik}
                                name={`files.${i}.fileName`}
                                label="File Name"
                              />
                            </div>
                          </td>
                          <td className="w-[50%] flex flex-wrap ">
                            <div className="text-ellipsis overflow-hidden whitespace-nowrap pl-2">
                              <FormikDirectFileUpload
                                name={`files.${i}.fileDocs`}
                                formik={formik}
                                label="Choose File"
                                randomize={true}
                                location={`Sale/documents/`}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              );
            }}
          />
        </FormikProvider>
        <FormikTextareaGroup
          formik={formik}
          label="Edit Summary"
          name="editSummary"
          required
        />
        <FormikSelectGroup
          formik={formik}
          label="Created By"
          options={generateOptions({
            array: employees?.docs?.map((ele) => ({
              name: `${ele.firstName} ${ele.lastName}`,
              value: ele._id,
            })),
            labelField: "name",
            valueField: "value",
          })}
          name="createdBy"
          required
        />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </PageWithCard>
  );
};

export default EditLead;
