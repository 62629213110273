import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import moment from 'moment';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikAsyncMultiSelect from '../../components/formik/FormikAsyncMultiSelect';
import { exportCsv } from '../../utils/Utils';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { ClipLoader } from 'react-spinners';

const DeadStockReport = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [deadStockData, setDeadStockData] = useState({});
  const { location, loading } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
    getDeadStockData();
  }, [page]);

  const formik = useFormik({
    initialValues: {
      product: [],
      location: [],
    },
    onSubmit: async values => {
      try {
        setIsLoading(true);
        const filter = {
          page,
        };
        if (values?.product) {
          filter['product'] = { $in: values.product };
        }
        if (values?.location) {
          filter['location'] = { $in: values.location };
        }
        const string = QueryString.stringify(filter);
        const response = await authAxiosInstance.get(`/report/dead-stock?${string}`);
        if (response) {
          setDeadStockData(response?.data?.data);
        } else {
          setDeadStockData({});
        }

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Error Fetching Dead Stock Data');
      } finally {
        setIsLoading(false);
      }
    },
  });

  const getDeadStockData = async () => {
    try {
      setIsLoading(true);
      const filter = {
        page,
      };
      if (formik?.values?.product) {
        filter['product'] = { $in: formik.values.product };
      }
      if (formik?.values?.location) {
        filter['location'] = { $in: formik.values.location };
      }
      const string = QueryString.stringify(filter);
      const response = await authAxiosInstance.get(`/report/dead-stock?${string}`);
      if (response) {
        setDeadStockData(response?.data?.data);
      } else {
        setDeadStockData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Fetching Dead Stock Data');
    } finally {
      setIsLoading(false);
    }
  };

  const exportCsvDownloadStockData = async () => {
    try {
      setIsLoading(true);
      const filter = {
        page,
      };
      if (formik?.values?.product) {
        filter['product'] = { $in: formik.values.product };
      }
      if (formik?.values?.location) {
        filter['location'] = { $in: formik.values.location };
      }
      const string = QueryString.stringify(filter);
      const response = await authAxiosInstance.get(`/report/dead-stock?${string}`);

      if (response?.data?.data?.docs?.length > 0) {
        let reportData = response?.data?.data?.docs;
        let csvData = reportData.map(item => {
          return {
            Location: item?.location ? item?.location?.name : 'No Sale Found',
            'First In Date': moment(item?.result?.firstIn?.firstInDate).format('DD/MM/YYYY'),
            'First In': item?.result?.firstIn?.firstInReceived,
            'Last In Date': moment(item?.result?.lastIn?.lastInDate).format('DD/MM/YYYY'),
            'Last In': item?.result?.lastIn?.lastInReceived,
            'Inventory Date': moment(item?.result?.currentInventoryDate).format('DD/MM/YYYY'),
            'Inventory Received': item?.result?.currentReceivedInventory,
            'Inventory Damaged': item?.result?.currentDamagedInventory,
            'Master Sku': item?.productData?.masterSku,
            Sale: item?.saleData ? item?.saleData?.totalSale : 0,
            'Dead Stock Percent': `${item?.deadStockPercent.toFixed(2)}%`,
          };
        });

        exportCsv(csvData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error Downloading data');
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: 'SRNo.',
      Cell: ({ row }) => {
        return row.index + deadStockData?.pagingCounter;
      },
    },
    {
      Header: 'Location',
      Cell: ({ row }) => {
        return row?.original?.location ? row?.original?.location?.name : 'No Sale Found';
      },
    },
    {
      Header: 'First In Date',
      Cell: ({ row }) => {
        return row?.original?.result?.firstIn?.firstInDate
          ? moment(row?.original?.result?.firstIn?.firstInDate).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'First In',
      accessor: 'result.firstIn.firstInReceived',
    },
    {
      Header: 'Last In Date',
      Cell: ({ row }) => {
        return row?.original?.result?.lastIn?.lastInDate
          ? moment(row?.original?.result?.lastIn?.lastInDate).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'Last In',
      accessor: 'result.lastIn.lastInReceived',
    },
    {
      Header: 'Inventory Date',
      Cell: ({ row }) => {
        return row?.original?.result?.currentInventoryDate
          ? moment(row?.original?.result?.currentInventoryDate).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'Inventory Received',
      accessor: 'result.currentReceivedInventory',
    },
    {
      Header: 'Inventory Damaged',
      accessor: 'result.currentDamagedInventory',
    },
    {
      Header: 'Master Sku',
      accessor: 'productData.masterSku',
    },
    {
      Header: 'Sale',
      Cell: ({ row }) => {
        return row?.original?.saleData ? row?.original?.saleData?.totalSale : 0;
      },
    },
    {
      Header: 'Dead Stock Percent',
      Cell: ({ row }) => {
        return `${row?.original?.deadStockPercent.toFixed(2)}%`;
      },
    },
  ];
  const columnMemoization = useMemo(() => columns ?? [], [columns]);
  const dataMemoization = useMemo(() => deadStockData?.docs ?? [], [deadStockData]);
  return (
    <PageWithCard>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikAsyncMultiSelect
          getOptions={async value => {
            const string = QueryString.stringify({ search: value });
            const productsResponse = await authAxiosInstance.get(`product?${string}`);
            const options = productsResponse?.data?.data?.docs?.map((el, i) => {
              return {
                label: `${el?.name} / ${el?.masterSku}`,
                value: el?._id,
              };
            });
            return options;
          }}
          name={`product`}
          formik={formik}
          label="Search Product"
        />
        <FormikMultiSelect
          name="location"
          formik={formik}
          label="Select Location"
          options={location?.docs?.map(el => ({
            value: el._id,
            label: el.name,
          }))}
        />
        <div className="flex flex-row gap-3">
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
          <PrimaryButton onClick={exportCsvDownloadStockData} type="button">
            Download
          </PrimaryButton>
        </div>
      </form>
      {loading || isLoading ? (
        <ClipLoader />
      ) : (
        <div className="mt-3">
          <TableWithHeadingAndSearch columns={columnMemoization} data={dataMemoization} />
          <PaginationClassic paginationDetails={deadStockData} setPage={setPage} />
        </div>
      )}
    </PageWithCard>
  );
};

export default DeadStockReport;
