import DatePicker from 'react-datepicker';
import _ from 'lodash';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './datePickerStyles.css'

const FormikTime = ({
    formik,
    label = '',
    required = false,
    name,
    selectedDate,
    dateFormat = 'dd/MM/yyyy',
    ...props
}) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="w-full flex flex-col"
        >
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            <DatePicker
                selected={_.at(formik.values, name)[0]}
                onChange={(date) => {
                    console.log(new Date(date).toTimeString(), date, 'date');
                    formik.setFieldValue(name, date);
                }}

                showTimeSelect
                showTimeSelectOnly
                // timeIntervals={60}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className=' border-1 rounded-md form-input'
                // className='text-slate-100 custom-datepicker-input border-0 rounded-md'
                {...props}
            />
        </div>
    );
};

export default FormikTime;