import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';

import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { generateOptions } from '../../utils/Utils';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { X } from 'react-feather';
import { createStockAdjustment } from '../../app/reducers/StockAdjustment/stockAdjustmentSlice';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { ClipLoader } from 'react-spinners';

const TableHeader = ({ headers }) => {
  return (
    <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers?.map(header => (
          <th key={header} className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-semibold text-center">{header}</div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const AddStockAdjustment = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const { location, loading } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    initialValues: {
      location: '',
      product: [],
      transactionType: '',
      reason: '',
    },
    validationSchema: Yup.object({
      location: Yup.string().required(),
      transactionType: Yup.string(),
      reason: Yup.string(),
    }),
    onSubmit: async values => {
      if (values.product.length > 0) {
        setProgressLoading(true);
        setTotalItem(values.product.length);
        for (let i = 0; i < values.product.length; i++) {
          try {
            let productData = values.product[i];
            let payload = {
              location: values.location,
              product: productData.product,
              quantity: productData.quantity,
              reason: productData.reason,
              transactionType: productData.transactionType,
            };
            let stockAdjustment = await dispatch(createStockAdjustment(payload));
            if (stockAdjustment) setCurrentItem(prevState => prevState + 1);
          } catch (error) {
            console.log(error);
            setCurrentItem(prevState => prevState + 1);
            toast.error('Error on Submit');
            setErrorData(prev => [
              ...prev,
              {
                ...singleProduct[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      } else {
        alert('Please Select one product');
      }
    },
  });
  return (
    <PageWithCard heading="Stock Adjustment">
      {progressLoading ? (
        <ProgressBar currentItem={currentItem} totalItem={totalItem} errorData={errorData} />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikSelectGroup
            formik={formik}
            name="location"
            label="Select Location"
            options={generateOptions({
              array: location?.docs ?? [],
              valueField: '_id',
              labelField: 'name',
            })}
          />
          {formik?.values?.location && (
            <FormikProvider value={formik}>
              <FieldArray
                name="product"
                render={arrayHelpers => (
                  <>
                    <FormikAsyncSelect
                      formik={formik}
                      name="product"
                      label="Select Product"
                      getOptions={async value => {
                        const query = {
                          search: value,
                        };
                        const string = QueryString.stringify(query);
                        const resp = await authAxiosInstance.get(`product?${string}`);
                        const products = resp.data.data.docs;
                        return products.map(ele => {
                          return {
                            label: `${ele?.name} / ${ele.masterSku}`,
                            value: JSON.stringify(ele),
                          };
                        });
                      }}
                      onChange={async option => {
                        const product = JSON.parse(option.value);
                        const string = QueryString.stringify({
                          product: product._id,
                          location: formik?.values?.location,
                        });
                        //current-stock of this product and location
                        const resp = await authAxiosInstance.get(
                          `stockAdjustment/current-inventory?${string}`
                        );
                        if (!resp?.data?.data) {
                          alert('No Challan Found');
                          return;
                        }

                        const stock = resp.data.data?.received;

                        arrayHelpers.insert(0, {
                          product: product._id,
                          productName: product?.name,
                          stock: stock,
                          quantity: 0,
                          sku: product?.masterSku,
                          reason: '',
                        });
                      }}
                    />

                    <table className="table-auto w-full">
                      <TableHeader
                        headers={['productName', 'sku', 'stock', 'quantity', 'reason']}
                      />
                      <tbody className="text-sm divide-y divide-slate-200">
                        {formik.values.product &&
                          formik.values.product.map((ele, i) => (
                            <tr key={i}>
                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div className="flex">
                                  <X
                                    onClick={() => {
                                      arrayHelpers.remove(i);
                                    }}
                                  />
                                  {ele.productName}
                                </div>
                              </td>
                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <FormikInputGroup
                                  name={`product.${i}.sku`}
                                  formik={formik}
                                  type="text"
                                  readOnly
                                />
                              </td>
                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <FormikInputGroup
                                  name={`product.${i}.stock`}
                                  formik={formik}
                                  type="number"
                                  readOnly
                                />
                              </td>
                              <td className="px-2  first:pl-5 last:pr-5 py-3 whitespace-nowrap flex flex-row gap-1 justify-center items-center  ">
                                <div className="w-[120px] pt-8">
                                  <FormikSelectGroup
                                    name={`product.${i}.transactionType`}
                                    formik={formik}
                                    options={[
                                      {
                                        value: 'in',
                                        label: `In (+)`,
                                      },
                                      {
                                        value: 'out',
                                        label: 'Out (-)',
                                      },
                                    ]}
                                  />
                                </div>
                                {ele.transactionType && (
                                  <div className="pt-8">
                                    <FormikInputGroup
                                      name={`product.${i}.quantity`}
                                      type="number"
                                      formik={formik}
                                    />
                                  </div>
                                )}
                              </td>

                              <td className="w-[20%] lg:w-[30%] ">
                                <FormikTextareaGroup formik={formik} name={`product.${i}.reason`} />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
              />
            </FormikProvider>
          )}
          <div>
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default AddStockAdjustment;
