import React, { useRef } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";

const FormikAsyncSelect = ({
  formik,
  label = "",
  required = false,
  name,
  onChange,
  getOptions,
  defaultOptionsFields = { labelField: "_id", valueField: "name" },
  ...props
}) => {
  const computeValue = (options, val) => {
    return options?.find((option) => option.value === val);
  };
  const computeDefaultOptions = async (val) => {
    const options = await getOptions(val);
    setOptions(options);
  };
  useEffect(() => {
    computeDefaultOptions(_.at(formik.values, name)[0]);
  }, [_.at(formik.values, name)[0]]);
  const [options, setOptions] = useState([]);
  const handleChange = onChange
    ? onChange
    : (selectedOption) => {
        formik.setFieldValue(name, selectedOption.value);
      };
  const loadOptions = async (value) => {
    const options = await getOptions(value);
    setOptions(options);
    return options;
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>
      <AsyncSelect
        name={name}
        value={computeValue(options, _.at(formik.values, name)[0])}
        onBlur={formik.handleBlur}
        onChange={handleChange}
        loadOptions={loadOptions}
        {...props}
      />
      {formik?.errors?.[name] && formik?.touched?.[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikAsyncSelect;
