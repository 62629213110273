import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
  createDeadStock,
  fetchDeadStocks,
  getDeadStocks,
} from '../../app/reducers/DeadStockPercentage/deadStockPercentageSlice';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { ClipLoader } from 'react-spinners';
import { Edit2 } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';

const DeadStockPercentage = () => {
  const [page, setPage] = useState(1);
  const { deadStock, loading } = useSelector(getDeadStocks);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchDeadStocks({ page }));
  }, [page]);

  const formik = useFormik({
    initialValues: { deadStockPercent: 0 },
    validationSchema: Yup.object({ deadStockPercent: Yup.number().required() }),
    onSubmit: async values => {
      await dispatch(createDeadStock(values));
      await dispatch(fetchDeadStocks({ page }));
      formik.resetForm();
    },
  });

  const columns = [
    {
      Header: 'SRNo',
      Cell: ({ row }) => {
        return row.index + deadStock.pagingCounter;
      },
    },
    {
      Header: 'Percent',
      Cell: ({ row }) => {
        return `${row?.original?.deadStockPercent}%`;
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div onClick={e => e.stopPropagation()}>
            <Edit2
              color="blue"
              onClick={() => navigate(`/deadStockPercentage/${row?.original?._id}`)}
            />
          </div>
        );
      },
    },
  ];
  const columnsMemoization = useMemo(() => columns ?? [], [columns]);
  const dataMemoization = useMemo(() => deadStock?.docs ?? [], [deadStock]);
  return (
    <PageWithCard heading="Dead Stock Percentage">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikInputGroup name="deadStockPercent" label="Add Percentage" formik={formik} required />

        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      <div className="mt-3">
        {loading ? (
          <ClipLoader />
        ) : (
          <>
            <TableWithHeadingAndSearch
              columns={columnsMemoization}
              data={dataMemoization}
              heading="View Dead Stock Percentage"
            />
            <PaginationClassic paginationDetails={deadStock} setPage={setPage} />
          </>
        )}
      </div>
    </PageWithCard>
  );
};

export default DeadStockPercentage;
