import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { ClipLoader } from "react-spinners";
import {
  editBank,
  fetchBank,
  getBanks,
} from "../../app/reducers/Bank/bankSlice";

const EditBank = () => {
  const { id } = useParams();
  const { elementEditData } = useSelector(getBanks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBank({ _id: id }));
  }, [id]);
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
    },
    onSubmit: async (values) => {
      await dispatch(editBank(values));
      await dispatch(fetchBank({ _id: id }));
      navigate("/master/bank");
    },
  });

  return (
    <PageWithCard heading="Edit Attribute">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikInputGroup formik={formik} name="name" label="Name" required />

        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};
export default EditBank;
