import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSaleReturns, getSaleReturns } from '../../app/reducers/SaleReturn/saleReturnSlice';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useFormik } from 'formik';
import moment from 'moment';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikAsyncMultiSelect from '../../components/formik/FormikAsyncMultiSelect';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { useNavigate } from 'react-router-dom';
import { generateOptions } from '../../utils/Utils';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { ClipLoader } from 'react-spinners';

const ViewSaleReturn = () => {
  const [page, setPage] = useState(1);
  const { location } = useSelector(getLocation);
  const { saleReturn, loading } = useSelector(getSaleReturns);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      location: '',
      customerId: '',
      product: [],
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      const filters = {
        createdAt: {
          $gt: values.from,
          $lt: values.to,
        },
      };
      if (values.customerId) {
        filters.customerId = values.customerId;
      }
      if (values.location) {
        filters['location._id'] = values.location;
      }
      if (values.product) {
        filters['products.product._id'] = { $in: values.product };
      }

      await dispatch(fetchSaleReturns({ ...filters, page, populate: true }));
    },
  });

  useEffect(() => {
    const filters = {
      createdAt: {
        $gt: formik.values.from,
        $lt: formik.values.to,
      },
    };
    if (formik.values.customerId) {
      filters.customerId = formik.values.customerId;
    }
    if (formik.values.location) {
      filters['location._id'] = formik.values.location;
    }
    if (formik.values.product) {
      filters['products.product._id'] = { $in: formik.values.product };
    }

    dispatch(fetchLocations());
    dispatch(fetchSaleReturns({ ...filters, page, populate: true }));
  }, [page]);

  const columns = [
    {
      Header: 'SRNO',
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    { Header: 'Customer Name', accessor: 'customerName' },
    {
      Header: 'Location',
      accessor: 'location.name',
    },
    {
      Header: 'Return Type',
      accessor: 'dataType',
    },
    {
      Header: 'Return Receipt No',
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={async e => {
              e.stopPropagation();
              navigate(`/sale/viewSaleReturn/${row?.original?._id}`);
            }}
          >
            {row?.original?.saleReturnNumber}
          </div>
        );
      },
    },
    {
      Header: 'Total Quantity',
      Cell: ({ row }) => {
        const totalSale = row?.original?.products?.reduce((acc, { qty }) => {
          return qty ? acc + qty : 0;
        }, 0);
        return totalSale;
      },
    },
    {
      Header: 'Total Valuation',
      Cell: ({ row }) => {
        const totalSaleValuation = row?.original?.products?.reduce((acc, { qty, product }) => {
          const value = qty * product.costPrice;
          return acc + value;
        }, 0);
        return totalSaleValuation;
      },
    },
  ];

  const dateMemo = useMemo(() => {
    return saleReturn?.docs ?? [];
  }, [saleReturn?.docs]);

  const columnMemo = useMemo(() => columns ?? [], [columns]);

  const debouncedSearch = useCallback(
    _.debounce(search => {
      let filter = {
        createdAt: {
          $gt: formik?.values?.from,
          $lt: formik?.values?.to,
        },
        'location._id': formik?.values?.location,
        customerId: formik.values.customerId,
        'products.product._id': { $in: formik.values.product },
        populate: true,
        search: search,
        page,
      };
      dispatch(fetchSaleReturns(filter));
    }, 300),
    []
  );

  return (
    <PageWithCard heading="View Sale Return">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikSelectGroup
          name="location"
          label="Select Location"
          formik={formik}
          options={generateOptions({
            array: location?.docs ?? [],
            valueField: '_id',
            labelField: 'name',
          })}
        />
        <FormikAsyncSelect
          name="customerId"
          formik={formik}
          label="Search Customer"
          getOptions={async value => {
            const string = QueryString.stringify({ search: value });
            const customers = await authAxiosInstance.get(`users/customer?${string}`);
            const options = customers?.data?.data?.docs?.map(ele => ({
              label: `${ele?.firstName} ${ele?.lastName} / ${ele?.username}`,
              value: ele?._id,
            }));
            return options;
          }}
        />

        <FormikAsyncMultiSelect
          name="product"
          formik={formik}
          label="Search Product"
          getOptions={async value => {
            const string = QueryString.stringify({ search: value });
            const products = await authAxiosInstance.get(`product?${string}`);
            const options = products?.data?.data?.docs?.map(ele => ({
              label: `${ele?.name} / ${ele?.masterSku}`,
              value: ele?._id,
            }));
            return options;
          }}
        />

        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />

        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      <div className="mt-2">
        <TableWithHeadingAndGlobalSearch
          heading="Sales"
          data={dateMemo}
          loading={loading}
          columns={columnMemo}
          searchFunction={search => {
            debouncedSearch(search);
          }}
        />
        <PaginationClassic paginationDetails={saleReturn} setPage={setPage} />
      </div>
    </PageWithCard>
  );
};

export default ViewSaleReturn;
