import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "bankbook";

export const fetchBankbooks = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editBankbook = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchBankbook = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteBankbook = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createBankbook = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const bankbookSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchBankbooks, SLICE_NAME),
    ...createData.generateExtraReducers(createBankbook, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteBankbook, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchBankbook, SLICE_NAME),
    ...editData.generateExtraReducers(editBankbook, SLICE_NAME),
  },
});

export const getBankbook = (state) => state.bankbook;
export const { setEditId } = bankbookSlice.actions;
export default bankbookSlice.reducer;
