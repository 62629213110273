import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchProductPurchaseOrder,
  getProductPurchaseOrders,
} from '../../app/reducers/ProductPurchaseOrder/productPurchaseOrderSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  createProductChallan,
  fetchProductChallan,
  fetchProductChallans,
} from '../../app/reducers/ProductChallan/productChallanSlice';
import { ClipLoader } from 'react-spinners';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';

const AddProductChallan = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { elementEditData, editDataLoading } = useSelector(getProductPurchaseOrders);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductPurchaseOrder({ _id: id, populate: true }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
      poRef: '',
      challanNumber: '',
      challanDate: '',
      damaged: 0,
      receivedQty: 0,
      notes: '',
    },
    validationSchema: Yup.object({
      challanNumber: Yup.string().required(),
      challanDate: Yup.string(),
      receivedQty: Yup.number().min(1).required(),
      damaged: Yup.number(),
      notes: Yup.string(),
    }),
    onSubmit: async values => {
      try {
        setIsLoading(true);
        const totalProductQty = Number(values?.receivedQty) + Number(values?.damaged);

        if (elementEditData?.remaining >= totalProductQty) {
          try {
            setIsLoading(true);
            const repeatChallan = await dispatch(
              fetchProductChallans({
                manufacturer: values?.manufacturer?._id,
                challanNumber: values?.challanNumber,
              })
            );
            if (repeatChallan?.payload?.data?.totalDocs > 0) {
              alert('Challan Number already exist');
              return;
            } else {
              const payload = {
                product: values?.product?._id,
                location: values?.location?._id,
                manufacturer: values?.manufacturer?._id,
                poRef: id,
                challanNumber: values?.challanNumber,
                receivedQty: Number(values?.receivedQty),
                damaged: Number(values?.damaged),
                challanDate: values?.challanDate,
                notes: values?.notes,
              };
              await dispatch(createProductChallan(payload));
              formik.resetForm();
              await dispatch(fetchProductPurchaseOrder({ _id: id, populate: true }));
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
            console.log(error);
            toast.error('Something went wrong while creating Challan');
          }
        } else {
          toast.error('Your Current Quantity is more than remaining quantity');
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <PageWithCard heading="Add Challan">
      <div className=" grid grid-cols-1 sm:grid-cols-3 justify-between gap-2">
        {elementEditData && (
          <div className="flex flex-row justify-between  border p-2">
            <p className="text text-base font-medium">Total Quantity</p>
            <p className="text text-base font-medium">{elementEditData?.quantity}</p>
          </div>
        )}
        {elementEditData && (
          <div className="flex flex-row justify-between border p-2">
            <p className="text text-base font-medium">Remaining Quantity</p>
            <p className="text text-base font-medium">{elementEditData?.remaining}</p>
          </div>
        )}
        {elementEditData && (
          <div className="flex flex-row justify-between border p-2">
            <p className="text text-base font-medium">Current Quantity</p>
            <p className="text text-base font-medium">
              {elementEditData?.remaining -
                (Number(formik?.values?.receivedQty) + Number(formik?.values?.damaged))}
            </p>
          </div>
        )}
      </div>
      <div className="mt-2">
        <hr />
      </div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 mt-4">
        <FormikInputGroup
          formik={formik}
          readOnly
          label="Manufacturer"
          name="manufacturer.companyName"
        />
        <FormikInputGroup formik={formik} readOnly label="Location" name="location.name" />
        <FormikInputGroup formik={formik} readOnly label="Product" name="product.name" />
        <FormikInputDateGroup formik={formik} label="Challan Date" name="challanDate" />
        <FormikInputGroup formik={formik} label="Challan Number" name="challanNumber" />
        <FormikInputGroup label="Received Quantity" name="receivedQty" formik={formik} />
        <FormikInputGroup label="Damaged" name="damaged" formik={formik} />
        <FormikTextareaGroup name="notes" label="Notes" formik={formik} />

        <div>
          {isLoading || editDataLoading || formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default AddProductChallan;
